import { Auth } from "aws-amplify";

const awsconfig = {
  Auth: {
    region: "ap-northeast-1",
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    storage: window.sessionStorage,
  },
  API: {
    endpoints: [
      {
        name: "biogas-plant-remote-monitoring-api",
        endpoint: process.env.VUE_APP_API_ENDPOINT,
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        custom_header: async () => {
          return {
            Authorization: `${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
};

export default awsconfig;
