






import Vue from "vue";
export default Vue.extend({
  name: "arrow-right",
  props: {
    arrowColor: { type: String, default: "#815e46" },
  },
  mounted() {
    const targetElement = this.$refs["arrow-right"] as HTMLElement;
    if (targetElement) {
      targetElement.style.setProperty("--arrowColor", this.arrowColor);
    }
  },
});
