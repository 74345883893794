var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"elevation-15 mt-n7"},[_c('v-card-title',[_vm._v(" 設定管理画面 ")]),_c('v-card-text',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.settingType),callback:function ($$v) {_vm.settingType=$$v},expression:"settingType"}},[_c('v-radio',{attrs:{"label":"自動運転パラメータ","value":"autoOperationParam"},on:{"change":function () {
              _vm.filterTarget = null;
              _vm.getAutomaticSequenceControlParameterSetting();
            }}}),_c('v-radio',{attrs:{"label":"原材料投入スケジュール","value":"rawMaterialSchedule"},on:{"change":function () {
              _vm.filterTarget = null;
              _vm.getRawMaterialInputScheduleSetting();
            }}})],1),_c('v-row',[(_vm.settingType === 'autoOperationParam')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"データ絞り込み（自由入力）","single-line":"","hide-details":""},model:{value:(_vm.filterTarget),callback:function ($$v) {_vm.filterTarget=$$v},expression:"filterTarget"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mb-6",attrs:{"headers":[
              { text: '項目', value: 'display_name' },
              { text: '選択状況', value: 'status' },
              { text: '操作', value: 'action' } ],"items":_vm.automaticSequenceControlParameterSetting,"search":_vm.filterTarget},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{class:{ 'primary--text': item.status },attrs:{"true-value":true,"false-value":false,"disabled":""},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.showEditDialog(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1):_vm._e(),(_vm.settingType === 'rawMaterialSchedule')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.isShowRegisterDialog = true}}},[_vm._v(" 新規スケジュール登録 ")])],1)],1),_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"データ絞り込み（自由入力）","single-line":"","hide-details":""},model:{value:(_vm.filterTarget),callback:function ($$v) {_vm.filterTarget=$$v},expression:"filterTarget"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mb-6",attrs:{"headers":[
              { text: '開始時刻', value: 'start_time' },
              { text: '投入期間', value: 'input_period' },
              { text: '有効', value: 'is_enabled' },
              { text: '操作', value: 'action' } ],"items":_vm.rawMaterialInputScheduleSetting,"search":_vm.filterTarget,"sort-by":['start_time']},scopedSlots:_vm._u([{key:"item.start_time",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_time.slice(0, 2))+":"+_vm._s(item.start_time.slice(2))+" ")]}},{key:"item.input_period",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.input_period)+"秒 ")]}},{key:"item.is_enabled",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{class:{ 'primary--text': item.is_enabled },attrs:{"true-value":true,"false-value":false,"disabled":""},model:{value:(item.is_enabled),callback:function ($$v) {_vm.$set(item, "is_enabled", $$v)},expression:"item.is_enabled"}})]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.showEditDialog(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.isShowEditDialog),callback:function ($$v) {_vm.isShowEditDialog=$$v},expression:"isShowEditDialog"}},[(_vm.settingType === 'autoOperationParam')?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" 編集画面 ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"選択状態"},model:{value:(_vm.editItem.status),callback:function ($$v) {_vm.$set(_vm.editItem, "status", $$v)},expression:"editItem.status"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","large":"","color":"primary","block":"","elevation":"5"},on:{"click":_vm.updateAutomaticSequenceControlParameterSetting}},[_vm._v(" SAVE ")])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","large":"","color":"error","block":"","elevation":"5"},on:{"click":function () {
                    _vm.isShowEditDialog = false;
                    _vm.initializeEditItem();
                  }}},[_vm._v(" CANCEL ")])],1)],1)],1)],1)],1):_vm._e(),(_vm.settingType === 'rawMaterialSchedule')?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" 編集画面 ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"8","lg":"6"}},[_c('v-select',{attrs:{"items":_vm.hours,"label":"時","prepend-icon":"mdi-clock-outline"},model:{value:(_vm.startDatetime.hour),callback:function ($$v) {_vm.$set(_vm.startDatetime, "hour", $$v)},expression:"startDatetime.hour"}}),_c('v-select',{attrs:{"items":_vm.minutes,"label":"分"},model:{value:(_vm.startDatetime.minute),callback:function ($$v) {_vm.$set(_vm.startDatetime, "minute", $$v)},expression:"startDatetime.minute"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"継続時間（秒）","rules":_vm.inputPeriodRules},model:{value:(_vm.editItem.input_period),callback:function ($$v) {_vm.$set(_vm.editItem, "input_period", $$v)},expression:"editItem.input_period"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"有効状態"},model:{value:(_vm.editItem.is_enabled),callback:function ($$v) {_vm.$set(_vm.editItem, "is_enabled", $$v)},expression:"editItem.is_enabled"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","large":"","color":"primary","block":"","elevation":"5","disabled":!_vm.editItem.input_period ||
                  !_vm.startDatetime.hour ||
                  !_vm.startDatetime.minute ||
                  _vm.inputPeriodRules.some(
                    function (rule) { return rule(_vm.editItem.input_period) !== true; }
                  )},on:{"click":_vm.updateRawMaterialInputScheduleSetting}},[_vm._v(" SAVE ")])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","large":"","color":"error","block":"","elevation":"5"},on:{"click":function () {
                    _vm.isShowEditDialog = false;
                    _vm.initializeEditItem();
                  }}},[_vm._v(" CANCEL ")])],1)],1)],1)],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.isShowRegisterDialog),callback:function ($$v) {_vm.isShowRegisterDialog=$$v},expression:"isShowRegisterDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" 新規スケジュール登録 ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"8","lg":"6"}},[_c('v-select',{attrs:{"items":_vm.hours,"label":"時","prepend-icon":"mdi-clock-outline"},model:{value:(_vm.startDatetime.hour),callback:function ($$v) {_vm.$set(_vm.startDatetime, "hour", $$v)},expression:"startDatetime.hour"}}),_c('v-select',{attrs:{"items":_vm.minutes,"label":"分"},model:{value:(_vm.startDatetime.minute),callback:function ($$v) {_vm.$set(_vm.startDatetime, "minute", $$v)},expression:"startDatetime.minute"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"継続時間（秒）","rules":_vm.inputPeriodRules},model:{value:(_vm.editItem.input_period),callback:function ($$v) {_vm.$set(_vm.editItem, "input_period", $$v)},expression:"editItem.input_period"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"有効状態"},model:{value:(_vm.editItem.is_enabled),callback:function ($$v) {_vm.$set(_vm.editItem, "is_enabled", $$v)},expression:"editItem.is_enabled"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","large":"","color":"primary","block":"","elevation":"5","disabled":!_vm.editItem.input_period ||
                  !_vm.startDatetime.hour ||
                  !_vm.startDatetime.minute ||
                  _vm.inputPeriodRules.some(
                    function (rule) { return rule(_vm.editItem.input_period) !== true; }
                  )},on:{"click":_vm.registerRawMaterialInputScheduleSetting}},[_vm._v(" SAVE ")])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","large":"","color":"error","block":"","elevation":"5"},on:{"click":function () {
                    _vm.isShowRegisterDialog = false;
                    _vm.initializeEditItem();
                  }}},[_vm._v(" CANCEL ")])],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" 削除確認 ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("本当に削除しますか？")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","large":"","block":"","elevation":"5"},on:{"click":_vm.deleteRawMaterialInputScheduleSetting}},[_vm._v(" 削除 ")])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","outlined":"","large":"","block":"","elevation":"5"},on:{"click":function () {
                    _vm.deleteDialog = false;
                    _vm.initializeEditItem();
                  }}},[_vm._v(" キャンセル ")])],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"60%"},model:{value:(_vm.isShowErrorDialog),callback:function ($$v) {_vm.isShowErrorDialog=$$v},expression:"isShowErrorDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" エラー ")]),_c('v-card-text',[_c('span',[_vm._v(_vm._s(_vm.errorDialogMessage))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold mb-5",attrs:{"color":"error","elevation":"5","outlined":"","large":""},on:{"click":_vm.resetErrorDialog}},[_vm._v(" 戻る ")])],1)],1)],1),_c('v-overlay',{attrs:{"z-index":100,"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }