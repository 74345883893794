



















































import Vue from "vue";
export default Vue.extend({
  name: "CreateUser",
  data(): { [key: string]: string | boolean } {
    return {
      successMessage: "",
      showPassword: false,
      showPasswordVerif: false,
    };
  },
  methods: {
    createUser() {
      this.successMessage = "ユーザーの登録が完了しました";
      setTimeout(() => {
        this.successMessage = "";
        this.$emit("switchToLogin");
      }, 1.5 * 1000);
    },
  },
});
