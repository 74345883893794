













































































import Vue from "vue";
import { bus } from "../main";
import BarChart from "../components/BarChart.vue";
import NavigationDrawer from "../components/NavigationDrawer.vue";
import {
  DetailFacility,
  DetailDisplayData,
  DisplayDataTime,
  PlantControl,
} from "@/types";
import { mapState } from "vuex";
import { getDetailData, getGraphData } from "@/lib/web_api_util";
import { debugLog } from "@/lib/debug_util";
import WebApiModel from "@/apis/web_api";
import store from "@/store";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

type GraphRecord = {
  record_time: string;
  [key: string]: string | number | null;
};

type BarChartDataset = {
  type: string;
  label: string;
  borderWidth: number;
  pointBorderWidth: number;
  borderColor: string;
  backgroundColor: string;
  data: Array<string | number | null>;
  yAxisID: string;
  xAxisID: string;
  hidden: boolean;
};

type BarChartData = {
  labels: Array<string>;
  datasets: Array<BarChartDataset>;
};

type GraphDisplayData = {
  hours: BarChartData;
  week: BarChartData;
  month: BarChartData;
};

export default Vue.extend({
  name: "Graph",
  components: { BarChart, NavigationDrawer },

  data() {
    return {
      ///////////////////////////////////////////////////////////////
      // APIアクセス関連
      ///////////////////////////////////////////////////////////////
      webApiModel: {} as WebApiModel,

      ///////////////////////////////////////////////////////////////
      // エラー表示関連
      ///////////////////////////////////////////////////////////////
      errorSnackBarMessage: "",
      showErrorDialog: false,
      errorDialogMessage: "",

      ///////////////////////////////////////////////////////////////
      // 画面表示関連
      ///////////////////////////////////////////////////////////////
      // ローディング表示用
      isLoadingDisplay: false,

      // タイマー設定用の変数
      timeOutId: 0,

      // 画面表示用データの格納場所
      displayData: {
        facilities: [] as Array<DetailFacility>,
        alerts: [] as Array<string>,
      } as DetailDisplayData,

      // 表示期間
      displayPeriod: "hours",

      // グラフ表示用
      loaded: false,
      width: Math.floor(window.innerWidth * 0.7),
      height: Math.floor(window.innerHeight * 0.7),
      labels: [] as Array<string>,
      datasets: [] as Array<BarChartDataset>,

      // データがない場合のグラフの代わりのメッセージ表示用
      cardFlat: true,
      dataNoneMessage: "",

      // グラフ用データの格納場所
      graphDisplayData: {
        hours: {
          labels: [],
          datasets: [],
        },
        week: {
          labels: [],
          datasets: [],
        },
        month: {
          labels: [],
          datasets: [],
        },
      } as GraphDisplayData,
    };
  },

  created() {
    bus.$emit("background", "noImage");
    // 予めdayjsのutcプラグインを有効化しておく
    dayjs.extend(utc);
    // APIアクセス用ライブラリを作成
    this.webApiModel = new WebApiModel();
  },

  computed: {
    ...mapState(["isAutoUpdate", "isOnceUpdate"]),
    navigationWidth() {
      if (
        window.matchMedia("(min-width: 960px) and (max-width: 1264px)").matches
      ) {
        return 260;
      } else {
        return 300;
      }
    },
    isShowErrorSnackBarMessage() {
      if (this.errorSnackBarMessage.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    store.commit("setNextLoadingDisplay", true);
    this.getDisplayData();
    this.labels = this.getLabels(this.displayPeriod);
    this.datasets = this.getDatasets(this.displayPeriod);
    this.loaded = true;
  },

  watch: {
    isAutoUpdate() {
      debugLog("自動更新？", store.getters.isAutoUpdate);
      // 自動更新に変更した場合は、画面表示データ取得を行う
      if (store.getters.isAutoUpdate === true) {
        debugLog("自動更新を開始します", store.getters.isAutoUpdate);
        this.getDisplayData();
      }
    },
    isOnceUpdate() {
      debugLog("日付指定更新？", store.getters.isOnceUpdate);
      // １度だけの画面表示データ取得を行う（自動更新でない場合）
      if (store.getters.isOnceUpdate === true) {
        debugLog("日付指定更新を開始します", store.getters.isOnceUpdate);
        this.getDisplayData();
        store.commit("setIsOnceUpdate", false);
      }
    },
  },

  beforeDestroy() {
    // 自動更新のためのタイマーを止める
    clearTimeout(this.timeOutId);
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    // resize時に呼ばれる関数
    handleResize() {
      this.width = Math.floor(window.innerWidth * 0.7);
      this.height = Math.floor(window.innerHeight * 0.7);
    },

    // グラフのスタイル設定（resizeにグラフのサイズ指定）
    graphStyles() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
        position: "relative",
      };
    },

    // 表示期間ラジオボタンの選択を変更
    onDisplayPeriodChange() {
      // 表示期間に応じたラベルとデータセットをグラフに設定
      this.labels = this.getLabels(this.displayPeriod);
      this.datasets = this.getDatasets(this.displayPeriod);
      // データがない場合はグラフの代わりにメッセージを表示
      if (this.labels.length === 0) {
        this.dataNoneMessage = "集計データがありません";
      } else {
        this.dataNoneMessage = "";
      }
    },

    // 画面表示データを取得する
    async getDisplayData(): Promise<void> {
      debugLog("getDisplayData");
      // 自動更新で初回、および自動更新でない場合はローディング表示オンにする
      if (
        store.getters.nextLoadingDisplay == true ||
        store.getters.isAutoUpdate == false
      ) {
        debugLog("ローディング表示オン");
        this.isLoadingDisplay = true;
        store.commit("setNextLoadingDisplay", false);
      }
      // 自動更新タイマーを停止する
      clearTimeout(this.timeOutId);

      // 詳細画面表示用データを取得する（アラートリスト表示のため）
      const responsePlantsData = await getDetailData(
        this.webApiModel,
        this.displayData
      );
      // エラーの場合はエラーを表示し処理を中断する
      if (responsePlantsData === undefined) {
        // ローディング表示オフ
        this.isLoadingDisplay = false;
        if (store.getters.isAutoUpdate === true) {
          // 自動更新の場合はエラーをスナックバー表示する
          this.errorSnackBarMessage = "情報の取得に失敗しました";
        } else {
          // 自動更新でない場合は(ヘッダ内の)データ表示日時を
          // 確認する。これが空でない場合は、以下の場合に備えて
          // store内のonceUpdateDataTimeへ値をコピーする。
          // ・再読み込み操作をされた時
          // ・サマリー版画面へ遷移する操作をされた時
          // ↓
          // いずれもonceUpdateDataTimeの日時を元にAPIを
          // アクセスしてデータ取得するので、ヘッダ内の表示と
          // APIアクセスの日時が一致していないと不自然である。
          const displayDataTime: DisplayDataTime =
            store.getters.displayDataTime;
          if (
            displayDataTime.date !== "" &&
            displayDataTime.hour !== "" &&
            displayDataTime.minute !== ""
          ) {
            store.commit("setOnceUpdateDataTime", displayDataTime);
          }
          // 自動更新でない場合はエラーポップアップを表示する
          this.errorDialogMessage = "情報の取得に失敗しました";
          this.showErrorDialog = true;
        }
        debugLog("getDisplayData error");
        // エラーの場合でも必要に応じて自動更新タイマーを設定する
        this.setAutoUpdateTimerWhenNeeded();
        return;
      }

      // 時刻オフセットを考慮したdayjsのオブジェクトを作成し、データ表示日時を設定する
      const timeOffset: number = store.getters.companies_plant.time_offset;
      const recordTime = dayjs(this.displayData.record_time).utcOffset(
        timeOffset
      );
      const displayDataTime = {} as DisplayDataTime;
      displayDataTime.date = dayjs(recordTime).format("YYYY-MM-DD");
      displayDataTime.hour = dayjs(recordTime).format("HH");
      displayDataTime.minute = dayjs(recordTime).format("mm");
      store.commit("setDisplayDataTime", displayDataTime);

      // グラフ用データを取得する
      const graphData = await getGraphData(this.webApiModel);
      // エラーの場合はエラーを表示し処理を中断する
      if (graphData === undefined) {
        // ローディング表示オフ
        this.isLoadingDisplay = false;
        if (store.getters.isAutoUpdate === true) {
          // 自動更新の場合はエラーをスナックバー表示する
          this.errorSnackBarMessage = "グラフデータの取得に失敗しました";
        } else {
          // 自動更新でない場合はエラーポップアップを表示する
          this.errorDialogMessage = "グラフデータの取得に失敗しました";
          this.showErrorDialog = true;
        }
        debugLog("getDisplayData error");
        // エラーの場合でも必要に応じて自動更新タイマーを設定する
        this.setAutoUpdateTimerWhenNeeded();
        return;
      }

      // 取得したグラフ用データから表示期間が時間用/週用/月用のチャートデータを作成する
      this.makeChartData("hours", graphData.hours, this.graphDisplayData.hours);
      this.makeChartData("week", graphData.week, this.graphDisplayData.week);
      this.makeChartData("month", graphData.month, this.graphDisplayData.month);
      // データがない場合はグラフの代わりにメッセージを表示
      if (
        (this.displayPeriod === "hours" && graphData.hours.length === 0) ||
        (this.displayPeriod === "week" && graphData.week.length === 0) ||
        (this.displayPeriod === "month" && graphData.month.length === 0)
      ) {
        this.dataNoneMessage = "集計データがありません";
      } else {
        this.dataNoneMessage = "";
      }

      // 成功すればエラーのスナックバー表示を消去
      this.errorSnackBarMessage = "";
      // ローディング表示オフ
      this.isLoadingDisplay = false;
      // 必要に応じて自動更新タイマーを設定する
      this.setAutoUpdateTimerWhenNeeded();
    },

    // 必要に応じて自動更新タイマーを設定する
    setAutoUpdateTimerWhenNeeded() {
      // 自動更新の場合は、自動更新用のタイマーを設定する
      if (store.getters.isAutoUpdate === true) {
        // 画面更新間隔(秒)をstoreから取得
        const refresh_seconds = store.getters.refresh_seconds;
        this.timeOutId = window.setTimeout(() => {
          // タイマー発火時、自動更新の場合は画面表示更新を行う
          if (store.getters.isAutoUpdate === true) {
            debugLog("自動更新実施");
            this.getDisplayData();
          }
        }, refresh_seconds * 1000);
      }
    },

    // グラフ用データからのチャートデータを作成する
    makeChartData(
      dataType: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      graphData: any,
      barChartData: BarChartData
    ) {
      // Storeから制御データ取得し、グラフの表示順に並び替える
      // ・第１ソートキー："line"→"bar"の順序(描画でlineがbarの後ろに隠れるため)
      // ・第２ソートキー：日報データ並び順の昇順
      const controlList: PlantControl[] = store.getters.plant_control_list;
      controlList.sort((a, b) => a.report_priority - b.report_priority);
      controlList.sort((a, b) => {
        if (a.graph_type === "line" && b.graph_type !== "line") {
          return -1;
        } else if (a.graph_type === "bar" && b.graph_type == null) {
          return -1;
        }
        return 1;
      });

      // グラフのラベルとデータを初期化する
      barChartData.labels.splice(0);
      barChartData.datasets.splice(0);

      // グラフのラベルを作成
      graphData.forEach((graphDataRecord: GraphRecord) => {
        let label = graphDataRecord.record_time;
        // 表示期間が月のデータは年月日のみのラベルにする
        if (dataType === "month") {
          label = graphDataRecord.record_time.substring(0, 10);
        }
        barChartData.labels.push(label);
      });

      // プラントデータ表示制御情報を基にＹ軸のデータセットを作成する
      let datasetIndex = -1;
      controlList.forEach((plantControl: PlantControl) => {
        // グラフ種別が"line"または"bar"でない場合は処理しない。
        if (
          plantControl.graph_type !== "line" &&
          plantControl.graph_type !== "bar"
        ) {
          // 次の要素へ(forEachではcontinueが使えないのでreturnを使用)
          return;
        }
        // Ｙ軸のデータセットを作成
        datasetIndex += 1;
        let dataset = {} as BarChartDataset;
        dataset.type = plantControl.graph_type;
        dataset.label = plantControl.report_name;
        if (plantControl.unit_of_data != null) {
          dataset.label += " | " + plantControl.unit_of_data;
        } else {
          dataset.label += " | -";
        }
        if (plantControl.type_for_report != null) {
          dataset.label += " | " + plantControl.type_for_report;
        } else {
          dataset.label += " | -";
        }
        dataset.borderWidth = 2;
        dataset.pointBorderWidth = 1;
        dataset.borderColor = this.makeHslColor(datasetIndex);
        dataset.backgroundColor = this.makeHslColor(datasetIndex);
        dataset.yAxisID = "y" + (datasetIndex + 1);
        dataset.hidden = true;
        dataset.data = [];
        // Ｙ軸のデータセットにデータ値を詰める
        graphData.forEach((graphDataRecord: GraphRecord) => {
          const value = graphDataRecord[plantControl.display_name_en];
          dataset.data.push(value);
        });
        // Ｙ軸のデータセットを追加
        barChartData.datasets.push(dataset);
      });
    },

    // 表示期間に応じたラベルを取得
    getLabels(displayPeriod: string) {
      if (displayPeriod === "hours") {
        return this.graphDisplayData.hours.labels;
      } else if (displayPeriod === "week") {
        return this.graphDisplayData.week.labels;
      } else if (displayPeriod === "month") {
        return this.graphDisplayData.month.labels;
      } else {
        return [];
      }
    },

    // 表示期間に応じたデータセットを取得
    getDatasets(displayPeriod: string) {
      if (displayPeriod === "hours") {
        return this.graphDisplayData.hours.datasets;
      } else if (displayPeriod === "week") {
        return this.graphDisplayData.week.datasets;
      } else if (displayPeriod === "month") {
        return this.graphDisplayData.month.datasets;
      } else {
        return [];
      }
    },

    // グラフの色を作成する
    makeHslColor(index: number) {
      // HSLにおいてHue(色相)部分だけを回転させることにより、色のバリエーションを作成する
      // 考え方の参考ページ：https://sbfl.net/blog/2018/05/21/javascript-generate-better-color/

      // 75度ずつ回転、24回で(5周して)元の0度に戻る(すなわち24色のバリエーション)
      // 色相15度の差異はほとんど同じ色に見えるが、
      // 近くに並べればなんとか識別可能
      const hue = (75 * index) % 360;
      const hslString = `hsl(${hue}, 80%, 60%)`;
      // debugLog("hsl color:", hslString);
      return hslString;
    },

    // エラーポップアップを閉じる
    resetErrorDialog(): void {
      this.errorDialogMessage = "";
      this.showErrorDialog = false;
    },
  },
});
