




























































































































































































































































































































































































































































































































































































import Vue from "vue";
import router from "../router/index";
import SingleTank from "../components/tankComponent/SingleTank.vue";
import NavigationDrawer from "../components/NavigationDrawer.vue";
import Flow from "../components/Flow.vue";
import ArrowRight from "../components/summaryComponent/ArrowRight.vue";
import ArrowDown from "../components/summaryComponent/ArrowDown.vue";
import ArrowFlow from "../components/summaryComponent/ArrowFlow.vue";
import ShortArrowRight from "../components/summaryComponent/ShortArrowRight.vue";
import ArrowLeft from "../components/summaryComponent/ArrowLeft.vue";
import ArrowCurvedLeft from "../components/summaryComponent/ArrowCurvedLeft.vue";
import ArrowCurvedRight from "../components/summaryComponent/ArrowCurvedRight.vue";
import { bus } from "../main";
import { debugLog } from "@/lib/debug_util";
import WebApiModel from "@/apis/web_api";
import {
  getSummaryData,
  getDisplayScore,
  getSettingData,
  updateAutomaticSequenceControlParameterSetting,
  registerRawMaterialInputScheduleSetting,
  updateRawMaterialInputScheduleSetting,
  deleteRawMaterialInputScheduleSetting,
} from "@/lib/web_api_util";
import store from "@/store";
import {
  DisplayDataTime,
  SummaryDisplayData,
  SummaryFacilities,
  SummaryFlow,
  Scores,
  SettingData,
  AlertsInfo,
} from "@/types";
import { mapState } from "vuex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export default Vue.extend({
  name: "Summary",
  components: {
    SingleTank,
    NavigationDrawer,
    Flow,
    ArrowRight,
    ArrowDown,
    ArrowFlow,
    ShortArrowRight,
    ArrowLeft,
    ArrowCurvedLeft,
    ArrowCurvedRight,
  },
  data() {
    return {
      ///////////////////////////////////////////////////////////////
      // APIアクセス関連
      ///////////////////////////////////////////////////////////////
      webApiModel: {} as WebApiModel,

      ///////////////////////////////////////////////////////////////
      // エラー表示関連
      ///////////////////////////////////////////////////////////////
      errorSnackBarMessage: "",
      showErrorDialog: false,
      errorDialogMessage: "",

      ///////////////////////////////////////////////////////////////
      // 画面表示関連
      ///////////////////////////////////////////////////////////////
      // ローディング表示用
      isLoadingDisplay: false,

      // タイマー設定用の変数
      timeOutId: null as number | null,

      // 画面表示用データの格納場所
      displayData: {
        facilities: {} as SummaryFacilities,
        flows: [] as Array<SummaryFlow>,
        alerts: [] as Array<string>,
        alerts_info: [] as Array<AlertsInfo>,
        scores: [] as Scores[],
        record_time: "",
        setting: {} as SettingData,
      } as SummaryDisplayData,

      // 矢印の色定義
      arrowColors: {
        blue: "#5ab1bd",
        brown: "#815e46",
        red: "#e76d57",
        orange: "#db8d46",
      },
      // 設定編集対象アイテム
      editItem: {} as SettingData,
      // 編集前のアイテム
      beforeEditItem: {} as SettingData,
      // 自動運転パラメータ設定ダイアログの表示制御
      isShowAutomaticSequenceControlParameterSettingDialog: false,
      // スケジュール一覧ダイアログの表示制御
      isShowScheduleListDialog: false,
      // スケジュール一覧ダイアログのフィルター
      filterTarget: "",
      // スケジュール登録ダイアログの表示制御
      isShowRegisterRawMaterialInputScheduleSettingDialog: false,
      // スケジュール編集ダイアログの表示制御
      isShowEditRawMaterialInputScheduleSettingDialog: false,
      // スケジュール削除ダイアログの表示制御
      isShowDeleteRawMaterialInputScheduleSettingDialog: false,
      // 開始日時格納
      startDatetime: {
        hour: "00",
        minute: "00",
      },
      // 時項目コンボボックス値
      hours: ["Dummy"],
      // 分項目コンボボックス値
      minutes: ["Dummy"],

      ///////////////////////////////////////////////////////////////
      // バリデーションルール関連
      ///////////////////////////////////////////////////////////////
      inputPeriodRules: [
        (value: unknown) => !!value || "投入期間は必須入力です",
        (value: string) =>
          /^\d{1,3}$/.test(value) || "3桁までの数字で入力してください",
      ],
    };
  },
  created() {
    bus.$emit("background", "image2");
    // 予めdayjsのutcプラグインを有効化しておく
    dayjs.extend(utc);
    // APIアクセス用ライブラリを作成
    this.webApiModel = new WebApiModel();
    // 時間セレクトボックス用の配列作成
    this.hours = [];
    for (let i = 0; i <= 23; i++) {
      this.hours.push(String(i).padStart(2, "0"));
    }
    // 分セレクトボックス用の配列作成
    this.minutes = [];
    for (let i = 0; i <= 59; i++) {
      this.minutes.push(String(i).padStart(2, "0"));
    }
  },
  computed: {
    ...mapState(["isAutoUpdate", "isOnceUpdate"]),
    isShowErrorSnackBarMessage() {
      if (this.errorSnackBarMessage.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    store.commit("setNextLoadingDisplay", true);
    this.getDisplayData();
  },
  watch: {
    isAutoUpdate() {
      debugLog("自動更新？", store.getters.isAutoUpdate);
      // 自動更新に変更した場合は、画面表示データ取得を行う
      if (store.getters.isAutoUpdate === true) {
        debugLog("自動更新を開始します", store.getters.isAutoUpdate);
        this.getDisplayData();
      }
    },
    isOnceUpdate() {
      debugLog("日付指定更新？", store.getters.isOnceUpdate);
      // １度だけの画面表示データ取得を行う（自動更新出ない場合）
      if (store.getters.isOnceUpdate === true) {
        debugLog("日付指定更新を開始します", store.getters.isOnceUpdate);
        this.getDisplayData();
        store.commit("setIsOnceUpdate", false);
      }
    },
  },
  beforeDestroy() {
    // 自動更新のためのタイマーを止める
    if (this.timeOutId !== null) {
      clearTimeout(this.timeOutId);
      this.timeOutId = null;
    }
  },
  methods: {
    moveToPage(path: string): void {
      router.push(path);
    },
    // 画面表示データを取得する
    async getDisplayData(): Promise<void> {
      debugLog("getDisplayData");
      // 自動更新で初回、および自動更新でない場合はローディング表示オンにする
      if (
        store.getters.nextLoadingDisplay == true ||
        store.getters.isAutoUpdate == false
      ) {
        debugLog("ローディング表示オン");
        this.isLoadingDisplay = true;
        store.commit("setNextLoadingDisplay", false);
      }
      // 自動更新タイマーを停止する
      if (this.timeOutId !== null) {
        clearTimeout(this.timeOutId);
        this.timeOutId = null;
      }

      //画面表示用データを取得する
      const responsePlantsData = await getSummaryData(
        this.webApiModel,
        this.displayData
      );
      // 画面表示用スコアデータを取得する
      const responseScoreData = await getDisplayScore(
        this.webApiModel,
        this.displayData
      );
      // 画面表示用設定データを取得する
      const responseSettingData = await getSettingData(
        this.webApiModel,
        this.displayData
      );
      // エラーの場合はエラーを表示し処理を中断する
      if (responsePlantsData === undefined) {
        // ローディング表示オフ
        this.isLoadingDisplay = false;
        if (store.getters.isAutoUpdate === true) {
          // 自動更新の場合はエラーをスナックバー表示する
          this.errorSnackBarMessage = "情報の取得に失敗しました";
        } else {
          // 自動更新でない場合は(ヘッダ内の)データ表示日時を
          // 確認する。これが空でない場合は、以下の場合に備えて
          // store内のonceUpdateDataTimeへ値をコピーする。
          // ・再読み込み操作をされた時
          // ・詳細版画面へ遷移する操作をされた時
          // ↓
          // いずれもonceUpdateDataTimeの日時を元にAPIを
          // アクセスしてデータ取得するので、ヘッダ内の表示と
          // APIアクセスの日時が一致していないと不自然である。
          const displayDataTime: DisplayDataTime =
            store.getters.displayDataTime;
          if (
            displayDataTime.date !== "" &&
            displayDataTime.hour !== "" &&
            displayDataTime.minute !== ""
          ) {
            store.commit("setOnceUpdateDataTime", displayDataTime);
          }
          // 自動更新でない場合はエラーポップアップを表示する
          this.errorDialogMessage = "情報の取得に失敗しました";
          this.showErrorDialog = true;
        }
        debugLog("getDisplayData error");
      } else {
        // 時刻オフセットを考慮したdayjsのオブジェクトを作成する
        const timeOffset: number = store.getters.companies_plant.time_offset;
        const recordTime = dayjs(this.displayData.record_time).utcOffset(
          timeOffset
        );
        debugLog("recordTime with offset:", recordTime.format());
        // データ表示日時を設定する
        const displayDataTime = {} as DisplayDataTime;
        displayDataTime.date = dayjs(recordTime).format("YYYY-MM-DD");
        displayDataTime.hour = dayjs(recordTime).format("HH");
        displayDataTime.minute = dayjs(recordTime).format("mm");
        store.commit("setDisplayDataTime", displayDataTime);

        // 成功すればエラーのスナックバー表示を消去
        this.errorSnackBarMessage = "";
        // ローディング表示オフ
        this.isLoadingDisplay = false;
      }
      // エラー発生有無によらず、自動更新の場合は、自動更新用のタイマーを設定する
      if (store.getters.isAutoUpdate === true) {
        // 画面更新間隔(秒)をstoreから取得
        const refresh_seconds = store.getters.refresh_seconds;
        this.timeOutId = window.setTimeout(() => {
          // タイマー発火時、自動更新の場合は画面表示更新を行う
          if (store.getters.isAutoUpdate === true) {
            debugLog("自動更新実施");
            this.getDisplayData();
          }
        }, refresh_seconds * 1000);
      }
    },
    // エラーポップアップを閉じる
    resetErrorDialog(): void {
      this.errorDialogMessage = "";
      this.showErrorDialog = false;
    },
    getSettingStatus(param_id: string): string {
      // 設定パラメータの状態の文字列を取得する
      const setting =
        this.displayData.setting.automaticSequenceControlParameterSetting.find(
          (param: any) => param.param_id === param_id
        );
      if (param_id === "is_bypass_gas_withdrawal_pump_selected") {
        return setting && setting.status ? "バイパス" : "メイン";
      } else {
        return setting && setting.status ? "ON" : "OFF";
      }
    },
    getArrowFlowColor() {
      // 引抜ポンプ（バイパス）の状態に応じて色を変化させる
      return this.displayData.facilities.gasBalloon.items[5].value === true
        ? this.arrowColors.red
        : this.arrowColors.orange;
    },
    getShortArrowColor() {
      // 入口電動弁の状態に応じて色を変化させる
      return this.displayData.facilities.gasTank.items[0].value === true
        ? this.arrowColors.red
        : this.arrowColors.orange;
    },
    getShortArrow2Color() {
      // 出口電動弁の状態に応じて色を変化させる
      return this.displayData.facilities.gasTank.items[2].value === true
        ? this.arrowColors.red
        : this.arrowColors.orange;
    },
    getArrowCurvedColor() {
      // 温水槽 循環ポンプ（発電機用）の状態に応じて色を変化させる
      return this.displayData.facilities.hotWater.items[3].value === true
        ? this.arrowColors.red
        : this.arrowColors.orange;
    },
    getArrowColor() {
      // 温水槽 循環ポンプ（発酵槽用）の状態に応じて色を変化させる
      return this.displayData.facilities.hotWater.items[2].value === true
        ? this.arrowColors.red
        : this.arrowColors.orange;
    },
    openAutomaticSequenceControlParameterSettingDialog(parm_id: string) {
      this.editItem =
        this.displayData.setting.automaticSequenceControlParameterSetting.find(
          (param: any) => param.param_id === parm_id
        );
      this.isShowAutomaticSequenceControlParameterSettingDialog = true;
    },
    openScheduleListDialog() {
      this.isShowScheduleListDialog = true;
    },
    openRegisterRawMaterialInputScheduleSettingDialog() {
      this.isShowRegisterRawMaterialInputScheduleSettingDialog = true;
    },
    openEditRawMaterialInputScheduleSettingDialog(item: SettingData) {
      this.startDatetime.hour = item.start_time.slice(0, 2);
      this.startDatetime.minute = item.start_time.slice(2);
      this.editItem = Object.assign({}, item);
      this.beforeEditItem = Object.assign({}, item);
      this.isShowEditRawMaterialInputScheduleSettingDialog = true;
    },
    openDeleteRawMaterialInputScheduleSettingDialog(item: SettingData) {
      this.startDatetime.hour = item.start_time.slice(0, 2);
      this.startDatetime.minute = item.start_time.slice(2);
      this.editItem = Object.assign({}, item);
      this.isShowDeleteRawMaterialInputScheduleSettingDialog = true;
    },
    // 編集対象アイテムを初期化
    initializeEditItem() {
      this.editItem = {};
      this.beforeEditItem = {};
      this.startDatetime.hour = "00";
      this.startDatetime.minute = "00";
    },
    // 自動運転パラメータ設定更新
    async updateAutomaticSequenceControlParameterSetting() {
      const response = await updateAutomaticSequenceControlParameterSetting(
        this.webApiModel,
        this.editItem.imsi,
        this.editItem.param_id,
        this.editItem.status
      );
      if (response === undefined) {
        this.errorSnackBarMessage = "設定の更新に失敗しました";
      } else {
        this.isShowAutomaticSequenceControlParameterSettingDialog = false;
        this.initializeEditItem();
        this.getDisplayData();
      }
    },
    // 開始時刻をUTCに変換
    convertStartTimeToUTC(startTimeLocal: string): string {
      const hourLocal = startTimeLocal.slice(0, 2);
      const minuteLocal = startTimeLocal.slice(2);
      const timeOffsetMin = store.getters.companies_plant.time_offset;
      const timeOffsetHour = Math.floor(timeOffsetMin / 60);
      let hourUTC = parseInt(hourLocal, 10) - timeOffsetHour;
      if (hourUTC < 0) {
        hourUTC += 24;
      }
      return `${hourUTC.toString().padStart(2, "0")}${minuteLocal}`;
    },
    // 原材料入力スケジュール設定登録
    async registerRawMaterialInputScheduleSetting(): Promise<void> {
      // ローディング表示ON
      this.isLoadingDisplay = true;
      // 原材料入力スケジュール設定登録APIを呼び出す
      const responseRawMaterialInputScheduleSetting =
        await registerRawMaterialInputScheduleSetting(
          this.webApiModel,
          store.getters.companies_plant.imsi,
          "input_raw_materials_to_conditioning_tank",
          this.convertStartTimeToUTC(
            this.startDatetime.hour + this.startDatetime.minute
          ),
          Number(this.editItem.input_period),
          this.editItem.is_enabled !== undefined
            ? this.editItem.is_enabled
            : false
        );
      // エラーの場合は中断する
      if (
        typeof responseRawMaterialInputScheduleSetting === "string" ||
        responseRawMaterialInputScheduleSetting === undefined
      ) {
        // 編集対象アイテムを初期化
        this.initializeEditItem();
        // ローディング表示OFF
        this.isLoadingDisplay = false;
        // エラーメッセージを設定
        this.errorDialogMessage =
          typeof responseRawMaterialInputScheduleSetting === "string"
            ? responseRawMaterialInputScheduleSetting
            : "スケジュール情報の登録に失敗しました。";
        // エラーダイアログを表示
        this.showErrorDialog = true;
        return;
      }
      // 原材料入力スケジュール設定取得APIを呼び出す
      await this.getDisplayData();
      // ローディング表示OFF
      this.isLoadingDisplay = false;
      // 編集対象アイテムを初期化
      this.initializeEditItem();
      // ダイアログを閉じる
      this.isShowRegisterRawMaterialInputScheduleSettingDialog = false;
    },
    // 原材料入力スケジュール設定更新
    async updateRawMaterialInputScheduleSetting(): Promise<void> {
      // ローディング表示ON
      this.isLoadingDisplay = true;
      // 原材料入力スケジュール設定更新APIを呼び出す
      const responseRawMaterialInputScheduleSetting =
        await updateRawMaterialInputScheduleSetting(
          this.webApiModel,
          store.getters.companies_plant.imsi,
          "input_raw_materials_to_conditioning_tank",
          this.convertStartTimeToUTC(
            this.startDatetime.hour + this.startDatetime.minute
          ),
          Number(this.editItem.input_period),
          this.editItem.is_enabled,
          this.beforeEditItem
        );
      // エラーの場合は中断する
      if (
        typeof responseRawMaterialInputScheduleSetting === "string" ||
        responseRawMaterialInputScheduleSetting === undefined
      ) {
        // 編集対象アイテムを初期化
        this.initializeEditItem();
        // ローディング表示OFF
        this.isLoadingDisplay = false;
        // エラーメッセージを設定
        this.errorDialogMessage =
          typeof responseRawMaterialInputScheduleSetting === "string"
            ? responseRawMaterialInputScheduleSetting
            : "スケジュール情報の更新に失敗しました。";
        // エラーダイアログを表示
        this.showErrorDialog = true;
        return;
      }
      // 原材料入力スケジュール設定取得APIを呼び出す
      await this.getDisplayData();
      // 編集対象アイテムを初期化
      this.initializeEditItem();
      // ローディング表示OFF
      this.isLoadingDisplay = false;
      // ダイアログを閉じる
      this.isShowEditRawMaterialInputScheduleSettingDialog = false;
    },
    // 原材料入力スケジュール設定削除
    async deleteRawMaterialInputScheduleSetting(): Promise<void> {
      // ローディング表示ON
      this.isLoadingDisplay = true;
      // 原材料入力スケジュール設定削除APIを呼び出す
      const responseRawMaterialInputScheduleSetting =
        await deleteRawMaterialInputScheduleSetting(
          this.webApiModel,
          store.getters.companies_plant.imsi,
          "input_raw_materials_to_conditioning_tank",
          this.convertStartTimeToUTC(
            this.startDatetime.hour + this.startDatetime.minute
          )
        );
      // エラーの場合は中断する
      if (responseRawMaterialInputScheduleSetting === undefined) {
        // 編集対象アイテムを初期化
        this.initializeEditItem();
        // エラーメッセージを設定
        this.errorDialogMessage = "スケジュール情報の削除に失敗しました。";
        // エラーダイアログを表示
        this.showErrorDialog = true;
        return;
      }
      // 原材料入力スケジュール設定取得APIを呼び出す
      await this.getDisplayData();
      // 編集対象アイテムを初期化
      this.initializeEditItem();
      // ローディング表示OFF
      this.isLoadingDisplay = false;
      // ダイアログを閉じる
      this.isShowDeleteRawMaterialInputScheduleSettingDialog = false;
    },
  },
});
