/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

// デバッグ中のためのconsole.log()へのwrapper関数
export function debugLog(arg0: any, ...args: any): void {
  if (process.env.NODE_ENV === "development") {
    // 開発環境の場合のみログ出力を行う
    console.log(arg0, ...args);
  }
}
