







































































































import Vue from "vue";
import { Auth } from "aws-amplify";
export default Vue.extend({
  name: "ResetPassword",
  data() {
    return {
      valid: false,
      // 認証コードを再送信のインフォメーション表示はしない仕様としたためコメントアウト
      // successMessage: "",
      showPassword: false,
      showPasswordVerify: false,
      verificationCode: "",
      mailAddress: "",
      newPassword: "",
      newPasswordVerify: "",
      showInfoMessageDialog: false,
      infoMessages: "",
      rulesAllCheck: true,
      mailAddressRules: [
        (value: unknown) => !!value || "メールアドレスは必須入力です",
      ],
    };
  },

  computed: {
    // 認証コードのバリデーション
    verificationCodeRules() {
      const rules = [
        (value: unknown) => {
          if (this.rulesAllCheck === true) {
            return !!value || "認証コードは必須入力です";
          } else {
            return true;
          }
        },
      ];
      return rules;
    },
    // 新パスワードのバリデーション
    newPasswordRules() {
      const rules = [
        (value: unknown) => {
          if (this.rulesAllCheck === true) {
            return !!value || "新パスワードは必須入力です";
          } else {
            return true;
          }
        },
      ];
      return rules;
    },
    // 新パスワード(確認)のバリデーション
    newPasswordVerifyRules() {
      const rules = [
        (value: unknown) => {
          if (this.rulesAllCheck === true) {
            return !!value || "新パスワード(確認)は必須入力です";
          } else {
            return true;
          }
        },
        (value: string) => {
          if (this.rulesAllCheck === true && value !== this.newPassword) {
            return "新パスワードが一致していません";
          } else {
            return true;
          }
        },
      ];
      return rules;
    },
  },

  methods: {
    // 他の箇所に依存するバリデーションの警告を表示させるための関数
    showValidationAlert() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.resetPasswordForm as any).validate();
    },
    // パスワード再設定ボタンの有効チェック
    isResetPasswordValid() {
      if (this.mailAddress == null || this.mailAddress === "") {
        return false;
      }
      if (this.verificationCode == null || this.verificationCode === "") {
        return false;
      }
      if (this.newPassword == null || this.newPassword === "") {
        return false;
      }
      if (this.newPasswordVerify == null || this.newPasswordVerify === "") {
        return false;
      }
      if (this.newPassword !== this.newPasswordVerify) {
        return false;
      }
      return true;
    },
    // 認証コード再送信ボタンの有効チェック
    isResendCodeValid() {
      if (this.mailAddress == null || this.mailAddress === "") {
        return false;
      }
      return true;
    },
    // 認証コード再送信処理
    async resendCode() {
      // バリデーションを全項目にしない（メールアドレスのみ）
      this.rulesAllCheck = false;
      // 入力チェックを実施
      if (
        (
          this.$refs.resetPasswordForm as Vue & { validate: () => boolean }
        ).validate()
      ) {
        // 入力チェックでエラーがない場合
        try {
          // ローディング表示オン
          this.$emit("loadingDisplay", true);
          // 認証コード送信
          await Auth.forgotPassword(this.mailAddress);
          // ローディング表示オフ
          this.$emit("loadingDisplay", false);
          // 認証コードを再送信のインフォメーション表示はしない仕様としたためコメントアウト
          // // インフォメーション表示を行う（一定時間で自動消去）
          // this.successMessage = "認証コードを再送信しました";
          // setTimeout(() => {
          //   this.successMessage = "";
          // }, 1.5 * 1000);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          console.error(err);
          // ローディング表示オフ
          this.$emit("loadingDisplay", false);
          // エラーポップアップを表示
          this.$emit("checkCognitoError", err.code);
        }
      }
      // バリデーションを全項目に戻す
      this.rulesAllCheck = true;
      // バリデーションをリセットする
      // （全項目に戻したあとリセットしないとカーソル移動でバリデーションが効かないため）
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.resetPasswordForm as any).resetValidation();
    },
    // パスワード再設定処理
    async resetPassword() {
      // バリデーションを全項目にする
      this.rulesAllCheck = true;
      // 入力チェックを実施
      if (
        (
          this.$refs.resetPasswordForm as Vue & { validate: () => boolean }
        ).validate()
      ) {
        // 入力チェックでエラーがない場合
        try {
          // ローディング表示オン
          this.$emit("loadingDisplay", true);
          // パスワードの更新
          await Auth.forgotPasswordSubmit(
            this.mailAddress,
            this.verificationCode,
            this.newPassword
          );
          // ローディング表示オフ
          this.$emit("loadingDisplay", false);
          // インフォメーションポップアップを表示する
          this.infoMessages = "パスワードを再設定しました";
          this.showInfoMessageDialog = true;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          console.error(err);
          // ローディング表示オフ
          this.$emit("loadingDisplay", false);
          // エラーポップアップを表示
          this.$emit("checkCognitoError", err.code);
        }
      }
    },
    // ログイン画面へ切り換え
    switchToLogin() {
      // ログイン画面へ切り換え
      this.$emit("switchToLogin");
      // フォームをリセットして初期状態にする（次回表示用）
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.resetPasswordForm as any).reset();
    },
    // インフォメーションポップアップを閉じる
    resetInfoDialog(): void {
      this.infoMessages = "";
      this.showInfoMessageDialog = false;
      // ログイン画面へ切り換え
      this.$emit("switchToLogin");
      // フォームをリセットして初期状態にする（次回表示用）
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.resetPasswordForm as any).reset();
    },
  },
});
