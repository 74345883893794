


















import Vue from "vue";
import SingleTank from "./SingleTank.vue";
import CustomList from "./CustomList.vue";
import { getIconInfo } from "../../lib/get_icon_info";

export default Vue.extend({
  name: "double-tank",
  components: { SingleTank, CustomList },
  props: {
    main: {
      type: Object,
      default: () => ({ title: "", icon: "", dataList: [] }),
      required: true,
    },
    sub: {
      type: Object,
      default: () => ({ title: "", dataList: [] }),
      required: true,
    },
  },
  data() {
    return {
      iconObject: getIconInfo(),
    };
  },
});
