











































































































































































































































































import Vue, { PropType } from "vue";
import dayjs from "dayjs";
import {
  DetailItem,
  ManualControlData,
  InterlockConditionItem,
  LatestPlcAddressValue,
} from "@/types";
import WebApiModel from "@/apis/web_api";
import {
  remoteControlIndividualStart,
  remoteControlIndividualStop,
  remoteControlCollectiveStartMachine,
  remoteControlCollectiveStopMachine,
} from "@/lib/web_api_util";
import store from "@/store";
import { debugLog } from "@/lib/debug_util";
import { mapState } from "vuex";

export default Vue.extend({
  name: "ManualControlDialog",
  props: {
    showDialog: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
    data: {
      type: Object as PropType<DetailItem>,
    },
    controlData: {
      type: Object as PropType<ManualControlData>,
    },
  },
  data() {
    return {
      switchValue: false,
      forceExecution: false,
      comment: "",
      detailItemData: {} as DetailItem,
      manualControlData: {
        interlockConditions: {
          run: [] as Array<InterlockConditionItem>,
          stop: [] as Array<InterlockConditionItem>,
        },
      } as ManualControlData,
      webApiModel: {} as WebApiModel,
      // ローディング表示制御
      isLoading: false,
      // メッセージダイアログ表示制御
      showMessageDialog: false,
      // メッセージダイアログ表示制御
      messageDialogType: "success",
      // メッセージダイアログメッセージ
      messageDialogMessage: "",
      // 最新状況データ
      latestValues: [] as LatestPlcAddressValue[],
    };
  },
  created() {
    // APIアクセス用ライブラリを作成
    this.webApiModel = new WebApiModel();
    this.detailItemData = Object.assign({}, this.data);
    this.manualControlData = Object.assign({}, this.controlData);
    this.forceExecution = false;
    this.comment = "";
  },
  watch: {
    latest_values_list() {
      const latests = store.getters.latest_values_list;
      const copy_list: LatestPlcAddressValue[] = [];
      if (latests != null) {
        for (let i = 0; i < latests.length; i++) {
          const latest = Object.assign({}, latests[i]);
          copy_list.push(latest);
        }
      }
      this.latestValues = copy_list;
    },
  },
  computed: {
    ...mapState(["latest_values_list"]),
    isShowDialog(): boolean {
      return this.showDialog;
    },
  },
  methods: {
    // 最新状況と成立/不成立を取得する
    getLatestValues(item: InterlockConditionItem) {
      let text = "";
      let judgment = "";
      let latest = undefined;
      const latestValues = store.getters.latest_values_list;
      // 最新状況の文字列を作成する
      if (item.dataType === "alert") {
        // アラートは発生していないときにデータがないので警報なしをデフォルト文字列とする
        text = "警報なし";
      } else if (item.dataType === "operation") {
        // オペレーションもデータがない場合があるのでFalse時文字列をデフォルト文字列とする
        text = item.displayNameForFalse ? item.displayNameForFalse : "";
      }
      // 最新データからPLCアドレスが一致するものを検索し、最新状況の表示文字列を作成する。
      for (let i = 0; i < latestValues.length; i++) {
        if (item.plcAddress === latestValues[i].plcAddress) {
          if (item.dataType === "alert") {
            if (latestValues[i].value === true) {
              text = "警報あり";
            }
          } else if (item.dataType === "operation") {
            if (latestValues[i].value) {
              text = item.displayNameForTrue ? item.displayNameForTrue : "";
            } else {
              text = item.displayNameForFalse ? item.displayNameForFalse : "";
            }
          } else if (item.dataType === "sensor") {
            if (item.judgmentType === "IN") {
              text = latestValues[i].value + "";
            } else {
              text = latestValues[i].value + " " + item.unitOfData;
            }
          }
          latest = latestValues[i].value;
          break;
        }
      }
      // 成立/不成立の判定を行う
      if (item.dataType === "alert" || item.dataType === "operation") {
        if (item.judgmentType === "EQUAL") {
          if (
            (item.value == null ? false : item.value) ===
            (latest == null ? false : latest)
          ) {
            judgment = "成立";
          } else {
            judgment = "不成立";
          }
        }
      } else {
        if (item.value == null || latest == null) {
          judgment = "";
        } else if (item.judgmentType === "EQUAL") {
          if (item.value === latest) {
            judgment = "成立";
          } else {
            judgment = "不成立";
          }
        } else if (item.judgmentType === "IN") {
          const values = (item.value + "").split(",");
          debugLog(latest, "in", values);
          if (values.includes(latest)) {
            judgment = "成立";
          } else {
            judgment = "不成立";
          }
        } else if (item.judgmentType === "SHORT") {
          if (item.value >= latest) {
            judgment = "成立";
          } else {
            judgment = "不成立";
          }
        } else if (item.judgmentType === "OVER") {
          if (item.value <= latest) {
            judgment = "成立";
          } else {
            judgment = "不成立";
          }
        } else {
          judgment = "";
        }
      }
      return [text, judgment];
    },
    // トリガー時刻を取得する
    getTriggeredTime() {
      const now = dayjs();
      const timeOffset: number = store.getters.companies_plant.time_offset;
      // 分単位の時刻オフセットから「+09:00」のような文字列を作成する。
      const timeOffsetString =
        (timeOffset > 0 ? "+" : "-") +
        String(Math.abs(timeOffset) / 60).padStart(2, "0") +
        ":" +
        String(Math.abs(timeOffset) % 60).padStart(2, "0");
      const formattedEndDateTime =
        dayjs(
          `${now.format("YYYY-MM-DD")}T${now.format("HH")}:${now.format(
            "mm"
          )}:${now.format("ss")}${timeOffsetString}`
        )
          .utcOffset(timeOffset)
          .toISOString()
          .slice(0, 19) + "Z";
      return formattedEndDateTime;
    },
    // 状態スイッチのラベルを取得
    getSwitchLabel(): string {
      let label = this.removeNewlineTag(this.detailItemData.text) + "：";
      if (
        (this.manualControlData.run_entry_value === 1 &&
          this.detailItemData.value) ||
        (this.manualControlData.run_entry_value === 0 &&
          !this.detailItemData.value)
      ) {
        // 現在、運転/開/ON状態のとき
        this.switchValue = true;
        label += this.detailItemData.textOn;
      } else {
        // 現在、停止/閉/OFF状態のとき
        this.switchValue = false;
        label += this.detailItemData.textOff;
      }
      return label;
    },
    // アクションボタンのラベルを取得
    getActionBtnName(): string {
      let btnName = "";
      if (
        (this.manualControlData.run_entry_value === 1 &&
          this.detailItemData.value) ||
        (this.manualControlData.run_entry_value === 0 &&
          !this.detailItemData.value)
      ) {
        // 現在、運転/開/ON状態のとき
        btnName = this.detailItemData.textOff;
      } else {
        // 現在、停止/閉/OFF状態のとき
        btnName = this.detailItemData.textOn;
      }
      return btnName;
    },
    // アクションボタンクリック
    async clickAction(): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // トリガー時刻を取得する
      const triggeredTime = this.getTriggeredTime();
      // 遠隔制御個別機器運転開始、遠隔制御個別機器運転停止を行う
      let response = undefined;
      if (
        (this.manualControlData.run_entry_value === 1 &&
          this.detailItemData.value) ||
        (this.manualControlData.run_entry_value === 0 &&
          !this.detailItemData.value)
      ) {
        // 現在、運転/開/ON状態のとき
        debugLog("clickAction: 停止/閉/OFF");
        if (this.detailItemData.plcAddress === "M_AUTOMATION_OUTPUT") {
          // 自動運転停止処理
          response = await remoteControlCollectiveStopMachine(
            this.webApiModel,
            store.getters.companies_plant.imsi,
            triggeredTime,
            this.comment
          );
        } else {
          // 遠隔制御個別機器運転停止処理
          response = await remoteControlIndividualStop(
            this.webApiModel,
            store.getters.companies_plant.imsi,
            this.detailItemData.plcAddress,
            this.forceExecution,
            triggeredTime,
            this.comment
          );
        }
      } else {
        // 現在、停止/閉/OFF状態のとき
        debugLog("clickAction: 運転/開/ON");
        if (this.detailItemData.plcAddress === "M_AUTOMATION_OUTPUT") {
          // 自動運転開始処理
          response = await remoteControlCollectiveStartMachine(
            this.webApiModel,
            store.getters.companies_plant.imsi,
            triggeredTime,
            this.comment
          );
        } else {
          // 遠隔制御個別機器運転開始処理
          response = await remoteControlIndividualStart(
            this.webApiModel,
            store.getters.companies_plant.imsi,
            this.detailItemData.plcAddress,
            this.forceExecution,
            triggeredTime,
            this.comment
          );
        }
      }
      // ローディング表示OFF
      this.isLoading = false;
      // エラーの場合はエラーメッセージをして中断する
      if (response === undefined) {
        // エラーポップアップを表示する
        this.messageDialogType = "error";
        this.messageDialogMessage = "手動制御に失敗しました。";
        this.showMessageDialog = true;
        return;
      }
      if (response.records.overall_result === "OK") {
        // 成功時ポップアップを表示する
        this.messageDialogType = "success";
        this.messageDialogMessage = "手動制御に成功しました。";
        this.showMessageDialog = true;
      } else {
        // APIの呼び出しは成功したが、手動制御に失敗した場合はエラーポップアップを表示する
        this.messageDialogType = "error";
        this.messageDialogMessage =
          "手動制御に失敗しました。<br />" +
          response.records.overall_result_detail;
        this.showMessageDialog = true;
      }
    },
    // ダイアログを閉じる
    closeDialog(): void {
      this.$emit("closeDialog");
    },
    // メッセージポップアップを閉じる
    closeMessageDialog(close: boolean): void {
      this.messageDialogMessage = "";
      this.showMessageDialog = false;
      if (close) {
        this.$emit("closeDialog");
      }
    },
    // 改行タグを取り除く関数
    removeNewlineTag(text: string): string {
      if (typeof text !== "string") {
        return text;
      }
      return text.replace(/<br\s*\/?>/g, "");
    },
  },
});
