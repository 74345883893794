












































































import Vue from "vue";
import router from "../router/index";
import store from "@/store";
import { Auth } from "aws-amplify";
import WebApiModel from "@/apis/web_api";
import { debugLog } from "@/lib/debug_util";
import {
  getCompaniesPlants,
  getPlantsControls,
  getPlantsFacilities,
} from "@/lib/web_api_util";

export default Vue.extend({
  name: "Login",
  data() {
    return {
      ///////////////////////////////////////////////////////////////
      // APIアクセス・ルータ関連
      ///////////////////////////////////////////////////////////////
      webApiModel: {} as WebApiModel,
      router,

      ///////////////////////////////////////////////////////////////
      // 画面表示関連
      ///////////////////////////////////////////////////////////////
      showPassword: false,
      valid: false,
      formTitle: "ログイン",
      companyId: "",
      mailAddress: "",
      password: "",

      ///////////////////////////////////////////////////////////////
      // エラー表示関連
      ///////////////////////////////////////////////////////////////
      errorMessages: "",
      showErrorDialog: false,

      ///////////////////////////////////////////////////////////////
      // バリデーションルール関連
      ///////////////////////////////////////////////////////////////
      companyIdRules: [
        (value: unknown) => !!value || "会社コードは必須入力です",
      ],
      mailAddressRules: [
        (value: unknown) => !!value || "メールアドレスは必須入力です",
      ],
      passwordRules: [
        (value: unknown) => !!value || "パスワードは必須入力です",
      ],
      disabledCompanyId: false,
    };
  },
  created: function () {
    // 会社コード指定遷移時は初期セットし変更不可とする
    if (this.$route.params.companyId) {
      this.companyId = this.$route.params.companyId;
      this.disabledCompanyId = true;
    }
    // ログイン済確認
    Auth.currentAuthenticatedUser()
      .then(() => {
        // すでにログイン済みの場合は詳細版・モバイル版画面へ遷移する
        this.toMainPage();
      })
      .catch((error) => {
        console.error(error);
      });
    // APIアクセス用ライブラリを作成
    this.webApiModel = new WebApiModel();
    // エラー表示関連のクリア
    this.errorMessages = "";
    this.showErrorDialog = false;
  },
  methods: {
    // サインイン処理
    async signIn(): Promise<void> {
      if (
        (this.$refs.loginForm as Vue & { validate: () => boolean }).validate()
      ) {
        // ローディング表示オン
        this.$emit("loadingDisplay", true);

        /////////////////////////////////////////////////////////////
        // サインイン処理
        /////////////////////////////////////////////////////////////
        try {
          // サインインを実施
          await Auth.signIn(this.mailAddress, this.password);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          console.error(err);
          // ローディング表示オフ
          this.$emit("loadingDisplay", false);
          // エラーポップアップを表示
          this.$emit("checkCognitoError", err.code);
          return;
        }

        /////////////////////////////////////////////////////////////
        // 会社コードのチェック処理
        /////////////////////////////////////////////////////////////
        // ユーザ情報に登録されている会社コード(カンマ区切りで複数指定可)に
        // 入力された会社コードが含まれているか確認し、
        // 含まれていなければエラーポップアップを表示し処理を中断する
        const user = await Auth.currentAuthenticatedUser();
        const user_company_id = user.attributes["custom:company_id"];
        debugLog("custom:company_id:", user_company_id);
        const company_id = this.companyId;
        if (
          user_company_id != null &&
          user_company_id.length > 0 &&
          !user_company_id.split(",").includes(company_id)
        ) {
          // ローディング表示オフ
          this.$emit("loadingDisplay", false);
          // サインアウトする
          this.signOut();
          // エラーポップアップを表示する
          this.errorMessages =
            "会社コードまたはメールアドレスまたはパスワードが違います";
          this.showErrorDialog = true;
          return;
        }
        // チェック済の会社コードをstoreへ格納
        store.commit("setCompanyId", company_id);

        /////////////////////////////////////////////////////////////
        // 会社プラント情報取得処理
        /////////////////////////////////////////////////////////////
        // 会社プラント情報の取得を行う
        const responsePlants = await getCompaniesPlants(
          this.webApiModel,
          company_id
        );
        // エラーの場合はエラーポップアップを表示し処理を中断する
        if (responsePlants === undefined) {
          // ローディング表示オフ
          this.$emit("loadingDisplay", false);
          // サインアウトする
          this.signOut();
          // エラーポップアップを表示する
          this.errorMessages = "会社プラント情報の取得に失敗しました";
          this.showErrorDialog = true;
          return;
        }
        // 取得した会社プラント情報（配列０番目）をstoreへ格納
        const companies_plant = responsePlants.records[0];
        store.commit("setCompaniesPlant", companies_plant);
        // 会社プラント情報の画面更新間隔をstoreへ格納
        let refresh_seconds = companies_plant.refresh_seconds;
        if (refresh_seconds == null || refresh_seconds <= 0) {
          // 未設定や0以下の場合は安全のため60秒を設定
          refresh_seconds = 60;
        }
        store.commit("setRefreshSeconds", refresh_seconds);

        /////////////////////////////////////////////////////////////
        // プラントデータ表示制御情報取得処理
        /////////////////////////////////////////////////////////////
        const imsi = store.getters.companies_plant.imsi;
        // プラントデータ表示制御情報の取得を行う
        const responseControls = await getPlantsControls(
          this.webApiModel,
          imsi
        );
        // エラーの場合はエラーポップアップを表示し処理を中断する
        if (responseControls === undefined) {
          // ローディング表示オフ
          this.$emit("loadingDisplay", false);
          // サインアウトする
          this.signOut();
          // エラーポップアップを表示する
          this.errorMessages = "プラントデータ表示制御の取得に失敗しました";
          this.showErrorDialog = true;
          return;
        }
        // 取得したプラントデータ表示制御情報配列をstoreへ格納
        const plants_controls = responseControls.records;
        store.commit("setPlantControlList", plants_controls);

        /////////////////////////////////////////////////////////////
        // プラント設備表示制御情報取得処理
        /////////////////////////////////////////////////////////////
        // プラント設備表示制御情報取得の取得を行う
        const responseFacilities = await getPlantsFacilities(
          this.webApiModel,
          imsi
        );
        // エラーの場合はエラーポップアップを表示し処理を中断する
        if (responseFacilities === undefined) {
          // ローディング表示オフ
          this.$emit("loadingDisplay", false);
          // サインアウトする
          this.signOut();
          // エラーポップアップを表示する
          this.errorMessages = "プラント設備表示制御情報の取得に失敗しました";
          this.showErrorDialog = true;
          return;
        }

        // 取得したプラント設備表示制御情報配列をstoreへ格納
        const plants_facilities = responseFacilities.records;
        store.commit("setPlantFacility", plants_facilities);

        // ローディング表示オフ
        this.$emit("loadingDisplay", false);
        // メインページへ遷移
        this.toMainPage();
      }
    },
    // メインページへ遷移
    toMainPage() {
      // モバイルの場合はモバイル版画面に遷移
      if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
        router.push("/mobiledashboard/mobile");
      } else {
        // モバイル以外の場合は詳細版画面に遷移
        router.push("/dashboard/detail");
      }
    },
    // パスワードリセット画面へ切り換え
    switchToSendCode() {
      // パスワードリセット画面へ切り換え
      this.$emit("switchToSendCode");
      // 入力状態をクリアする（次回表示用）
      if (this.$route.name !== "CompanyLogin") {
        // URLに会社コードがない場合のみ会社コードをクリア
        this.companyId = "";
      }
      this.mailAddress = "";
      this.password = "";
      // バリデーションをリセットする（次回表示用）
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.loginForm as any).resetValidation();
    },
    // サインアウト
    async signOut() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
    // エラーポップアップを閉じる
    resetErrorDialog(): void {
      this.errorMessages = "";
      this.showErrorDialog = false;
    },
  },
});
