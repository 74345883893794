







import Vue from "vue";
import Header from "@/components/Header.vue";

export default Vue.extend({
  name: "DashBoard",
  components: {
    Header,
  },
});
