import Vue from "vue";
import Vuetify from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";
import ja from "vuetify/src/locale/ja";
import AdjustmentTankIcon from "../components/IconComponent/AdjustmentTankIcon.vue";
import AirPumpIconIcon from "../components/IconComponent/AirPumpIconIcon.vue";
import DigestiveJuiceStorageTankIcon from "../components/IconComponent/DigestiveJuiceStorageTankIcon.vue";
import DilutedLiquidDischargeTankIcon from "../components/IconComponent/DilutedLiquidDischargeTankIcon.vue";
import FermentationTankIcon from "../components/IconComponent/FermentationTankIcon.vue";
import GasPackIcon from "../components/IconComponent/GasPackIcon.vue";
import GasTankIcon from "../components/IconComponent/GasTankIcon.vue";
import HotWaterTankIcon from "../components/IconComponent/HotWaterTankIcon.vue";
import WeighingTankIcon from "../components/IconComponent/WeighingTankIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      adjustmentTankIcon: {
        component: AdjustmentTankIcon,
      },
      airPumpIconIcon: {
        component: AirPumpIconIcon,
      },
      digestiveJuiceStorageTankIcon: {
        component: DigestiveJuiceStorageTankIcon,
      },
      dilutedLiquidDischargeTankIcon: {
        component: DilutedLiquidDischargeTankIcon,
      },
      fermentationTankIcon: {
        component: FermentationTankIcon,
      },
      gasPackIcon: {
        component: GasPackIcon,
      },
      gasTankIcon: {
        component: GasTankIcon,
      },
      hotWaterTankIcon: {
        component: HotWaterTankIcon,
      },
      weighingTankIcon: {
        component: WeighingTankIcon,
      },
    },
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#319DAC",
        secondary: "#AB309C",
        error: "#AB3E30",
        warning: "#9CAB30",
        info: "#3E30AB",
        success: "#30AB3E",
      },
    },
  },
  lang: {
    locales: { ja },
    current: "ja",
  },
});
