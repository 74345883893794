








import Vue from "vue";
export default Vue.extend({
  name: "arrow-left",
  props: {
    arrowColor: { type: String, default: "#e76d57" },
  },
  mounted() {
    const targetElement = this.$refs["arrow-left"] as HTMLElement;
    if (targetElement) {
      targetElement.style.setProperty("--arrowColor", this.arrowColor);
    }
  },
  watch: {
    arrowColor: {
      immediate: true,
      handler(newColor: string) {
        this.updateArrowColor(newColor);
      },
    },
  },
  methods: {
    updateArrowColor(color: string) {
      const targetElement = this.$refs["arrow-left"] as HTMLElement;
      if (targetElement) {
        targetElement.style.setProperty("--arrowColor", color);
      }
    },
  },
});
