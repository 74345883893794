





































































































































































































































































































































































































































import Vue from "vue";
import { bus } from "../main";
import dayjs from "dayjs";
// utcは呼び元のVueのcreatedで参照している。
// このソースファイルではutcOffsetメソッドで不当にエラー指摘されないようimportしている。
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import utc from "dayjs/plugin/utc";
import store from "@/store";
import { PlantControl, DisplayDataTime } from "@/types";
import Papa from "papaparse";
import WebApiModel from "@/apis/web_api";
import {
  getRawMaterialInputScheduleSetting,
  updateRawMaterialInputScheduleSetting,
  registerRawMaterialInputScheduleSetting,
  deleteRawMaterialInputScheduleSetting,
  getAutomaticSequenceControlParameterSetting,
  updateAutomaticSequenceControlParameterSetting,
} from "@/lib/web_api_util";
import { debugLog } from "@/lib/debug_util";
export default Vue.extend({
  name: "Mobile",
  data() {
    return {
      // パネル表示制御
      panelState: [0],
      // 設定種別
      settingType: "autoOperationParam",
      // 開始日時格納
      startDatetime: {
        hour: "00",
        minute: "00",
      },
      // 時項目コンボボックス値
      hours: ["Dummy"],
      // 分項目コンボボックス値
      minutes: ["Dummy"],
      // WebAPI用変数
      webApiModel: {} as WebApiModel,
      // 取得制限件数オーバーフラグ
      isTooManyResults: false,
      // 条件設定エラーダイアログ表示制御
      isShowDownloadErrorDialog: false,
      // 条件設定エラータイプ
      downloadErrorType: "",
      // 条件設定エラーメッセージ
      downloadErrorMessages: "",
      // ローディング表示制御
      isLoading: false,
      // データ表示の対象絞り込み選択用
      filterTarget: null,
      // データ表示の一覧ヘッダー
      headers: [{}],
      // データ表示の一覧データ
      datasets: [{}],
      // エラーダイアログ表示制御
      isShowErrorDialog: false,
      // エラーダイアログメッセージ
      errorDialogMessage: "",

      // 編集ダイアログ表示制御
      isShowEditDialog: false,
      // 編集ダイアログの編集対象インデックス
      editIndex: -1,
      // 編集ダイアログの編集対象アイテム
      editItem: {} as { [key: string]: any },
      // 編集ダイアログの編集対象アイテム（更新前）
      beforeItem: {} as { [key: string]: any },
      // 削除ダイアログ表示制御
      deleteDialog: false,
      // 削除ダイアログの削除対象インデックス
      deleteIndex: -1,
      // 削除ダイアログの削除対象アイテム
      deleteItem: {},
      // 原材料入力スケジュール設定
      rawMaterialInputScheduleSetting: [],
      // 登録ダイアログ表示制御
      isShowRegisterDialog: false,
      // 自動運転パラメータ設定
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      automaticSequenceControlParameterSetting: [] as Array<any>,

      ///////////////////////////////////////////////////////////////
      // バリデーションルール関連
      ///////////////////////////////////////////////////////////////
      inputPeriodRules: [
        (value: unknown) => !!value || "投入期間は必須入力です",
        (value: string) =>
          /^\d{1,3}$/.test(value) || "3桁までの数字で入力してください",
      ],
    };
  },
  created() {
    // 背景イメージなし
    bus.$emit("background", "noImage");
    // 時間の配列作成
    this.hours = [];
    for (let i = 0; i <= 23; i++) {
      this.hours.push(String(i).padStart(2, "0"));
    }
    // 分の配列作成
    this.minutes = [];
    for (let i = 0; i <= 59; i++) {
      this.minutes.push(String(i).padStart(2, "0"));
    }
    // APIアクセス用ライブラリを作成
    this.webApiModel = new WebApiModel();
    // 自動運転パラメータ設定取得APIを呼び出す
    this.getAutomaticSequenceControlParameterSetting();
  },
  computed: {},

  methods: {
    // 原材料入力スケジュール設定取得
    async getRawMaterialInputScheduleSetting(): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 原材料入力スケジュール設定取得APIを呼び出す
      const responseRawMaterialInputScheduleSetting =
        await getRawMaterialInputScheduleSetting(
          this.webApiModel,
          store.getters.companies_plant.imsi
        );
      // エラーの場合は中断する
      if (
        typeof responseRawMaterialInputScheduleSetting === "string" ||
        responseRawMaterialInputScheduleSetting === undefined
      ) {
        // 編集対象アイテムを初期化
        this.initializeEditItem();
        // ローディング表示OFF
        this.isLoading = false;
        // エラーメッセージを設定
        this.errorDialogMessage =
          typeof responseRawMaterialInputScheduleSetting === "string"
            ? responseRawMaterialInputScheduleSetting
            : "スケジュール情報の取得に失敗しました。";
        // エラーダイアログを表示
        this.isShowErrorDialog = true;
        return;
      }
      // start_timeをJSTに変換
      this.convertStartTimeToLocalTime(
        responseRawMaterialInputScheduleSetting.records
      );
      // 取得したデータを格納
      this.rawMaterialInputScheduleSetting =
        responseRawMaterialInputScheduleSetting.records;
      // 編集対象アイテムを初期化
      this.initializeEditItem();
      // ローディング表示OFF
      this.isLoading = false;
    },

    // 原材料入力スケジュール設定登録
    async registerRawMaterialInputScheduleSetting(): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 原材料入力スケジュール設定登録APIを呼び出す
      const responseRawMaterialInputScheduleSetting =
        await registerRawMaterialInputScheduleSetting(
          this.webApiModel,
          store.getters.companies_plant.imsi,
          "input_raw_materials_to_conditioning_tank",
          this.convertStartTimeToUTC(
            this.startDatetime.hour + this.startDatetime.minute
          ),
          Number(this.editItem.input_period),
          this.editItem.is_enabled !== undefined
            ? this.editItem.is_enabled
            : false
        );
      // エラーの場合は中断する
      if (
        typeof responseRawMaterialInputScheduleSetting === "string" ||
        responseRawMaterialInputScheduleSetting === undefined
      ) {
        // 編集対象アイテムを初期化
        this.initializeEditItem();
        // ローディング表示OFF
        this.isLoading = false;
        // エラーメッセージを設定
        this.errorDialogMessage =
          typeof responseRawMaterialInputScheduleSetting === "string"
            ? responseRawMaterialInputScheduleSetting
            : "スケジュール情報の登録に失敗しました。";
        // エラーダイアログを表示
        this.isShowErrorDialog = true;
        return;
      }
      // 原材料入力スケジュール設定取得APIを呼び出す
      await this.getRawMaterialInputScheduleSetting();
      // ローディング表示OFF
      this.isLoading = false;
      // 編集対象アイテムを初期化
      this.initializeEditItem();
      // ダイアログを閉じる
      this.isShowRegisterDialog = false;
    },

    // 原材料入力スケジュール設定更新
    async updateRawMaterialInputScheduleSetting(): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 原材料入力スケジュール設定更新APIを呼び出す
      const responseRawMaterialInputScheduleSetting =
        await updateRawMaterialInputScheduleSetting(
          this.webApiModel,
          store.getters.companies_plant.imsi,
          "input_raw_materials_to_conditioning_tank",
          this.convertStartTimeToUTC(
            this.startDatetime.hour + this.startDatetime.minute
          ),
          Number(this.editItem.input_period),
          this.editItem.is_enabled,
          this.beforeItem
        );
      // エラーの場合は中断する
      if (
        typeof responseRawMaterialInputScheduleSetting === "string" ||
        responseRawMaterialInputScheduleSetting === undefined
      ) {
        // 編集対象アイテムを初期化
        this.initializeEditItem();
        // ローディング表示OFF
        this.isLoading = false;
        // エラーメッセージを設定
        this.errorDialogMessage =
          typeof responseRawMaterialInputScheduleSetting === "string"
            ? responseRawMaterialInputScheduleSetting
            : "スケジュール情報の更新に失敗しました。";
        // エラーダイアログを表示
        this.isShowErrorDialog = true;
        return;
      }
      // 原材料入力スケジュール設定取得APIを呼び出す
      await this.getRawMaterialInputScheduleSetting();
      // 編集対象アイテムを初期化
      this.initializeEditItem();
      // ローディング表示OFF
      this.isLoading = false;
      // ダイアログを閉じる
      this.isShowEditDialog = false;
    },

    // 原材料入力スケジュール設定削除
    async deleteRawMaterialInputScheduleSetting(): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 原材料入力スケジュール設定削除APIを呼び出す
      const responseRawMaterialInputScheduleSetting =
        await deleteRawMaterialInputScheduleSetting(
          this.webApiModel,
          store.getters.companies_plant.imsi,
          "input_raw_materials_to_conditioning_tank",
          this.convertStartTimeToUTC(
            this.startDatetime.hour + this.startDatetime.minute
          )
        );
      // エラーの場合は中断する
      if (responseRawMaterialInputScheduleSetting === undefined) {
        // 編集対象アイテムを初期化
        this.initializeEditItem();
        // ローディング表示OFF
        this.isLoading = false;
        // エラーメッセージを設定
        this.errorDialogMessage = "スケジュール情報の削除に失敗しました。";
        // エラーダイアログを表示
        this.isShowErrorDialog = true;
        return;
      }
      // 原材料入力スケジュール設定取得APIを呼び出す
      await this.getRawMaterialInputScheduleSetting();
      // 編集対象アイテムを初期化
      this.initializeEditItem();
      // ローディング表示OFF
      this.isLoading = false;
      // ダイアログを閉じる
      this.deleteDialog = false;
    },

    // 開始時刻をローカル時刻に変換
    convertStartTimeToLocalTime(records: any[]): void {
      records.forEach((record: any) => {
        const utcHour = parseInt(record.start_time.slice(0, 2), 10);
        const utcMinute = record.start_time.slice(2);
        const timeOffsetMin = store.getters.companies_plant.time_offset;
        const timeOffsetHour = Math.floor(timeOffsetMin / 60);
        let jstHour = utcHour + timeOffsetHour;
        if (jstHour >= 24) {
          jstHour -= 24;
        }
        record.start_time = `${jstHour
          .toString()
          .padStart(2, "0")}${utcMinute}`;
      });
    },

    // 開始時刻をUTCに変換
    convertStartTimeToUTC(startTimeLocal: string): string {
      const hourLocal = startTimeLocal.slice(0, 2);
      const minuteLocal = startTimeLocal.slice(2);
      const timeOffsetMin = store.getters.companies_plant.time_offset;
      const timeOffsetHour = Math.floor(timeOffsetMin / 60);
      let hourUTC = parseInt(hourLocal, 10) - timeOffsetHour;
      if (hourUTC < 0) {
        hourUTC += 24;
      }
      return `${hourUTC.toString().padStart(2, "0")}${minuteLocal}`;
    },

    // 編集対象アイテムを初期化
    initializeEditItem(): void {
      this.editItem = {};
      this.beforeItem = {};
      this.startDatetime = {
        hour: "00",
        minute: "00",
      };
    },

    // 自動運転パラメータ設定取得
    async getAutomaticSequenceControlParameterSetting(): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 自動運転パラメータ設定取得APIを呼び出す
      const responseAutomaticSequenceControlParameterSetting =
        await getAutomaticSequenceControlParameterSetting(
          this.webApiModel,
          store.getters.companies_plant.imsi
        );
      // エラーの場合は中断する
      if (
        typeof responseAutomaticSequenceControlParameterSetting === "string" ||
        responseAutomaticSequenceControlParameterSetting === undefined
      ) {
        // ローディング表示OFF
        this.isLoading = false;
        // エラーメッセージを設定
        this.errorDialogMessage =
          typeof responseAutomaticSequenceControlParameterSetting === "string"
            ? responseAutomaticSequenceControlParameterSetting
            : "自動運転パラメータ情報の取得に失敗しました。";
        // エラーダイアログを表示
        this.isShowErrorDialog = true;
        return;
      }

      // 取得したデータを格納
      this.automaticSequenceControlParameterSetting =
        responseAutomaticSequenceControlParameterSetting.records;

      // ローディング表示OFF
      this.isLoading = false;
    },

    // 自動運転パラメータ設定更新
    async updateAutomaticSequenceControlParameterSetting(): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 自動運転パラメータ設定更新APIを呼び出す
      const responseAutomaticSequenceControlParameterSetting =
        await updateAutomaticSequenceControlParameterSetting(
          this.webApiModel,
          store.getters.companies_plant.imsi,
          this.editItem.param_id,
          this.editItem.status
        );
      // エラーの場合は中断する
      if (
        typeof responseAutomaticSequenceControlParameterSetting === "string" ||
        responseAutomaticSequenceControlParameterSetting === undefined
      ) {
        // 編集対象アイテムを初期化
        this.initializeEditItem();
        // ローディング表示OFF
        this.isLoading = false;
        // エラーメッセージを設定
        this.errorDialogMessage =
          typeof responseAutomaticSequenceControlParameterSetting === "string"
            ? responseAutomaticSequenceControlParameterSetting
            : "自動運転パラメータ情報の更新に失敗しました。";
        // エラーダイアログを表示
        this.isShowErrorDialog = true;
        return;
      }
      // 自動運転パラメータ設定取得APIを呼び出す
      await this.getAutomaticSequenceControlParameterSetting();
      // ローディング表示OFF
      this.isLoading = false;
      // ダイアログを閉じる
      this.isShowEditDialog = false;
    },

    // 日時文字列をローカル時刻表示に変換
    formatDatetime(strDateTime: string): string {
      // ローカル時刻表示をさせないため、ISO 8601フォーマットの日時文字列から
      // 「+09:00」のような時刻オフセット部分を削除してから、指定の日時文字列に変換して返す。
      let strChangeDateTime = "";
      if (strDateTime != null) {
        strChangeDateTime = strDateTime.substring(0, 19);
      }
      let resultStrDateTime = "";
      resultStrDateTime = dayjs(strChangeDateTime).format("YYYY-MM-DD HH:mm");
      return resultStrDateTime;
    },
    // エラーポップアップを閉じる
    resetErrorDialog(): void {
      this.errorDialogMessage = "";
      this.isShowErrorDialog = false;
      this.isShowEditDialog = false;
      this.isShowRegisterDialog = false;
      this.deleteDialog = false;
    },
    // 編集ダイアログを開く
    showEditDialog(item: any): void {
      if (this.settingType === "rawMaterialSchedule") {
        this.startDatetime.hour = item.start_time.slice(0, 2);
        this.startDatetime.minute = item.start_time.slice(2);
      }
      this.editIndex = this.datasets.indexOf(item);
      this.editItem = Object.assign({}, item);
      this.beforeItem = {};
      this.isShowEditDialog = true;
    },
    // 削除ダイアログを開く
    showDeleteDialog(item: any): void {
      this.startDatetime.hour = item.start_time.slice(0, 2);
      this.startDatetime.minute = item.start_time.slice(2);
      this.deleteIndex = this.datasets.indexOf(item);
      this.deleteItem = Object.assign({}, item);
      this.deleteDialog = true;
    },
  },
});
