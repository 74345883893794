import Amplify, { Auth } from "aws-amplify";
import awsExports from "./aws_exports";
import router from "@/router";
import { Route } from "vue-router";
Amplify.configure(awsExports);

// 認証確認
// eslint-disable-next-line @typescript-eslint/no-explicit-any
router.beforeEach((to: Route, from: Route, next: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (to.matched.some((record: any) => record.meta.requiredAuth)) {
    Auth.currentAuthenticatedUser()
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          path: "login",
          query: {
            redirect: to.fullPath,
          },
        });
      });
  } else {
    next();
  }
});
