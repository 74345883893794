export const getIconInfo = function () {
  return {
    adjustmentTankIcon: "$vuetify.icons.adjustmentTankIcon",
    airPumpIconIcon: "$vuetify.icons.airPumpIconIcon",
    digestiveJuiceStorageTankIcon:
      "$vuetify.icons.digestiveJuiceStorageTankIcon",
    dilutedLiquidDischargeTankIcon:
      "$vuetify.icons.dilutedLiquidDischargeTankIcon",
    fermentationTankIcon: "$vuetify.icons.fermentationTankIcon",
    gasPackIcon: "$vuetify.icons.gasPackIcon",
    gasTankIcon: "$vuetify.icons.gasTankIcon",
    hotWaterTankIcon: "$vuetify.icons.hotWaterTankIcon",
    weighingTankIcon: "$vuetify.icons.weighingTankIcon",
  };
};
