



















































































































































































































































































































































































import Vue from "vue";
import { bus } from "../main";
import dayjs from "dayjs";
// utcは呼び元のVueのcreatedで参照している。
// このソースファイルではutcOffsetメソッドで不当にエラー指摘されないようimportしている。
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import utc from "dayjs/plugin/utc";
import store from "@/store";
import { PlantControl, DisplayDataTime } from "@/types";
import Papa from "papaparse";
import WebApiModel from "@/apis/web_api";
import {
  getRawLogData,
  getDailyReportLogData,
  getOperationLogData,
  getAlertLogData,
} from "@/lib/web_api_util";
import { debugLog } from "@/lib/debug_util";
export default Vue.extend({
  name: "Mobile",
  data() {
    return {
      // ダウンロード種別
      downloadType: "rawData",
      // 開始日付カレンダー表示制御
      startDateCalender: false,
      // 開始日時格納
      startDatetime: {
        date: "",
        hour: "",
        minute: "",
      },
      // 終了日付カレンダー表示制御
      endDateCalender: false,
      // 終了日時格納
      endDatetime: {
        date: "",
        hour: "",
        minute: "",
      },
      // 時項目コンボボックス値
      hours: ["Dummy"],
      // 分項目コンボボックス値
      minutes: ["Dummy"],
      // WebAPI用変数
      webApiModel: {} as WebApiModel,
      // ダウンロードダイアログ表示制御
      downloadDialog: false,
      // ダウンロードデータ件数
      dataCount: 0,
      // CSV文字列(ヘッダー付き)
      strCSVData: "",
      // 取得制限件数オーバーフラグ
      isTooManyResults: false,
      // 条件設定エラーダイアログ表示制御
      downloadErrorDialog: false,
      // 条件設定エラータイプ
      downloadErrorType: "",
      // 条件設定エラーメッセージ
      downloadErrorMessages: "",
      // ローディング表示制御
      isLoading: false,
      // 表示パネル制御
      downloadPanelState: [0],
      // ダウンロードデータパネル表示制御
      isDataViewPanelVisible: false,
      // データ表示の表示項目選択表示制御
      isTypeColumnSelect: false,
      // データ表示の表示項目選択肢
      columnItems: [] as Array<string>,
      // データ表示の表示項目選択用
      selectedColumns: [] as Array<string>,
      // 日報データの表示項目(日報データ、積算で使用)
      reportColumns: [] as Array<string>,
      // 積算データの表示項目(日報データ、積算で使用)
      additionColumns: [] as Array<string>,
      // データ表示の対象絞り込み表示制御
      isTypeFilter: false,
      // データ表示の対象絞り込み選択肢
      targetItems: [] as Array<string>,
      // データ表示の対象絞り込み選択用
      filterTarget: null,
      // データ表示の一覧ヘッダー
      headers: [{}],
      // データ表示の一覧データ
      datasets: [{}],
      // データ表示の一覧ソートアイテム
      sortItem: "record_time",
      // データ表示の一覧ソート順
      sortDesc: false,
      // エラーダイアログ表示制御
      showErrorDialog: false,
      // エラーダイアログメッセージ
      errorDialogMessage: "",
    };
  },
  created() {
    // 背景イメージなし
    bus.$emit("background", "noImage");
    // 時間の配列作成
    this.hours = [];
    for (let i = 0; i <= 23; i++) {
      this.hours.push(String(i).padStart(2, "0"));
    }
    // 分の配列作成
    this.minutes = [];
    for (let i = 0; i <= 59; i++) {
      this.minutes.push(String(i).padStart(2, "0"));
    }
    // APIアクセス用ライブラリを作成
    this.webApiModel = new WebApiModel();
  },
  computed: {
    selectAllColumns(): boolean {
      return this.selectedColumns.length === this.columnItems.length;
    },
    selectSomeColumns(): boolean {
      return this.selectedColumns.length > 0 && !this.selectAllColumns;
    },
    selectReportAllColumns(): boolean {
      // 日報データの表示項目のうち、選択されていない項目を抽出
      const notSelectedReportColumns = this.reportColumns.filter(
        (i) => this.selectedColumns.indexOf(i) == -1
      );
      return notSelectedReportColumns.length === 0;
    },
    selectReportSomeColumns(): boolean {
      // 日報データの表示項目のうち、選択されている項目を抽出
      const selectedReportColumns = this.selectedColumns.filter(
        (i) => this.reportColumns.indexOf(i) >= 0
      );
      return selectedReportColumns.length > 0 && !this.selectReportAllColumns;
    },
    selectAdditionAllColumns(): boolean {
      // 日報データの表示項目のうち、選択されていない項目を抽出
      const notSelectedAdditionColumns = this.additionColumns.filter(
        (i) => this.selectedColumns.indexOf(i) == -1
      );
      return notSelectedAdditionColumns.length === 0;
    },
    selectAdditionSomeColumns(): boolean {
      // 日報データの表示項目のうち、選択されている項目を抽出
      const selectedAdditionColumns = this.selectedColumns.filter(
        (i) => this.additionColumns.indexOf(i) >= 0
      );
      return (
        selectedAdditionColumns.length > 0 && !this.selectAdditionAllColumns
      );
    },
    getSelectIcon(): string {
      if (this.selectAllColumns) return "mdi-close-box";
      if (this.selectSomeColumns) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    getSelectReportIcon(): string {
      if (this.selectReportAllColumns) return "mdi-close-box";
      if (this.selectReportSomeColumns) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    getSelectAdditionIcon(): string {
      if (this.selectAdditionAllColumns) return "mdi-close-box";
      if (this.selectAdditionSomeColumns) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    downloadTargetName(): string {
      let downloadTargetName = "生データ";
      if (this.downloadType === "dailyReportData") {
        downloadTargetName = "日報データ";
      } else if (this.downloadType === "operationHistory") {
        downloadTargetName = "運転履歴";
      } else if (this.downloadType === "alertHistory") {
        downloadTargetName = "アラート履歴";
      } else if (this.downloadType === "additionData") {
        downloadTargetName = "積算";
      }
      return downloadTargetName;
    },
    rangeDataTime(): string {
      let strRange = "";
      if (this.startDatetime.date !== "") {
        strRange =
          strRange +
          this.startDatetime.date +
          " " +
          this.startDatetime.hour +
          ":" +
          this.startDatetime.minute;
      }
      strRange = strRange + " ~ ";
      if (this.endDatetime.date !== "") {
        strRange =
          strRange +
          this.endDatetime.date +
          " " +
          this.endDatetime.hour +
          ":" +
          this.endDatetime.minute;
      }
      return strRange;
    },
  },
  watch: {
    selectedColumns: {
      handler: function () {
        this.changeTableHeaders();
      },
    },
    downloadType: {
      handler: function () {
        this.isDataViewPanelVisible = false;
      },
    },
    startDatetime: {
      handler: function () {
        this.isDataViewPanelVisible = false;
      },
      deep: true,
    },
    endDatetime: {
      handler: function () {
        this.isDataViewPanelVisible = false;
      },
      deep: true,
    },
  },
  methods: {
    openDataView(): void {
      this.downloadErrorType = "DataView";
      this.downloadErrorMessages = "";
      // 入力値チェック
      if (
        !this.validateInput(
          this.downloadType,
          this.startDatetime,
          this.endDatetime
        )
      ) {
        this.downloadErrorDialog = true;
        return;
      }
      // 一覧のヘッダーとデータを表示
      this.createTableHeaders(this.downloadType);
      this.createTableDatasets(this.downloadType);
      if (this.showErrorDialog === true) {
        // WebAPIアクセスでエラー発生時等
        return;
      }
      // 日報データまたは積算データであれば表示項目選択を、それ以外は対象絞り込みを表示
      if (
        this.downloadType === "dailyReportData" ||
        this.downloadType === "additionData"
      ) {
        this.isTypeColumnSelect = true;
        this.isTypeFilter = !this.isTypeColumnSelect;
      } else {
        this.isTypeColumnSelect = false;
        this.isTypeFilter = !this.isTypeColumnSelect;
      }
      this.isDataViewPanelVisible = true;
      this.downloadPanelState = [1];
    },
    openDownloadCsvData(): void {
      // CSVデータ格納領域初期化
      this.strCSVData = "";
      this.dataCount = 0;
      this.isTooManyResults = false;
      this.downloadErrorType = "Download";
      this.downloadErrorMessages = "";
      // 入力値チェック
      if (
        !this.validateInput(
          this.downloadType,
          this.startDatetime,
          this.endDatetime
        )
      ) {
        this.downloadErrorDialog = true;
        return;
      }
      // 日付形式変換
      const startTime = this.formatStartTime(this.startDatetime);
      const endTime = this.formatEndDateTime(this.endDatetime);
      // ダウンロードタイプごとにCSVデータ生成
      switch (this.downloadType) {
        case "rawData":
          debugLog("生データダウンロード");
          this.downloadRawDataHistory(startTime, endTime);
          break;
        case "dailyReportData":
          debugLog("日報データダウンロード");
          this.downloadDailyReportHistory(startTime, endTime);
          break;
        case "operationHistory":
          this.downloadOperationHistory(startTime, endTime);
          debugLog("運転履歴ダウンロード");
          break;
        case "alertHistory":
          debugLog("アラート履歴ダウンロード");
          this.downloadAlertHistory(startTime, endTime);
          break;
        case "additionData":
          debugLog("積算データダウンロード");
          this.downloadDailyReportHistory(startTime, endTime);
          break;
        default:
          debugLog("想定外エラー");
      }
    },
    // 入力チェック（全種別共通）
    validateInput(
      inDownloadType: string,
      inStartTime: DisplayDataTime,
      inEndTime: DisplayDataTime
    ): boolean {
      // Validate結果
      let validateResult = true;
      // データ種別：必須入力
      if (inDownloadType === undefined || inDownloadType === "") {
        this.downloadErrorMessages +=
          "ダウンロード種別が選択されていません。<br />";
        validateResult = false;
      }
      // 開始日時：いずれかに入力がある場合、日時分のすべて入力されていること
      if (
        inStartTime.date !== "" ||
        inStartTime.hour !== "" ||
        inStartTime.minute !== ""
      ) {
        if (
          inStartTime.date === "" ||
          inStartTime.hour === "" ||
          inStartTime.minute === ""
        ) {
          this.downloadErrorMessages +=
            "開始日時を指定する場合は年月日時刻をすべて入力してください。<br />";
          validateResult = false;
        }
      }
      // 終了日時：いずれかに入力がある場合、日時分のすべて入力されていること
      if (
        inEndTime.date !== "" ||
        inEndTime.hour !== "" ||
        inEndTime.minute !== ""
      ) {
        if (
          inEndTime.date === "" ||
          inEndTime.hour === "" ||
          inEndTime.minute === ""
        ) {
          this.downloadErrorMessages +=
            "終了日時を指定する場合は年月日時刻をすべて入力してください。<br />";
          validateResult = false;
        }
      }
      // 日付相関チェック
      if (validateResult) {
        // 日付形式変換
        const startTime = this.formatStartTime(this.startDatetime);
        const endTime = this.formatEndDateTime(this.endDatetime);
        // 開始日時、終了日時がともに未入力の場合はエラー
        if (startTime === null && endTime === null) {
          this.downloadErrorMessages +=
            "開始日時・終了日時のいずれかは必ず指定してください。<br />";
          validateResult = false;
        }
        // 開始日時が終了日時よりも後の場合はエラー（同じ日時もエラー）
        if (startTime !== null && endTime !== null) {
          if (startTime >= endTime) {
            this.downloadErrorMessages +=
              "開始日時に終了日時より前の日時は入力できません。<br />";
            validateResult = false;
          }
        }
      }
      return validateResult;
    },
    async downloadRawDataHistory(
      start: string | null,
      end: string | null
    ): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      //生データ履歴取得APIを呼び出す
      debugLog("開始日時");
      debugLog(start);
      debugLog("終了日時");
      debugLog(end);
      const responseRawLogData = await getRawLogData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        start,
        end
      );
      // エラーの場合は中断する
      if (responseRawLogData === undefined) {
        // ローディング表示OFF
        this.isLoading = false;
        this.downloadErrorMessages = "データ取得に失敗しました。";
        this.downloadErrorDialog = true;
        return;
      }
      // データ件数・取得制限結果取得
      this.dataCount = 0;
      this.isTooManyResults = false;
      if (responseRawLogData.records !== undefined) {
        this.dataCount = responseRawLogData.records.length;
        this.isTooManyResults = responseRawLogData.too_many_results;
      }
      // データ０件の場合はCSVデータは生成しない
      if (this.dataCount > 0) {
        // CSVヘッダー作成
        const csvHeader = [["IMSI", "会社コード", "データ日時"]];
        // CSVデータ部生成
        const csvConfig = {
          quotes: true,
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ",",
          header: false,
          newline: "\r\n",
          skipEmptyLines: false,
          columns: ["imsi", "company_id", "record_time"],
        };
        // ヘッダー、データ部をアペンド
        const rawControlList = this.getRawDataControlList();
        rawControlList.forEach((plantControl: PlantControl) => {
          let headerText =
            plantControl.plc_address + "|" + plantControl.display_name_full;
          if (plantControl.unit_of_data) {
            headerText = headerText + "|" + plantControl.unit_of_data;
          }
          csvHeader[0].push(headerText);
          let column = plantControl.display_name_en;
          csvConfig.columns.push(column);
        });
        // アラート部分を追加
        csvHeader[0].push("アラート発生件数");
        csvHeader[0].push("アラート発生内容");
        csvConfig.columns.push("number_of_alerts");
        csvConfig.columns.push("alert_array");
        // CSVファイル生成
        this.strCSVData = Papa.unparse(csvHeader, { quotes: true }) + "\r\n";
        const rawLogData = responseRawLogData.records;
        this.strCSVData += Papa.unparse(rawLogData, csvConfig);
      }
      // ローディング表示OFF
      this.isLoading = false;
      // ダウンロード確認ダイアログ表示
      this.downloadDialog = true;
    },
    async downloadDailyReportHistory(
      start: string | null,
      end: string | null
    ): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      //日報データ取得APIを呼び出す
      const responseDailyReportLogData = await getDailyReportLogData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        start,
        end
      );
      // エラーの場合は中断する
      if (responseDailyReportLogData === undefined) {
        // ローディング表示OFF
        this.isLoading = false;
        this.downloadErrorMessages = "データ取得に失敗しました。";
        this.downloadErrorDialog = true;
        return;
      }
      // データ件数・取得制限結果取得
      this.dataCount = 0;
      this.isTooManyResults = false;
      if (responseDailyReportLogData.records !== undefined) {
        this.dataCount = responseDailyReportLogData.records.length;
        this.isTooManyResults = responseDailyReportLogData.too_many_results;
      }
      // データ０件の場合はCSVデータは生成しない
      if (this.dataCount > 0) {
        // CSVヘッダー作成
        const csvHeader = [["IMSI", "会社コード", "日報日時"]];
        // CSVデータ部生成
        const csvConfig = {
          quotes: true,
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ",",
          header: false,
          newline: "\r\n",
          skipEmptyLines: false,
          columns: ["imsi", "company_id", "record_time"],
        };
        // ヘッダー、データ部をアペンド
        const DailyReportControlList = this.getReportControlList();
        DailyReportControlList.forEach((plantControl: PlantControl) => {
          let headerText =
            plantControl.plc_address +
            "|" +
            plantControl.report_name +
            "|" +
            plantControl.unit_of_data +
            "|" +
            plantControl.type_for_report;
          csvHeader[0].push(headerText);
          let column = plantControl.display_name_en;
          csvConfig.columns.push(column);
        });
        // CSVファイル生成
        this.strCSVData = Papa.unparse(csvHeader, { quotes: true }) + "\r\n";
        const dailyReportLogData = responseDailyReportLogData.records;
        this.strCSVData += Papa.unparse(dailyReportLogData, csvConfig);
      }
      // ローディング表示OFF
      this.isLoading = false;
      // ダウンロード確認ダイアログ表示
      this.downloadDialog = true;
    },
    async downloadAlertHistory(
      inStartDatetime: string | null,
      inEndDatetime: string | null
    ): Promise<void> {
      // パラメータ表示
      debugLog(inStartDatetime);
      debugLog(inEndDatetime);
      // ローディング表示ON
      this.isLoading = true;
      // プラントアラート履歴取得APIを呼び出す
      const responseAlertLogData = await getAlertLogData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        inStartDatetime,
        inEndDatetime
      );
      // エラーの場合は中断する
      if (responseAlertLogData === undefined) {
        // ローディング表示OFF
        this.isLoading = false;
        this.downloadErrorMessages = "データ取得に失敗しました。";
        this.downloadErrorDialog = true;
        return;
      }
      // データ件数・取得制限結果取得
      this.dataCount = 0;
      this.isTooManyResults = false;
      if (responseAlertLogData.records !== undefined) {
        this.dataCount = responseAlertLogData.records.length;
        this.isTooManyResults = responseAlertLogData.too_many_results;
      }
      // データ０件の場合はCSVデータは生成しない
      if (this.dataCount > 0) {
        // CSVヘッダー作成
        const csvHeader = [
          [
            "IMSI",
            "会社コード",
            "データ日時",
            "PLCアドレス名",
            "英字対象名",
            "日本語対象名",
            "PLCバリュー値",
            "状態",
          ],
        ];
        this.strCSVData = Papa.unparse(csvHeader, { quotes: true }) + "\r\n";
        // CSVデータ部生成、アペンド
        const csvConfig = {
          quotes: true,
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ",",
          header: false,
          newline: "\r\n",
          skipEmptyLines: false,
          columns: [
            "imsi",
            "company_id",
            "record_time_origin",
            "plc_address",
            "display_name_en",
            "display_name",
            "value",
            "display_name_for_value",
          ],
        };
        const alertLogData = responseAlertLogData.records;
        this.strCSVData += Papa.unparse(alertLogData, csvConfig);
      }
      // ローディング表示OFF
      this.isLoading = false;
      // ダウンロード確認ダイアログ表示
      this.downloadDialog = true;
    },
    async downloadOperationHistory(
      inStartDatetime: string | null,
      inEndDatetime: string | null
    ): Promise<void> {
      // パラメータ表示
      debugLog(inStartDatetime);
      debugLog(inEndDatetime);
      // ローディング表示ON
      this.isLoading = true;
      // プラントアラート履歴取得APIを呼び出す
      const responseOperationLogData = await getOperationLogData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        inStartDatetime,
        inEndDatetime
      );
      // エラーの場合は中断する
      if (responseOperationLogData === undefined) {
        // ローディング表示OFF
        this.isLoading = false;
        this.downloadErrorMessages = "データ取得に失敗しました。";
        this.downloadErrorDialog = true;
        return;
      }
      // データ件数・取得制限結果取得
      this.dataCount = 0;
      this.isTooManyResults = false;
      if (responseOperationLogData.records !== undefined) {
        this.dataCount = responseOperationLogData.records.length;
        this.isTooManyResults = responseOperationLogData.too_many_results;
      }
      // データ０件の場合はCSVデータは生成しない
      if (this.dataCount > 0) {
        // CSVヘッダー作成
        const csvHeader = [
          [
            "IMSI",
            "会社コード",
            "データ日時",
            "PLCアドレス名",
            "英字対象名",
            "日本語対象名",
            "PLCバリュー値",
            "状態",
          ],
        ];
        this.strCSVData = Papa.unparse(csvHeader, { quotes: true }) + "\r\n";
        // CSVデータ部生成、アペンド
        const csvConfig = {
          quotes: true,
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ",",
          header: false,
          newline: "\r\n",
          skipEmptyLines: false,
          columns: [
            "imsi",
            "company_id",
            "record_time_origin",
            "plc_address",
            "display_name_en",
            "display_name",
            "value",
            "display_name_for_value",
          ],
        };
        const alertLogData = responseOperationLogData.records;
        this.strCSVData += Papa.unparse(alertLogData, csvConfig);
      }
      // ローディング表示OFF
      this.isLoading = false;
      // ダウンロード確認ダイアログ表示
      this.downloadDialog = true;
    },
    // CSVファイル生成、ダウンロード実施処理
    downloadCsvData(): void {
      // ダウンロードするCSVファイルの名称を変更
      let csvFileName = "";
      switch (this.downloadType) {
        case "rawData":
          csvFileName = "raw_data";
          break;
        case "dailyReportData":
          csvFileName = "daily_report";
          break;
        case "operationHistory":
          csvFileName = "operation_history";
          break;
        case "alertHistory":
          csvFileName = "alert_history";
          break;
        case "additionData":
          csvFileName = "addition";
          break;
        default:
          csvFileName = "Result";
      }
      // csvファイルを生成しダウンロード
      const dateNow = dayjs().format("YYYYMMDDHHmmss");
      // 文字化け防止のためにBOMを追加
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, this.strCSVData], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${dateNow}_${csvFileName}.csv`;
      link.click();
      debugLog(`${dateNow}_${csvFileName}.csv`);
      this.downloadDialog = false;
    },
    // ダウンロードキャンセル処理
    downloadCsvDataCancel(): void {
      // 内部メモリ初期化
      this.strCSVData = "";
      this.dataCount = 0;
      this.isTooManyResults = false;
      this.downloadErrorMessages = "";
      // ダイアログCLOSE
      this.downloadDialog = false;
    },
    formatStartTime(startDatetime: { [key: string]: string }) {
      if (!startDatetime.date) {
        return null;
      }
      dayjs.extend(utc);
      const timeOffset: number = store.getters.companies_plant.time_offset;

      // 分単位の時刻オフセットから「+09:00」のような文字列を作成する。
      const timeOffsetString =
        (timeOffset > 0 ? "+" : "-") +
        String(Math.abs(timeOffset) / 60).padStart(2, "0") +
        ":" +
        String(Math.abs(timeOffset) % 60).padStart(2, "0");
      const formattedStartDateTime = dayjs(
        `${startDatetime.date}T${startDatetime.hour}:${startDatetime.minute}:00${timeOffsetString}`
      )
        .utcOffset(timeOffset)
        .format("YYYY-MM-DDTHH:mm:ssZ");
      return formattedStartDateTime;
    },
    formatEndDateTime(endDatetime: { [key: string]: string }) {
      if (!endDatetime.date) {
        return null;
      }
      dayjs.extend(utc);
      const timeOffset: number = store.getters.companies_plant.time_offset;
      // 分単位の時刻オフセットから「+09:00」のような文字列を作成する。
      const timeOffsetString =
        (timeOffset > 0 ? "+" : "-") +
        String(Math.abs(timeOffset) / 60).padStart(2, "0") +
        ":" +
        String(Math.abs(timeOffset) % 60).padStart(2, "0");
      const formattedEndDateTime = dayjs(
        `${endDatetime.date}T${endDatetime.hour}:${endDatetime.minute}:00${timeOffsetString}`
      )
        .utcOffset(timeOffset)
        .format("YYYY-MM-DDTHH:mm:ssZ");
      return formattedEndDateTime;
    },
    clearCondition(): void {
      this.downloadType = "rawData";
      this.startDatetime = {
        date: "",
        hour: "",
        minute: "",
      };
      this.endDatetime = {
        date: "",
        hour: "",
        minute: "",
      };
    },
    toggleColumnsAll(): void {
      this.$nextTick(() => {
        if (this.selectAllColumns) {
          this.selectedColumns = [];
        } else {
          this.selectedColumns = this.columnItems.slice();
        }
      });
    },
    toggleColumnsReportAll(): void {
      this.$nextTick(() => {
        if (this.selectReportAllColumns) {
          // 選択中項目から日報データの表示項目のみを削除
          this.selectedColumns = this.selectedColumns.filter(
            (i) => this.reportColumns.indexOf(i) == -1
          );
        } else {
          // 不足している日報データの表示項目を洗い出す。
          const addColumns = this.reportColumns.filter(
            (i) => this.selectedColumns.indexOf(i) == -1
          );
          // 選択中項目に不足していた日報データの表示項目を後ろに追加
          this.selectedColumns = this.selectedColumns.concat(addColumns);
        }
      });
    },
    toggleColumnsAdditionAll(): void {
      this.$nextTick(() => {
        if (this.selectAdditionAllColumns) {
          // 選択中項目から積算データの表示項目のみを削除
          this.selectedColumns = this.selectedColumns.filter(
            (i) => this.additionColumns.indexOf(i) == -1
          );
        } else {
          // 不足している日報データの表示項目を洗い出す。
          const addColumns = this.additionColumns.filter(
            (i) => this.selectedColumns.indexOf(i) == -1
          );
          // 選択中項目に不足していた日報データの表示項目を後ろに追加
          this.selectedColumns = this.selectedColumns.concat(addColumns);
        }
      });
    },
    updateStartCalendar(): void {
      this.startDateCalender = false;
      if (this.startDatetime.hour === "") {
        this.startDatetime.hour = "09";
      }
      if (this.startDatetime.minute === "") {
        if (
          this.downloadType === "dailyReportData" ||
          this.downloadType === "additionData"
        ) {
          // if: 日報データと積算データのみデフォルトの検索条件を9時00分~翌8時59分とする
          this.startDatetime.minute = "00";
        } else {
          this.startDatetime.minute = "01";
        }
      }
    },
    updateEndCalendar(): void {
      this.endDateCalender = false;
      if (this.endDatetime.hour === "") {
        if (
          this.downloadType === "dailyReportData" ||
          this.downloadType === "additionData"
        ) {
          // if: 日報データと積算データのみデフォルトの検索条件を9時00分~翌8時59分とする
          this.endDatetime.hour = "08";
        } else {
          this.endDatetime.hour = "09";
        }
      }
      if (this.endDatetime.minute === "") {
        if (
          this.downloadType === "dailyReportData" ||
          this.downloadType === "additionData"
        ) {
          // if: 日報データと積算データのみデフォルトの検索条件を9時00分~翌8時59分とする
          this.endDatetime.minute = "59";
        } else {
          this.endDatetime.minute = "00";
        }
      }
    },
    formatDatetime(strDateTime: string): string {
      // ローカル時刻表示をさせないため、ISO 8601フォーマットの日時文字列から
      // 「+09:00」のような時刻オフセット部分を削除してから、指定の日時文字列に変換して返す。
      let strChangeDateTime = "";
      if (strDateTime != null) {
        strChangeDateTime = strDateTime.substring(0, 19);
      }
      let resultStrDateTime = "";
      resultStrDateTime = dayjs(strChangeDateTime).format("YYYY-MM-DD HH:mm");
      return resultStrDateTime;
    },
    // 生データ用制御データ取得
    getRawDataControlList(): PlantControl[] {
      const rawDataPlantControlList: PlantControl[] = [];
      // Storeから制御データ取得
      const plant_control_list = store.getters.plant_control_list;
      // 生データ対象データを抽出する
      plant_control_list.forEach((plantControl: PlantControl) => {
        if (plantControl.raw_priority) {
          rawDataPlantControlList.push(plantControl);
        }
      });
      // 表示順に並び替える
      // TODO: マスタデータ取得していないため追加要件対応後に実施する
      rawDataPlantControlList.sort((a, b) => a.raw_priority - b.raw_priority);
      // 生成したデータを返す
      return rawDataPlantControlList;
    },
    // 日報データ用制御データ取得
    getReportControlList(): PlantControl[] {
      const reportTypePlantControlList: PlantControl[] = [];
      // Storeから制御データ取得
      const plant_control_list = store.getters.plant_control_list;
      // 初期化
      let max_report_priority = 0;
      this.reportColumns.splice(0);
      this.additionColumns.splice(0);
      // 日報対象データを抽出する
      plant_control_list.forEach((plantControl: PlantControl) => {
        if (plantControl.type_for_report != "unnecessary") {
          reportTypePlantControlList.push(plantControl);
          if (plantControl.report_priority > max_report_priority) {
            max_report_priority = plantControl.report_priority;
          }
        }
      });
      // 日報対象データに積算用("(日次)"を項目名の先頭に付与)を追加する
      // ・項目名英字は先頭に"daily_"を付与
      // ・日報データ用項目名は先頭に"(日次)"を付与
      // ・日報データ並び順は日報対象データの最大値の値に1足したものを加算
      plant_control_list.forEach((plantControl: PlantControl) => {
        if (plantControl.type_for_report != "unnecessary") {
          const daily = Object.assign({}, plantControl);
          daily.display_name_en = "daily_" + daily.display_name_en;
          daily.report_name = "(日次)" + daily.report_name;
          daily.report_priority += max_report_priority + 1;
          reportTypePlantControlList.push(daily);
        }
      });
      // 表示順に並び替える
      reportTypePlantControlList.sort(
        (a, b) => a.report_priority - b.report_priority
      );
      // 並び替えた項目を日報データと積算データの表示項目に分類して追加する
      reportTypePlantControlList.forEach((plantControl: PlantControl) => {
        if (plantControl.display_name_en.slice(0, 6) === "daily_") {
          this.additionColumns.push(plantControl.report_name);
        } else {
          this.reportColumns.push(plantControl.report_name);
        }
      });
      // 生成したデータを返す
      return reportTypePlantControlList;
    },
    // 運転履歴用制御データ取得
    getOperationControlList(): PlantControl[] {
      const operationTypePlantControlList: PlantControl[] = [];
      // Storeから制御データ取得
      const plant_control_list = store.getters.plant_control_list;
      // 日報対象データを抽出する
      plant_control_list.forEach((plantControl: PlantControl) => {
        if (plantControl.data_type == "operation") {
          // 要画面表示がtrueのみ対象とする
          if (plantControl.display_required == true) {
            operationTypePlantControlList.push(plantControl);
          }
        }
      });
      // 表示順に並び替える
      operationTypePlantControlList.sort(
        (a, b) => a.operation_priority - b.operation_priority
      );
      // 生成したデータを返す
      return operationTypePlantControlList;
    },
    // アラート履歴用制御データ取得
    getAlertControlList(): PlantControl[] {
      const alertTypePlantControlList: PlantControl[] = [];
      // Storeから制御データ取得
      const plant_control_list = store.getters.plant_control_list;
      // 日報対象データを抽出する
      plant_control_list.forEach((plantControl: PlantControl) => {
        if (plantControl.data_type == "alert") {
          // 要画面表示がtrueのみ対象とする
          if (plantControl.display_required == true) {
            alertTypePlantControlList.push(plantControl);
          }
        }
      });
      // 表示順に並び替える
      alertTypePlantControlList.sort(
        (a, b) => a.alert_priority - b.alert_priority
      );
      // 生成したデータを返す
      return alertTypePlantControlList;
    },
    // データ表示一覧のヘッダー部を変更（日報データのみ）
    changeTableHeaders(): void {
      this.headers = [
        {
          text: "日報日時",
          value: "record_time",
          width: "165px",
          divider: true,
        },
      ];
      const reportControlList = this.getReportControlList();
      this.selectedColumns.forEach((item) => {
        reportControlList.forEach((plantControl: PlantControl) => {
          if (plantControl.report_name == item) {
            let strText = plantControl.report_name;
            if (plantControl.display_name_en.slice(0, 6) === "daily_") {
              // "(日次)"と項目名英字の間に改行を入れる
              strText = strText.slice(0, 4) + "\r\n" + strText.slice(4);
            }
            if (plantControl.type_for_report != null) {
              strText = strText + "\r\n" + plantControl.type_for_report;
            }
            const hederObject = {
              text: strText,
              value: plantControl.display_name_en,
              width: "90px",
              align: "end",
              divider: true,
            };
            this.headers.push(hederObject);
          }
        });
      });
    },
    // データ表示一覧のヘッダー部を作成
    createTableHeaders(intDownloadType: string): void {
      if (
        intDownloadType === "dailyReportData" ||
        intDownloadType === "additionData"
      ) {
        this.columnItems = [];
        const dailyReportHeaders = [
          { text: "日報日時", value: "record_time", divider: true },
        ];
        const reportControlList = this.getReportControlList();
        reportControlList.forEach((plantControl: PlantControl) => {
          let strText = plantControl.report_name;
          this.columnItems.push(strText);
          if (plantControl.display_name_en.slice(0, 6) === "daily_") {
            // "(日次)"と項目名英字の間に改行を入れる
            strText = strText.slice(0, 4) + "\r\n" + strText.slice(4);
          }
          if (plantControl.type_for_report != null) {
            strText = strText + "\r\n" + plantControl.type_for_report;
          }
          const hederObject = {
            text: strText,
            value: plantControl.display_name_en,
            align: "end",
            divider: true,
          };
          dailyReportHeaders.push(hederObject);
        });
        // 表示項目選択のデフォルト選択
        if (intDownloadType === "dailyReportData") {
          this.selectedColumns = this.reportColumns.concat();
        } else {
          this.selectedColumns = this.additionColumns.concat();
        }
        this.headers = dailyReportHeaders;
        // 日報日時の昇順でソート表示
        this.sortItem = "record_time";
        this.sortDesc = false;
      } else if (intDownloadType === "operationHistory") {
        this.targetItems = [];
        this.headers = [
          { text: "データ日時", value: "record_time_origin", divider: true },
          { text: "対象", value: "display_name", divider: true },
          { text: "状態", value: "display_name_for_value" },
        ];
        const operationControlList = this.getOperationControlList();
        operationControlList.forEach((plantControl: PlantControl) => {
          const strText = plantControl.display_name_full;
          this.targetItems.push(strText);
        });
        // データ日時の降順でソート表示
        this.sortItem = "record_time_origin";
        this.sortDesc = true;
      } else {
        this.targetItems = [];
        this.headers = [
          { text: "データ日時", value: "record_time_origin", divider: true },
          { text: "対象", value: "display_name", divider: true },
          { text: "状態", value: "display_name_for_value" },
        ];
        const alertControlList = this.getAlertControlList();
        alertControlList.forEach((plantControl: PlantControl) => {
          const strText = plantControl.display_name_full;
          this.targetItems.push(strText);
        });
        // データ日時の降順でソート表示
        this.sortItem = "record_time_origin";
        this.sortDesc = true;
      }
    },
    // データ表示一覧のデータ部を作成
    createTableDatasets(intDownloadType: string): void {
      // テーブルのデータが変わるときはテーブルのフィルターをクリア
      this.filterTarget = null;
      // 日付形式変換
      const startTime = this.formatStartTime(this.startDatetime);
      const endTime = this.formatEndDateTime(this.endDatetime);
      // ダウンロードタイプごと一覧データを生成
      switch (intDownloadType) {
        case "dailyReportData":
          debugLog("日報データのデータ表示");
          this.dataViewDailyReportHistory(startTime, endTime);
          break;
        case "operationHistory":
          debugLog("運転履歴のデータ表示");
          this.dataViewOperationHistory(startTime, endTime);
          break;
        case "alertHistory":
          debugLog("アラート履歴のデータ表示");
          this.dataViewAlertHistory(startTime, endTime);
          break;
        case "additionData":
          debugLog("積算データのデータ表示");
          this.dataViewDailyReportHistory(startTime, endTime);
          break;
        default:
          debugLog("想定外エラー");
      }
    },
    // 日報データのデータ表示
    async dataViewDailyReportHistory(
      inStartDatetime: string | null,
      inEndDatetime: string | null
    ): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 一覧のデータをクリア
      this.datasets = [];
      // パラメータ表示
      debugLog("開始日時", inStartDatetime, "終了日時", inEndDatetime);
      //日報データ取得APIを呼び出す
      const responseDailyReportLogData = await getDailyReportLogData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        inStartDatetime,
        inEndDatetime
      );
      // エラーの場合は中断する
      if (responseDailyReportLogData === undefined) {
        // DATA VIEWパネルを閉じ、データダウンロード条件入力パネルを開く
        this.isDataViewPanelVisible = false;
        this.downloadPanelState = [0];
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "データ取得に失敗しました。";
        this.showErrorDialog = true;
        return;
      }
      // 取得結果をチェックして表示する
      if (responseDailyReportLogData.records !== undefined) {
        // 取得上限に達していた場合はエラーを表示（一覧表示の中断はしない）
        const too_many_results = responseDailyReportLogData.too_many_results;
        if (too_many_results != null && too_many_results === true) {
          this.errorDialogMessage =
            "データ件数・サイズの制限により全件取得出来ませんでした。<br />" +
            "必要であれば条件を見直してください。";
          this.showErrorDialog = true;
        }
        // 一覧にデータをセット
        this.datasets = responseDailyReportLogData.records;
      } else {
        // レスポンスがエラーでなくてもデータが0件の場合は中断する
        // ※0件の時はrecordsが空になるのではなくrecordsが欠落する点に注意

        // DATA VIEWパネルを閉じ、データダウンロード条件入力パネルを開く
        this.isDataViewPanelVisible = false;
        this.downloadPanelState = [0];
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "指定した範囲の対象データはありません。";
        this.showErrorDialog = true;
        return;
      }
      // ローディング表示OFF
      this.isLoading = false;
    },
    // 運転履歴のデータ表示
    async dataViewOperationHistory(
      inStartDatetime: string | null,
      inEndDatetime: string | null
    ): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 一覧のデータをクリア
      this.datasets = [];
      // パラメータ表示
      debugLog("開始日時", inStartDatetime, "終了日時", inEndDatetime);
      // プラントアラート履歴取得APIを呼び出す
      const responseOperationLogData = await getOperationLogData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        inStartDatetime,
        inEndDatetime
      );
      // エラーの場合は中断する
      if (responseOperationLogData === undefined) {
        // DATA VIEWパネルを閉じ、データダウンロード条件入力パネルを開く
        this.isDataViewPanelVisible = false;
        this.downloadPanelState = [0];
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "データ取得に失敗しました。";
        this.showErrorDialog = true;
        return;
      }
      // 取得結果をチェックして表示する
      if (responseOperationLogData.records !== undefined) {
        // 取得上限に達していた場合はエラーを表示（一覧表示の中断はしない）
        const too_many_results = responseOperationLogData.too_many_results;
        if (too_many_results != null && too_many_results === true) {
          this.errorDialogMessage =
            "データ件数・サイズの制限により全件取得出来ませんでした。<br />" +
            "必要であれば条件を見直してください。";
          this.showErrorDialog = true;
        }
        // 一覧にデータをセット
        this.datasets = responseOperationLogData.records;
      } else {
        // レスポンスがエラーでなくてもデータが0件の場合は中断する
        // ※0件の時はrecordsが空になるのではなくrecordsが欠落する点に注意

        // DATA VIEWパネルを閉じ、データダウンロード条件入力パネルを開く
        this.isDataViewPanelVisible = false;
        this.downloadPanelState = [0];
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "指定した範囲の対象データはありません。";
        this.showErrorDialog = true;
        return;
      }
      // ローディング表示OFF
      this.isLoading = false;
    },
    // アラート履歴のデータ表示
    async dataViewAlertHistory(
      inStartDatetime: string | null,
      inEndDatetime: string | null
    ): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 一覧のデータをクリア
      this.datasets = [];
      // パラメータ表示
      debugLog("開始日時", inStartDatetime, "終了日時", inEndDatetime);
      // プラントアラート履歴取得APIを呼び出す
      const responseAlertLogData = await getAlertLogData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        inStartDatetime,
        inEndDatetime
      );
      // エラーの場合は中断する
      if (responseAlertLogData === undefined) {
        // DATA VIEWパネルを閉じ、データダウンロード条件入力パネルを開く
        this.isDataViewPanelVisible = false;
        this.downloadPanelState = [0];
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "データ取得に失敗しました。";
        this.showErrorDialog = true;
        return;
      }
      // 取得結果をチェックして表示する
      if (responseAlertLogData.records !== undefined) {
        // 取得上限に達していた場合はエラーを表示（一覧表示の中断はしない）
        const too_many_results = responseAlertLogData.too_many_results;
        if (too_many_results != null && too_many_results === true) {
          this.errorDialogMessage =
            "データ件数・サイズの制限により全件取得出来ませんでした。<br />" +
            "必要であれば条件を見直してください。";
          this.showErrorDialog = true;
        }
        // 一覧にデータをセット
        this.datasets = responseAlertLogData.records;
      } else {
        // レスポンスがエラーでなくてもデータが0件の場合は中断する
        // ※0件の時はrecordsが空になるのではなくrecordsが欠落する点に注意

        // DATA VIEWパネルを閉じ、データダウンロード条件入力パネルを開く
        this.isDataViewPanelVisible = false;
        this.downloadPanelState = [0];
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "指定した範囲の対象データはありません。";
        this.showErrorDialog = true;
        return;
      }
      // ローディング表示OFF
      this.isLoading = false;
    },
    // エラーポップアップを閉じる
    resetErrorDialog(): void {
      this.errorDialogMessage = "";
      this.showErrorDialog = false;
    },
  },
});
