import {
  SummaryFixedFacilities,
  SummaryFixedSingleTank,
  SummaryFixedItem,
  SummaryFixedFlow,
} from "@/types";

// 【サマリー版画面表示用】設備データの固定部分のみをまとめたものを返す
export const getSummaryFixedFacilities = function () {
  return {
    wholePlant: {
      title: "設備全体",
      icon: "airPumpIconIcon",
      items: [
        {
          display_name_en: "operation_automatic_operation",
          text: "自動運転",
          icon: "mdi-factory",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_emergency_stop_output",
          text: "非常停止<br/>（クラウド）",
          icon: "mdi-factory",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_emergency_stop_input",
          text: "非常停止<br/>（プラント）",
          icon: "mdi-factory",
          default_data_type: "operation",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    crusher: {
      title: "破砕設備",
      icon: "airPumpIconIcon",
      items: [
        {
          display_name_en: "operation_disposer",
          text: "破砕機",
          icon: "mdi-compost",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_garbage_transfer_pump",
          text: "生ごみ移送ポンプ",
          icon: "mdi-water-pump",
          default_data_type: "operation",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    regulating: {
      title: "調整槽",
      icon: "adjustmentTankIcon",
      items: [
        {
          display_name_en: "data_regulating_tank_water_supply",
          text: "水位",
          icon: "mdi-hydraulic-oil-level",
          default_data_type: "sensor",
        },
        {
          display_name_en: "operation_digested_liquid_storage_tank_agitator",
          text: "撹拌機",
          icon: "mdi-crop-rotate",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_garbage_supply_pump",
          text: "生ごみ供給ポンプ",
          icon: "mdi-water-pump",
          default_data_type: "operation",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    fermentation: {
      title: "発酵槽",
      icon: "fermentationTankIcon",
      items: [
        {
          display_name_en: "data_fermentation_tank_temperature",
          text: "温度",
          icon: "mdi-thermometer-lines",
          default_data_type: "sensor",
        },
        {
          display_name_en: "data_fermentation_tank_potential_hydrogdata",
          text: "pH",
          icon: "mdi-hexagon-multiple-outline",
          default_data_type: "sensor",
        },
        {
          display_name_en: "data_gabage_flow_rate",
          text: "生ごみ流量",
          icon: "mdi-arrow-collapse-vertical",
          default_data_type: "sensor",
        },
        {
          display_name_en: "operation_fermenter_agitator_pump",
          text: "撹拌ポンプ",
          icon: "mdi-water-pump",
          default_data_type: "operation",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    hotWater: {
      title: "温水槽",
      icon: "hotWaterTankIcon",
      items: [
        {
          display_name_en: "data_hot_water_tank_temperature",
          text: "温度",
          icon: "mdi-thermometer-lines",
          default_data_type: "sensor",
        },
        {
          display_name_en: "operation_hot_water_tank_heater",
          text: "ヒーター",
          icon: "mdi-radiator",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_hot_water_circulation_pump_for_fermenter",
          text: "循環ポンプ<br/>（発酵槽用）",
          icon: "mdi-water-pump",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_hot_water_circulation_pump_for_generator",
          text: "循環ポンプ<br/>（発電機用）",
          icon: "mdi-water-pump",
          default_data_type: "operation",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    gasBalloon: {
      title: "ガスバルーン",
      icon: "gasPackIcon",
      items: [
        {
          display_name_en: "operation_gas_balloon_emergency_electric_valve",
          text: "非常用電動弁",
          icon: "mdi-pipe-valve",
          default_data_type: "operation",
        },
        {
          display_name_en:
            "operation_gas_balloon_emergency_electric_operating_conditions",
          text: "非常用電動弁<br/>動作状況",
          icon: "mdi-pipe-valve",
          default_data_type: "operation",
        },
        {
          display_name_en: "data_gas_balloon",
          text: "ガスバルーン高さ",
          icon: "mdi-waves-arrow-up",
          default_data_type: "sensor",
        },
        {
          display_name_en: "operation_deodorizing_blower",
          text: "脱臭ブロワ",
          icon: "mdi-weather-windy",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_main_gas_withdrawal_pump",
          text: "引抜ポンプ<br/>（メイン）",
          icon: "mdi-water-pump",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_bypass_gas_withdrawal_pump",
          text: "引抜ポンプ<br/>（パイパス）",
          icon: "mdi-water-pump",
          default_data_type: "operation",
        },
        {
          display_name_en: "data_gas_generation_flow_rate",
          text: "発生ガス流量",
          icon: "mdi-truck-cargo-container",
          default_data_type: "sensor",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    gasTank: {
      title: "ガスタンク",
      icon: "gasTankIcon",
      items: [
        {
          display_name_en: "operation_gas_tank_inlet_electric_valve",
          text: "入口電動弁",
          icon: "mdi-pipe-valve",
          default_data_type: "operation",
        },
        {
          display_name_en:
            "operation_gas_tank_inlet_electric_operating_conditions",
          text: "入口電動弁<br/>動作状況",
          icon: "mdi-pipe-valve",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_gas_tank_outlet_electric_valve",
          text: "出口電動弁",
          icon: "mdi-pipe-valve",
          default_data_type: "operation",
        },
        {
          display_name_en:
            "operation_gas_tank_outlet_electric_operating_conditions",
          text: "出口電動弁<br/>動作状況",
          icon: "mdi-pipe-valve",
          default_data_type: "operation",
        },
        {
          display_name_en: "data_gas_tank_pressure",
          text: "圧力",
          icon: "mdi-arrow-collapse-vertical",
          default_data_type: "sensor",
        },
        {
          display_name_en: "data_gas_supply_flow_rate",
          text: "供給ガス流量",
          icon: "mdi-truck-cargo-container",
          default_data_type: "sensor",
        },
      ] as Array<SummaryFixedItem>,
    } as SummaryFixedSingleTank,
    powerGenerator: {
      title: "発電機",
      icon: "weighingTankIcon",
      items: [
        {
          display_name_en: "data_generator_power_quantity_pulse",
          text: "発電機<br/>電力量パルス",
          icon: "mdi-generator-stationary",
          default_data_type: "sensor",
        },
        {
          display_name_en: "operation_power_generator",
          text: "発電機",
          icon: "mdi-generator-stationary",
          default_data_type: "operation",
        },
        {
          display_name_en: "operation_generator_methane_gas_demand",
          text: "スタンバイ",
          icon: "mdi-generator-stationary",
          default_data_type: "operation",
        },
      ],
    },
  } as SummaryFixedFacilities;
};

// 【サマリー版画面表示用】Flowデータの固定部分のみをまとめたものを返す
export const getSummaryFixedFlows = function () {
  return [
    {
      display_name_en: "data_raw_material_input",
      borderColor: "#a18674",
      arrowColor: "#815e46",
      backgroundColor: "#f0ebe8",
      arrowDirection: "down",
      icon: "mdi-trash-can-outline",
      iconColor: "#623618",
      message: "原料投入量",
    },
    {
      display_name_en: "data_gabage_flow_rate",
      borderColor: "#a18674",
      arrowColor: "#815e46",
      backgroundColor: "#f0ebe8",
      arrowDirection: "no",
      icon: "mdi-water-outline",
      iconColor: "#623618",
      message: "調整原料投入量",
    },
    {
      display_name_en: "data_gas_generation_flow_rate",
      borderColor: "#a18674",
      arrowColor: "#815e46",
      backgroundColor: "#f0ebe8",
      arrowDirection: "no",
      icon: "mdi-truck-cargo-container",
      iconColor: "#623618",
      message: "発生ガス流量",
    },
    {
      display_name_en: "data_gas_supply_flow_rate",
      borderColor: "#a18674",
      arrowColor: "#815e46",
      backgroundColor: "#f0ebe8",
      arrowDirection: "no",
      icon: "mdi-truck-cargo-container",
      iconColor: "#623618",
      message: "供給ガス流量",
    },
  ] as Array<SummaryFixedFlow>;
};
