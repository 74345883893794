






























































































































import Vue, { PropType } from "vue";
import dayjs from "dayjs";

interface DataTimestamp {
  date: string;
  hour: string;
  minute: string;
}
export default Vue.extend({
  name: "ChangeDataDialog",
  props: {
    showDialog: { type: Boolean, default: false },
    dataTimestamp: {
      type: Object as PropType<DataTimestamp>,
    },
    isAutoUpdate: { type: Boolean, default: true },
    isMobile: { type: Boolean, default: true },
  },
  data() {
    return {
      tempAutoUpdate: true,
      changeDataTime: {
        date: "",
        hour: "",
        minute: "",
      },
      changeDateCalender: false,
      hours: ["Dummy"],
      minutes: ["Dummy"],
    };
  },
  watch: {
    isShowDialog: {
      handler: function () {
        if (this.isShowDialog) {
          this.tempAutoUpdate = this.isAutoUpdate;
          Object.assign(this.changeDataTime, this.dataTimestamp);
        }
      },
    },
  },
  created() {
    // 時間の配列作成
    this.hours = [];
    for (let i = 0; i <= 23; i++) {
      this.hours.push(String(i).padStart(2, "0"));
    }
    // 分の配列作成
    this.minutes = [];
    for (let i = 0; i <= 59; i++) {
      this.minutes.push(String(i).padStart(2, "0"));
    }
  },
  computed: {
    isShowDialog(): boolean {
      return this.showDialog;
    },
    textDisplayTime(): string {
      return this.formatDateTime(
        this.dataTimestamp.date,
        this.dataTimestamp.hour,
        this.dataTimestamp.minute
      );
    },
    textChangeTime(): string {
      return this.formatDateTime(
        this.changeDataTime.date,
        this.changeDataTime.hour,
        this.changeDataTime.minute
      );
    },
  },
  methods: {
    formatDateTime(date: string, hour: string, minute: string): string {
      let resultString = "";
      resultString = date + " " + hour + ":" + minute;
      return resultString;
    },
    getTextAutoUpdateSwitch(): string {
      let resultText = "する";
      if (!this.tempAutoUpdate) {
        return "しない";
      }
      return resultText;
    },
    changeDataTimeAddMinute(addMinute: number) {
      const dataTimeString = this.formatDateTime(
        this.changeDataTime.date,
        this.changeDataTime.hour,
        this.changeDataTime.minute
      );
      const dataTime = dayjs(dataTimeString).add(addMinute, "m");
      this.changeDataTime.date = dayjs(dataTime).format("YYYY-MM-DD");
      this.changeDataTime.hour = dayjs(dataTime).format("HH");
      this.changeDataTime.minute = dayjs(dataTime).format("mm");
    },
    forward1minute(): void {
      this.changeDataTimeAddMinute(1);
    },
    rewind1minute(): void {
      this.changeDataTimeAddMinute(-1);
    },
    commitChangeDate(): void {
      this.$emit("response", {
        action: "commit",
        datetime: this.changeDataTime,
        update: this.tempAutoUpdate,
        message: "更新しました",
      });
    },
    cancel(): void {
      this.$emit("response", {
        action: "cancel",
        datetime: this.dataTimestamp,
        update: this.isAutoUpdate,
        message: "キャンセルしました",
      });
    },
  },
});
