








import Vue from "vue";
import { bus } from "./main";
export default Vue.extend({
  name: "app",
  data() {
    return {
      backgroundImageClass: ["backgroundImage"],
    };
  },
  created() {
    bus.$on("background", (data: string) => {
      if (data === "noImage") {
        this.backgroundImageClass = [""];
      } else if (data === "image1") {
        this.backgroundImageClass = ["backgroundImage"];
      } else if (data === "image2") {
        this.backgroundImageClass = ["summaryBackground"];
      }
    });
  },
  methods: {},
  beforeDestroy() {
    bus.$off("background");
  },
});
