var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"460px"},model:{value:(_vm.isShowDialog),callback:function ($$v) {_vm.isShowDialog=$$v},expression:"isShowDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" データ表示日時変更 ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_vm._v(" 現在表示日時： "+_vm._s(_vm.textDisplayTime)+" ")])],1),_c('v-row',[_c('v-switch',{staticClass:"ml-4",attrs:{"inset":"","label":("自動更新: " + (_vm.getTextAutoUpdateSwitch())),"color":"red"},model:{value:(_vm.tempAutoUpdate),callback:function ($$v) {_vm.tempAutoUpdate=$$v},expression:"tempAutoUpdate"}})],1),(!_vm.tempAutoUpdate)?_c('v-row',[_c('v-col',[_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":!_vm.isMobile},on:{"click":function($event){return _vm.rewind1minute()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-rewind")])],1)]}}],null,false,3682083945)},[_c('span',[_vm._v("時間を１分戻す")])]),_c('v-btn',{attrs:{"text":""}},[(_vm.isMobile)?_c('span',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.textChangeTime))]):_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.textChangeTime))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":!_vm.isMobile},on:{"click":function($event){return _vm.forward1minute()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-fast-forward")])],1)]}}],null,false,2785430788)},[_c('span',[_vm._v("時間を１分進める")])])],1)])],1):_vm._e(),(!_vm.tempAutoUpdate)?_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"id":"endDateInput","label":"表示日付","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.changeDataTime.date),callback:function ($$v) {_vm.$set(_vm.changeDataTime, "date", $$v)},expression:"changeDataTime.date"}},'v-text-field',attrs,false),on))]}}],null,false,2098064130),model:{value:(_vm.changeDateCalender),callback:function ($$v) {_vm.changeDateCalender=$$v},expression:"changeDateCalender"}},[_c('v-date-picker',{attrs:{"elevation":"15"},on:{"input":function($event){_vm.changeDateCalender = false}},model:{value:(_vm.changeDataTime.date),callback:function ($$v) {_vm.$set(_vm.changeDataTime, "date", $$v)},expression:"changeDataTime.date"}})],1),_c('v-select',{attrs:{"items":_vm.hours,"label":"時","prepend-icon":"mdi-clock-outline","min-width":"30px"},model:{value:(_vm.changeDataTime.hour),callback:function ($$v) {_vm.$set(_vm.changeDataTime, "hour", $$v)},expression:"changeDataTime.hour"}}),_c('v-select',{attrs:{"items":_vm.minutes,"label":"分"},model:{value:(_vm.changeDataTime.minute),callback:function ($$v) {_vm.$set(_vm.changeDataTime, "minute", $$v)},expression:"changeDataTime.minute"}})],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold mb-5",attrs:{"color":"primary","elevation":"5","outlined":"","large":!_vm.isMobile},on:{"click":function($event){return _vm.commitChangeDate()}}},[_vm._v(" 確定 ")]),_c('v-btn',{staticClass:"font-weight-bold mb-5",attrs:{"color":"error","elevation":"5","outlined":"","large":!_vm.isMobile},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" キャンセル ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }