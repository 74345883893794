var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list',{staticClass:"pb-0",staticStyle:{"background-color":"transparent"},attrs:{"subheader":"","dense":""}},[_vm._l((_vm.dataList),function(item,i){return [_c('v-divider',{key:i}),_c('div',{key:item.text,class:[
          !item.onoff ? 'custom-list__background-color' : '',
          i == _vm.dataList.length - 1 ? 'custom-list__last-list-item' : '' ]},[_c('v-list-item',{key:item.text,staticClass:"custom-list__list-height d-flex justify-center",class:[
            !item.onoff ? 'custom-list__list-background ' : '',
            i == _vm.dataList.length - 1 ? 'custom-list__last-list-item' : '' ]},[_c('v-list-item-icon',{staticClass:"mr-2 d-flex justify-center items-center align-center",attrs:{"id":"listIcon"}},[_c('v-icon',{attrs:{"color":_vm.getIconColor(item)},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('p',{staticClass:"font-small font-noto ma-0",staticStyle:{"word-break":"break-word"}},[_c('span',{domProps:{"innerHTML":_vm._s(item.text)}})])]),(item.onoff)?_c('v-list-item-content',{staticClass:"align-end"},[(item.value)?_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[_c('v-icon',{attrs:{"left":"","color":item.colorOn},domProps:{"textContent":_vm._s(item.iconOn)}}),_c('span',{staticClass:"font-weight-bold",style:({ color: item.colorOn })},[_vm._v(_vm._s(item.textOn))]),_c('v-spacer'),(
                  _vm.controlAuthority === true &&
                  item.isControllable &&
                  _vm.isAutoUpdate === true
                )?_c('v-btn',{staticClass:"mt-n2 mb-n2",attrs:{"fab":"","small":"","color":"#55aaFF","outlined":"","disabled":_vm.isManualControlDisabled(item)},on:{"click":function($event){return _vm.openManualControlDialog(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-remote ")])],1):_vm._e()],1):_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[_c('v-icon',{attrs:{"left":"","color":item.colorOff},domProps:{"textContent":_vm._s(item.iconOff)}}),_c('span',{staticClass:"font-weight-bold",style:({ color: item.colorOff })},[_vm._v(_vm._s(item.textOff))]),_c('v-spacer'),(
                  _vm.controlAuthority === true &&
                  item.isControllable &&
                  _vm.isAutoUpdate === true
                )?_c('v-btn',{staticClass:"mt-n2 mb-n2",attrs:{"fab":"","small":"","color":"#55aaFF","outlined":"","disabled":_vm.isManualControlDisabled(item)},on:{"click":function($event){return _vm.openManualControlDialog(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-remote ")])],1):_vm._e()],1)]):_c('v-list-item-content',{staticClass:"align-end text-body-2 relative"},[_c('div',{staticClass:"d-flex justify-start"},[_c('p',{staticClass:"ma-0 font-h6 white text-right pa-1 font-lato font-weight-bold custom-list__digit-limit"},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('p',{staticClass:"ma-0 custom-list__unit"},[_vm._v(" "+_vm._s(item.unit)+" ")])])])],1)],1),(i === _vm.dataList.length - 2)?_c('v-divider',{key:("under-" + i)}):_vm._e()]})],2),(_vm.showManualControlDialog)?_c('ManualControlDialog',{attrs:{"showDialog":_vm.showManualControlDialog,"isMobile":_vm.isMobile,"data":_vm.detailItem,"controlData":_vm.manualControlData},on:{"closeDialog":_vm.closeManualControlDialog}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"520px","persistent":""},model:{value:(_vm.showErrorDialog),callback:function ($$v) {_vm.showErrorDialog=$$v},expression:"showErrorDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.errorDialogMessage)}})]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.resetErrorDialog}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }