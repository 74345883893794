




























































































































































































































import { debugLog } from "@/lib/debug_util";
import Vue from "vue";
import { bus } from "../main";
import dayjs from "dayjs";
import store from "@/store";
import { DisplayDataTime } from "@/types";
import WebApiModel from "@/apis/web_api";
import { getPlantsRemoteControlsData } from "@/lib/web_api_util";
export default Vue.extend({
  name: "MobileRemoteLog",
  data() {
    return {
      // 開始日付カレンダー表示制御
      startDateCalender: false,
      // 開始日時格納
      startDatetime: {
        date: "",
        hour: "",
        minute: "",
      },
      // 終了日付カレンダー表示制御
      endDateCalender: false,
      // 終了日時格納
      endDatetime: {
        date: "",
        hour: "",
        minute: "",
      },
      // 時項目コンボボックス値
      hours: ["Dummy"],
      // 分項目コンボボックス値
      minutes: ["Dummy"],
      // WebAPI用変数
      webApiModel: {} as WebApiModel,
      // 条件設定エラーダイアログ表示制御
      dataViewErrorDialog: false,
      // 条件設定エラーメッセージ
      dataViewErrorMessages: "",
      // ローディング表示制御
      isLoading: false,
      // データ表示ダイアログ表示制御
      dataListDialog: false,
      // データ表示の対象絞り込み選択用
      filterTarget: null,
      // データ表示の一覧ヘッダー
      headers: [{}],
      // データ表示の一覧データ
      datasets: [{}],
      // データ表示の一覧ソートアイテム
      sortItem: "triggered_time",
      // データ表示の一覧ソート順
      sortDesc: false,
      // エラーダイアログ表示制御
      showErrorDialog: false,
      // エラーダイアログメッセージ
      errorDialogMessage: "",
    };
  },
  created() {
    // 背景イメージなし
    bus.$emit("background", "noImage");
    // 時間の配列作成
    this.hours = [];
    for (let i = 0; i <= 23; i++) {
      this.hours.push(String(i).padStart(2, "0"));
    }
    // 分の配列作成
    this.minutes = [];
    for (let i = 0; i <= 59; i++) {
      this.minutes.push(String(i).padStart(2, "0"));
    }
    // 開始日付に現在日時の前日を設定
    const now = dayjs();
    this.startDatetime.date = now.subtract(1, "day").format("YYYY-MM-DD");
    this.startDatetime.hour = "09";
    this.startDatetime.minute = "01";
    // 終了日付に現在日時を設定
    this.endDatetime.date = now.format("YYYY-MM-DD");
    this.endDatetime.hour = now.format("HH");
    this.endDatetime.minute = now.format("mm");
    // APIアクセス用ライブラリを作成
    this.webApiModel = new WebApiModel();
  },
  computed: {
    dataTypeTargetName(): string {
      return "稼働ログ";
    },
    rangeDataTime(): string {
      let strRange = "";
      if (this.startDatetime.date !== "") {
        strRange =
          strRange +
          this.startDatetime.date +
          " " +
          this.startDatetime.hour +
          ":" +
          this.startDatetime.minute;
      }
      strRange = strRange + " ~ ";
      if (this.endDatetime.date !== "") {
        strRange =
          strRange +
          this.endDatetime.date +
          " " +
          this.endDatetime.hour +
          ":" +
          this.endDatetime.minute;
      }
      return strRange;
    },
  },
  methods: {
    // DATA VIEWボタンを押下
    openDataView(): void {
      this.dataViewErrorMessages = "";
      // 入力値チェック
      if (!this.validateInput(this.startDatetime, this.endDatetime)) {
        this.dataViewErrorDialog = true;
        return;
      }
      this.createTableHeaders();
      this.createTableDatasets();
      if (this.showErrorDialog === true) {
        // WebAPIアクセスでエラー発生時等
        return;
      }
      this.dataListDialog = true;
    },
    // 入力チェック（全種別共通）
    validateInput(
      inStartTime: DisplayDataTime,
      inEndTime: DisplayDataTime
    ): boolean {
      // Validate結果
      let validateResult = true;
      // 開始日時：いずれかに入力がある場合、日時分のすべて入力されていること
      if (
        inStartTime.date !== "" ||
        inStartTime.hour !== "" ||
        inStartTime.minute !== ""
      ) {
        if (
          inStartTime.date === "" ||
          inStartTime.hour === "" ||
          inStartTime.minute === ""
        ) {
          this.dataViewErrorMessages +=
            "開始日時を指定する場合は年月日時刻をすべて入力してください。<br />";
          validateResult = false;
        }
      }
      // 終了日時：いずれかに入力がある場合、日時分のすべて入力されていること
      if (
        inEndTime.date !== "" ||
        inEndTime.hour !== "" ||
        inEndTime.minute !== ""
      ) {
        if (
          inEndTime.date === "" ||
          inEndTime.hour === "" ||
          inEndTime.minute === ""
        ) {
          this.dataViewErrorMessages +=
            "終了日時を指定する場合は年月日時刻をすべて入力してください。<br />";
          validateResult = false;
        }
      }
      // 日付相関チェック
      if (validateResult) {
        // 日付形式変換
        const startTime = this.formatStartDateTime(this.startDatetime);
        const endTime = this.formatEndDateTime(this.endDatetime);
        // 開始日時、終了日時がともに未入力の場合はエラー
        if (startTime === null && endTime === null) {
          this.dataViewErrorMessages +=
            "開始日時・終了日時のいずれかは必ず指定してください。<br />";
          validateResult = false;
        }
        // 開始日時が終了日時よりも後の場合はエラー（同じ日時もエラー）
        if (startTime !== null && endTime !== null) {
          if (startTime >= endTime) {
            this.dataViewErrorMessages +=
              "開始日時に終了日時より前の日時は入力できません。<br />";
            validateResult = false;
          }
        }
      }
      return validateResult;
    },
    // 日時をプラントの時刻オフセットの時間に変換
    changeDateTimeWithPlantTimeOffset(inTime: null | string) {
      // プラントの時刻オフセットから「+09:00」のような文字列を作成し、
      // それを日時のオフセットとして返す。
      const timeOffset: number = store.getters.companies_plant.time_offset;
      const timeOffsetString =
        (timeOffset >= 0 ? "+" : "-") +
        String(Math.abs(timeOffset) / 60).padStart(2, "0") +
        ":" +
        String(Math.abs(timeOffset) % 60).padStart(2, "0");
      let changedTime: null | string = null;
      if (inTime) {
        changedTime =
          dayjs(inTime).format("YYYY-MM-DDTHH:mm:ss") + timeOffsetString;
      }
      return changedTime;
    },
    formatStartDateTime(startDatetime: { [key: string]: string }) {
      if (!startDatetime.date) {
        return null;
      }
      const formattedStartDateTime = dayjs(
        `${startDatetime.date}T${startDatetime.hour}:${startDatetime.minute}:00`
      ).format();
      const changedDateTime = this.changeDateTimeWithPlantTimeOffset(
        formattedStartDateTime
      );
      return changedDateTime;
    },
    formatEndDateTime(endDatetime: { [key: string]: string }) {
      if (!endDatetime.date) {
        return null;
      }
      const formattedEndDateTime = dayjs(
        `${endDatetime.date}T${endDatetime.hour}:${endDatetime.minute}:59`
      ).format();
      const changedDateTime =
        this.changeDateTimeWithPlantTimeOffset(formattedEndDateTime);
      return changedDateTime;
    },
    clearCondition(): void {
      this.startDatetime = {
        date: "",
        hour: "",
        minute: "",
      };
      this.endDatetime = {
        date: "",
        hour: "",
        minute: "",
      };
    },
    updateStartCalendar(): void {
      this.startDateCalender = false;
      if (this.startDatetime.hour === "") {
        this.startDatetime.hour = "09";
      }
      if (this.startDatetime.minute === "") {
        this.startDatetime.minute = "01";
      }
    },
    updateEndCalendar(): void {
      this.endDateCalender = false;
      if (this.endDatetime.hour === "") {
        this.endDatetime.hour = "09";
      }
      if (this.endDatetime.minute === "") {
        this.endDatetime.minute = "00";
      }
    },
    /**
     * ローカル日時に変更
     * 「2024-05-28T04:00:00Z」の形式をローカル日時形式「2024-05-28T13:00:00」に変更する
     * @param strDateTime 日時文字列
     * @returns 変更したローカル日時の日時文字列
     */
    changeLocalDatetime(strDateTime: string): string {
      if (strDateTime == null) {
        return "";
      }
      return dayjs(strDateTime).format("MM-DD HH:mm:ss");
    },
    // データ表示一覧のヘッダー部を作成
    createTableHeaders(): void {
      this.headers = [
        {
          text: "操作日時",
          value: "triggered_time",
          divider: true,
          width: "100px",
        },
        { text: "操作者", value: "email", divider: true, width: "10px" },
        {
          text: "設備名",
          value: "facility_name_main",
          divider: true,
          width: "100px",
        },
        {
          text: "制御対象",
          value: "display_name",
          divider: true,
          width: "100px",
        },
        { text: "指示内容", value: "switch_to", divider: true, width: "100px" },
        {
          text: "強制実行",
          value: "force_execution",
          divider: true,
          width: "100px",
        },
        {
          text: "操作時コメント",
          value: "triggered_for",
          divider: true,
          width: "140px",
        },
        {
          text: "制御完了日時",
          value: "input_data_check_time",
          divider: true,
          width: "125px",
        },
        {
          text: "実行結果",
          value: "overall_result",
          divider: true,
          width: "100px",
        },
        {
          text: "実行結果詳細",
          value: "overall_result_detail",
          divider: true,
          width: "200px",
        },
        {
          text: "処理前関連データ状況",
          value: "interlock_check_data_text",
          divider: true,
          width: "280px",
        },
        {
          text: "処理後関連データ状況",
          value: "input_check_data_text",
          divider: true,
          width: "280px",
        },
      ];
      // 操作日時の降順でソート表示
      this.sortItem = "triggered_time";
      this.sortDesc = true;
    },
    // データ表示一覧のデータ部を作成
    createTableDatasets(): void {
      // テーブルのデータが変わるときはテーブルのフィルターをクリア
      this.filterTarget = null;
      // 日付形式変換
      const startTime = this.formatStartDateTime(this.startDatetime);
      const endTime = this.formatEndDateTime(this.endDatetime);
      debugLog("稼働ログのデータ表示");
      this.dataViewRemoteControlsData(startTime, endTime);
    },
    // 稼働ログのデータ表示
    async dataViewRemoteControlsData(
      inStartDatetime: string | null,
      inEndDatetime: string | null
    ): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 一覧のデータをクリア
      this.datasets = [];
      // パラメータ表示
      debugLog("開始日時", inStartDatetime, "終了日時", inEndDatetime);
      // 稼働ログ取得APIを呼び出す
      const response = await getPlantsRemoteControlsData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        inStartDatetime,
        inEndDatetime
      );
      // エラーの場合は中断する
      if (response === undefined) {
        // DATA VIEWダイアログを閉じる
        this.dataListDialog = false;
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "データ取得に失敗しました。";
        this.showErrorDialog = true;
        return;
      }
      // 取得結果をチェックして表示する
      if (response.records !== undefined) {
        // 取得上限に達していた場合はエラーを表示（一覧表示の中断はしない）
        const too_many_results = response.too_many_results;
        if (too_many_results != null && too_many_results === true) {
          this.errorDialogMessage =
            "データ件数・サイズの制限により全件取得出来ませんでした。<br />" +
            "必要であれば条件を見直してください。";
          this.showErrorDialog = true;
        }
        // 一覧にデータをセット
        this.datasets = response.records;
      } else {
        // レスポンスがエラーでなくてもデータが0件の場合は中断する
        // ※0件の時はrecordsが空になるのではなくrecordsが欠落する点に注意

        // DATA VIEWダイアログを閉じる
        this.dataListDialog = false;
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "指定した範囲の対象データはありません。";
        this.showErrorDialog = true;
        return;
      }
      // ローディング表示OFF
      this.isLoading = false;
    },
    // エラーポップアップを閉じる
    resetErrorDialog(): void {
      this.errorDialogMessage = "";
      this.showErrorDialog = false;
    },
  },
});
