import { API } from "aws-amplify";

export default class ApiModel {
  private apiName: string;

  constructor() {
    this.apiName = "biogas-plant-remote-monitoring-api";
  }

  // Get method
  public async get(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    init: { [k: string]: any } = {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    return API.get(this.apiName, path, init);
  }

  // Post method
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async post(path: string, init: { [k: string]: any }): Promise<void> {
    return await API.post(this.apiName, path, init);
  }

  // Put method
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async put(path: string, init: { [k: string]: any }): Promise<void> {
    return await API.put(this.apiName, path, init);
  }

  // Delete method
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async del(path: string, init: { [k: string]: any }): Promise<void> {
    return await API.del(this.apiName, path, init);
  }
}
