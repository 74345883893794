







import Vue from "vue";
import Header from "@/components/MobileHeader.vue";

export default Vue.extend({
  name: "MobileDashBoard",
  components: {
    Header,
  },
});
