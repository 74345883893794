
















































































import Vue, { PropType } from "vue";
import { AlertRecommend } from "@/types";
// import { debugLog } from "@/lib/debug_util";

export default Vue.extend({
  name: "AlertRecommendDialog",
  props: {
    showDialog: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
    alert: { type: String, default: "" },
    recommend: {
      type: Object as PropType<AlertRecommend>,
    },
  },
  data() {
    return {
      // アラート名称
      alertMessage: "",
      // アラートレコメンド
      alertRecommendData: {} as AlertRecommend,
      readonly: true,
    };
  },
  watch: {
    isShowDialog: {
      handler: function () {
        if (this.isShowDialog) {
          // propsで受け取った内容を画面に表示
          this.alertMessage = this.alert;
          this.alertRecommendData = Object.assign({}, this.recommend);
          // 改行コードのエスケープを除去
          this.alertRecommendData.cause_of_occurrence =
            this.recommend.cause_of_occurrence.replace(/\\n/g, "\n");
          this.alertRecommendData.action_required =
            this.recommend.action_required.replace(/\\n/g, "\n");
        }
      },
    },
  },
  computed: {
    isShowDialog(): boolean {
      return this.showDialog;
    },
  },
  methods: {
    closeDialog(): void {
      this.$emit("response");
    },
  },
});
