

















import Vue from "vue";
export default Vue.extend({
  name: "header-view",
  data() {
    return {
      appName: "おまかせバイオガスプラント",
      headerColor: "transparent",
    };
  },
});
