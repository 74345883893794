


































import Vue from "vue";
export default Vue.extend({
  name: "flow",
  props: {
    borderColor: { type: String, default: "#a18674" },
    arrowColor: { type: String, default: "#815e46" },
    backgroundColor: { type: String, default: "#f0ebe8" },
    arrowDirection: { type: String, default: "right" },
    icon: { type: String, default: "mdi-trash-can-outline" },
    iconColor: { type: String, default: "#a18674" },
    unit: { type: String, default: "㎥" },
    message: { type: String, default: "原料投入料" },
    displayData: { type: [Number, String], default: "" },
  },
  computed: {
    alertHeight() {
      return { height: window.innerHeight - 76 };
    },
    navigationWidth() {
      if (
        window.matchMedia("(min-width: 960px) and (max-width: 1264px)").matches
      ) {
        return 260;
      } else {
        return 300;
      }
    },
  },
  mounted() {
    const targetElement = this.$refs["round-arrow"] as HTMLElement;
    if (targetElement) {
      targetElement.style.setProperty("--arrowColor", this.arrowColor);
    }

    if (this.arrowDirection === "right") {
      targetElement.style.setProperty("--arrowWidth", "200px");
    }
  },
});
