




























































































































































































































































































import Vue from "vue";
import { bus } from "../main";
import dayjs from "dayjs";
// utcは呼び元のVueのcreatedで参照している。
// このソースファイルではutcOffsetメソッドで不当にエラー指摘されないようimportしている。
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import utc from "dayjs/plugin/utc";
import store from "@/store";
import { DisplayDataTime } from "@/types";
import Papa from "papaparse";
import WebApiModel from "@/apis/web_api";
import { getPlantsRemoteControlsData } from "@/lib/web_api_util";
import { debugLog } from "@/lib/debug_util";
export default Vue.extend({
  name: "Mobile",
  data() {
    return {
      // 対象データ名
      downloadTargetName: "稼働ログ",
      // 開始日付カレンダー表示制御
      startDateCalender: false,
      // 開始日時格納
      startDatetime: {
        date: "",
        hour: "",
        minute: "",
      },
      // 終了日付カレンダー表示制御
      endDateCalender: false,
      // 終了日時格納
      endDatetime: {
        date: "",
        hour: "",
        minute: "",
      },
      // 時項目コンボボックス値
      hours: ["Dummy"],
      // 分項目コンボボックス値
      minutes: ["Dummy"],
      // WebAPI用変数
      webApiModel: {} as WebApiModel,
      // ダウンロードダイアログ表示制御
      downloadDialog: false,
      // ダウンロードデータ件数
      dataCount: 0,
      // CSV文字列(ヘッダー付き)
      strCSVData: "",
      // 取得制限件数オーバーフラグ
      isTooManyResults: false,
      // 条件設定エラーダイアログ表示制御
      downloadErrorDialog: false,
      // 条件設定エラータイプ
      downloadErrorType: "",
      // 条件設定エラーメッセージ
      downloadErrorMessages: "",
      // ローディング表示制御
      isLoading: false,
      // 表示パネル制御
      downloadPanelState: [0],
      // ダウンロードデータパネル表示制御
      isDataViewPanelVisible: false,
      // データ表示の対象絞り込み選択用
      filterTarget: null,
      // データ表示の一覧ヘッダー
      headers: [{}],
      // データ表示の一覧データ
      datasets: [{}],
      // データ表示の一覧ソートアイテム
      sortItem: "triggered_time",
      // データ表示の一覧ソート順
      sortDesc: false,
      // エラーダイアログ表示制御
      showErrorDialog: false,
      // エラーダイアログメッセージ
      errorDialogMessage: "",
    };
  },
  created() {
    // 背景イメージなし
    bus.$emit("background", "noImage");
    // 時間の配列作成
    this.hours = [];
    for (let i = 0; i <= 23; i++) {
      this.hours.push(String(i).padStart(2, "0"));
    }
    // 分の配列作成
    this.minutes = [];
    for (let i = 0; i <= 59; i++) {
      this.minutes.push(String(i).padStart(2, "0"));
    }
    // 開始日付に現在日時の前日を設定
    const now = dayjs();
    this.startDatetime.date = now.subtract(1, "day").format("YYYY-MM-DD");
    this.startDatetime.hour = "09";
    this.startDatetime.minute = "01";
    // 終了日付に現在日時を設定
    this.endDatetime.date = now.format("YYYY-MM-DD");
    this.endDatetime.hour = now.format("HH");
    this.endDatetime.minute = now.format("mm");
    // APIアクセス用ライブラリを作成
    this.webApiModel = new WebApiModel();
  },
  computed: {
    /**
     * 開始日時と終了日時の範囲を文字列で返す
     * @returns {string} 開始日時と終了日時の範囲
     */
    rangeDataTime(): string {
      let strRange = "";
      if (this.startDatetime.date !== "") {
        strRange =
          strRange +
          this.startDatetime.date +
          " " +
          this.startDatetime.hour +
          ":" +
          this.startDatetime.minute;
      }
      strRange = strRange + " ~ ";
      if (this.endDatetime.date !== "") {
        strRange =
          strRange +
          this.endDatetime.date +
          " " +
          this.endDatetime.hour +
          ":" +
          this.endDatetime.minute;
      }
      return strRange;
    },
  },
  watch: {
    /**
     * 開始日時の変更監視
     * 開始日時が変更された場合、データ表示パネルを非表示にする
     */
    startDatetime: {
      handler: function () {
        this.isDataViewPanelVisible = false;
      },
      deep: true,
    },
    /**
     * 終了日時の変更監視
     * 終了日時が変更された場合、データ表示パネルを非表示にする
     */
    endDatetime: {
      handler: function () {
        this.isDataViewPanelVisible = false;
      },
      deep: true,
    },
  },
  methods: {
    /**
     * データ表示項目の作成
     * @param なし
     * @returns なし
     */
    openDataView(): void {
      this.downloadErrorType = "DataView";
      this.downloadErrorMessages = "";
      // 入力値チェック
      if (!this.validateInput(this.startDatetime, this.endDatetime)) {
        this.downloadErrorDialog = true;
        return;
      }
      // 一覧のヘッダーとデータを表示
      this.createTableHeaders();
      this.createTableDatasets();
      if (this.showErrorDialog === true) {
        // WebAPIアクセスでエラー発生時等
        return;
      }
      // 日報データまたは積算データであれば表示項目選択を、それ以外は対象絞り込みを表示
      this.isDataViewPanelVisible = true;
      this.downloadPanelState = [1];
    },
    /**
     * ダウンロード用のCSVデータを生成
     * @param なし
     * @returns なし
     */
    openDownloadCsvData(): void {
      // CSVデータ格納領域初期化
      this.strCSVData = "";
      this.dataCount = 0;
      this.isTooManyResults = false;
      this.downloadErrorType = "Download";
      this.downloadErrorMessages = "";
      // 入力値チェック
      if (!this.validateInput(this.startDatetime, this.endDatetime)) {
        this.downloadErrorDialog = true;
        return;
      }
      // 日付形式変換
      const startTime = this.formatStartTime(this.startDatetime);
      const endTime = this.formatEndDateTime(this.endDatetime);
      // CSVデータ生成
      this.downloadRemoteControlLog(startTime, endTime);
      debugLog("稼働ログダウンロード");
    },
    /**
     * 入力チェック（全種別共通）
     * @param inStartTime 開始日時
     * @param inEndTime 終了日時
     * @returns 入力チェック結果
     */
    validateInput(
      inStartTime: DisplayDataTime,
      inEndTime: DisplayDataTime
    ): boolean {
      // Validate結果
      let validateResult = true;
      // 開始日時：いずれかに入力がある場合、日時分のすべて入力されていること
      if (
        inStartTime.date !== "" ||
        inStartTime.hour !== "" ||
        inStartTime.minute !== ""
      ) {
        if (
          inStartTime.date === "" ||
          inStartTime.hour === "" ||
          inStartTime.minute === ""
        ) {
          this.downloadErrorMessages +=
            "開始日時を指定する場合は年月日時刻をすべて入力してください。<br />";
          validateResult = false;
        }
      }
      // 終了日時：いずれかに入力がある場合、日時分のすべて入力されていること
      if (
        inEndTime.date !== "" ||
        inEndTime.hour !== "" ||
        inEndTime.minute !== ""
      ) {
        if (
          inEndTime.date === "" ||
          inEndTime.hour === "" ||
          inEndTime.minute === ""
        ) {
          this.downloadErrorMessages +=
            "終了日時を指定する場合は年月日時刻をすべて入力してください。<br />";
          validateResult = false;
        }
      }
      // 日付相関チェック
      if (validateResult) {
        // 日付形式変換
        const startTime = this.formatStartTime(this.startDatetime);
        const endTime = this.formatEndDateTime(this.endDatetime);
        // 開始日時、終了日時がともに未入力の場合はエラー
        if (startTime === null && endTime === null) {
          this.downloadErrorMessages +=
            "開始日時・終了日時のいずれかは必ず指定してください。<br />";
          validateResult = false;
        }
        // 開始日時が終了日時よりも後の場合はエラー（同じ日時もエラー）
        if (startTime !== null && endTime !== null) {
          if (startTime >= endTime) {
            this.downloadErrorMessages +=
              "開始日時に終了日時より前の日時は入力できません。<br />";
            validateResult = false;
          }
        }
      }
      return validateResult;
    },
    /**
     * 稼働ログダウンロード処理
     * @param inStartDatetime 開始日時(null可)
     * @param inEndDatetime 終了日時(null可)
     * @returns 非同期処理結果
     */
    async downloadRemoteControlLog(
      inStartDatetime: string | null,
      inEndDatetime: string | null
    ): Promise<void> {
      // パラメータ表示
      debugLog(inStartDatetime);
      debugLog(inEndDatetime);
      // ローディング表示ON
      this.isLoading = true;
      // 稼働ログ取得APIを呼び出す
      const responseOperationLogData = await getPlantsRemoteControlsData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        inStartDatetime,
        inEndDatetime
      );
      // エラーの場合は中断する
      if (responseOperationLogData === undefined) {
        // ローディング表示OFF
        this.isLoading = false;
        this.downloadErrorMessages = "データ取得に失敗しました。";
        this.downloadErrorDialog = true;
        return;
      }
      // データ件数・取得制限結果取得
      this.dataCount = 0;
      this.isTooManyResults = false;
      if (responseOperationLogData.records !== undefined) {
        this.dataCount = responseOperationLogData.records.length;
        this.isTooManyResults = responseOperationLogData.too_many_results;
      }
      // データ０件の場合はCSVデータは生成しない
      if (this.dataCount > 0) {
        // CSVヘッダー作成
        const csvHeader = [
          [
            "操作日時",
            "操作者",
            "設備名",
            "制御対象",
            "指示内容",
            "強制実行",
            "操作時コメント",
            "制御完了日時",
            "実行結果",
            "実行結果詳細",
            "処理前関連データ状況",
            "処理後関連データ状況",
          ],
        ];
        this.strCSVData = Papa.unparse(csvHeader, { quotes: true }) + "\r\n";
        // CSVデータ部生成、アペンド
        const csvConfig = {
          quotes: true,
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ",",
          header: false,
          newline: "\r\n",
          skipEmptyLines: false,
          columns: [
            "triggered_time",
            "email",
            "facility_name_main",
            "display_name",
            "switch_to",
            "force_execution",
            "triggered_for",
            "input_data_check_time",
            "overall_result",
            "overall_result_detail",
            "interlock_check_data_text",
            "input_check_data_text",
          ],
        };
        const remoteControlLogData = responseOperationLogData.records;
        this.strCSVData += Papa.unparse(remoteControlLogData, csvConfig);
      }
      // ローディング表示OFF
      this.isLoading = false;
      // ダウンロード確認ダイアログ表示
      this.downloadDialog = true;
    },
    /**
     * CSVファイル生成、ダウンロード実施処理
     * @param なし
     * @returns なし
     */
    downloadCsvData(): void {
      // ダウンロードするCSVファイルの名称を設定
      const csvFileName = "remote_control_log";
      // csvファイルを生成しダウンロード
      const dateNow = dayjs().format("YYYYMMDDHHmmss");
      // 文字化け防止のためにBOMを追加
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, this.strCSVData], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${dateNow}_${csvFileName}.csv`;
      link.click();
      debugLog(`${dateNow}_${csvFileName}.csv`);
      this.downloadDialog = false;
    },
    /**
     * ダウンロードキャンセル処理
     * @param なし
     * @returns なし
     */
    downloadCsvDataCancel(): void {
      // 内部メモリ初期化
      this.strCSVData = "";
      this.dataCount = 0;
      this.isTooManyResults = false;
      this.downloadErrorMessages = "";
      // ダイアログCLOSE
      this.downloadDialog = false;
    },
    /**
     * 開始日時のフォーマット
     * @param startDatetime 開始日時
     * @returns フォーマット後の開始日時
     */
    formatStartTime(startDatetime: { [key: string]: string }) {
      if (!startDatetime.date) {
        return null;
      }
      dayjs.extend(utc);
      const timeOffset: number = store.getters.companies_plant.time_offset;

      // 分単位の時刻オフセットから「+09:00」のような文字列を作成する。
      const timeOffsetString =
        (timeOffset > 0 ? "+" : "-") +
        String(Math.abs(timeOffset) / 60).padStart(2, "0") +
        ":" +
        String(Math.abs(timeOffset) % 60).padStart(2, "0");
      const formattedStartDateTime = dayjs(
        `${startDatetime.date}T${startDatetime.hour}:${startDatetime.minute}:00${timeOffsetString}`
      )
        .utcOffset(timeOffset)
        .format("YYYY-MM-DDTHH:mm:ssZ");
      return formattedStartDateTime;
    },
    /**
     * 終了日時のフォーマット
     * @param endDatetime 終了日時
     * @returns フォーマット後の終了日時
     */
    formatEndDateTime(endDatetime: { [key: string]: string }) {
      if (!endDatetime.date) {
        return null;
      }
      dayjs.extend(utc);
      const timeOffset: number = store.getters.companies_plant.time_offset;
      // 分単位の時刻オフセットから「+09:00」のような文字列を作成する。
      const timeOffsetString =
        (timeOffset > 0 ? "+" : "-") +
        String(Math.abs(timeOffset) / 60).padStart(2, "0") +
        ":" +
        String(Math.abs(timeOffset) % 60).padStart(2, "0");
      const formattedEndDateTime = dayjs(
        `${endDatetime.date}T${endDatetime.hour}:${endDatetime.minute}:00${timeOffsetString}`
      )
        .utcOffset(timeOffset)
        .format("YYYY-MM-DDTHH:mm:ssZ");
      return formattedEndDateTime;
    },
    /**
     * 検索条件クリア
     * @param なし
     * @returns なし
     */
    clearCondition(): void {
      this.startDatetime = {
        date: "",
        hour: "",
        minute: "",
      };
      this.endDatetime = {
        date: "",
        hour: "",
        minute: "",
      };
    },
    /**
     * 開始日時更新処理
     * カレンダーからの年月日選択後に呼び出される
     * @param なし
     * @returns なし
     */
    updateStartCalendar(): void {
      this.startDateCalender = false;
      if (this.startDatetime.hour === "") {
        this.startDatetime.hour = "09";
      }
      if (this.startDatetime.minute === "") {
        this.startDatetime.minute = "01";
      }
    },
    /**
     * 終了日時更新処理
     * カレンダーからの年月日選択後に呼び出される
     * @param なし
     * @returns なし
     */
    updateEndCalendar(): void {
      this.endDateCalender = false;
      if (this.endDatetime.hour === "") {
        this.endDatetime.hour = "09";
      }
      if (this.endDatetime.minute === "") {
        this.endDatetime.minute = "00";
      }
    },
    /**
     * ローカル日時に変更
     * 「2024-05-28T04:00:00Z」の形式をローカル日時形式「2024-05-28T13:00:00」に変更する
     * @param strDateTime 日時文字列
     * @returns 変更したローカル日時の日時文字列
     */
    changeLocalDatetime(strDateTime: string): string {
      if (strDateTime == null) {
        return "";
      }
      return dayjs(strDateTime).format("YYYY-MM-DD HH:mm:ss");
    },
    // データ表示一覧のヘッダー部を作成
    createTableHeaders(): void {
      this.headers = [
        {
          text: "操作日時",
          value: "triggered_time",
          divider: true,
          width: "120px",
        },
        { text: "操作者", value: "email", divider: true, width: "10px" },
        {
          text: "設備名",
          value: "facility_name_main",
          divider: true,
          width: "100px",
        },
        {
          text: "制御対象",
          value: "display_name",
          divider: true,
          width: "100px",
        },
        { text: "指示内容", value: "switch_to", divider: true, width: "100px" },
        {
          text: "強制実行",
          value: "force_execution",
          divider: true,
          width: "100px",
        },
        {
          text: "操作時コメント",
          value: "triggered_for",
          divider: true,
          width: "140px",
        },
        {
          text: "制御完了日時",
          value: "input_data_check_time",
          divider: true,
          width: "125px",
        },
        {
          text: "実行結果",
          value: "overall_result",
          divider: true,
          width: "100px",
        },
        {
          text: "実行結果詳細",
          value: "overall_result_detail",
          divider: true,
          width: "200px",
        },
        {
          text: "処理前関連データ状況",
          value: "interlock_check_data_text",
          divider: true,
          width: "280px",
        },
        {
          text: "処理後関連データ状況",
          value: "input_check_data_text",
          divider: true,
          width: "280px",
        },
      ];
      // 操作日時の降順でソート表示
      this.sortItem = "triggered_time";
      this.sortDesc = true;
    },
    // データ表示一覧のデータ部を作成
    createTableDatasets(): void {
      // テーブルのデータが変わるときはテーブルのフィルターをクリア
      this.filterTarget = null;
      // 日付形式変換
      const startTime = this.formatStartTime(this.startDatetime);
      const endTime = this.formatEndDateTime(this.endDatetime);
      // 一覧データを生成
      debugLog("稼働ログのデータ表示");
      this.dataViewRemoteControlsData(startTime, endTime);
    },
    // 運転履歴のデータ表示
    async dataViewRemoteControlsData(
      inStartDatetime: string | null,
      inEndDatetime: string | null
    ): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 一覧のデータをクリア
      this.datasets = [];
      // パラメータ表示
      debugLog("開始日時", inStartDatetime, "終了日時", inEndDatetime);
      // 稼働ログ取得APIを呼び出す
      const response = await getPlantsRemoteControlsData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        inStartDatetime,
        inEndDatetime
      );
      // エラーの場合は中断する
      if (response === undefined) {
        // DATA VIEWパネルを閉じ、データダウンロード条件入力パネルを開く
        this.isDataViewPanelVisible = false;
        this.downloadPanelState = [0];
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "データ取得に失敗しました。";
        this.showErrorDialog = true;
        return;
      }
      // 取得結果をチェックして表示する
      if (response.records !== undefined) {
        // 取得上限に達していた場合はエラーを表示（一覧表示の中断はしない）
        const too_many_results = response.too_many_results;
        if (too_many_results != null && too_many_results === true) {
          this.errorDialogMessage =
            "データ件数・サイズの制限により全件取得出来ませんでした。<br />" +
            "必要であれば条件を見直してください。";
          this.showErrorDialog = true;
        }
        // 一覧にデータをセット
        this.datasets = response.records;
      } else {
        // レスポンスがエラーでなくてもデータが0件の場合は中断する
        // ※0件の時はrecordsが空になるのではなくrecordsが欠落する点に注意

        // DATA VIEWパネルを閉じ、データダウンロード条件入力パネルを開く
        this.isDataViewPanelVisible = false;
        this.downloadPanelState = [0];
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "指定した範囲の対象データはありません。";
        this.showErrorDialog = true;
        return;
      }
      // // ダミーデータを生成する
      // this.datasets = this.createDummyData();
      // ローディング表示OFF
      this.isLoading = false;
    },
    // エラーポップアップを閉じる
    resetErrorDialog(): void {
      this.errorDialogMessage = "";
      this.showErrorDialog = false;
    },
    // ダミーデータを生成する
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createDummyData(): any {
      const dummyDatasets = [];
      for (let i = 0; i < 10; i++) {
        dummyDatasets.push({
          triggered_time: "2024-04-28 02:06:32",
          email: "user@company.com",
          facility_name_main: "発酵槽",
          display_name: "生ごみ供給ポンプ",
          switch_to: "運転",
          force_exec: "false",
          input_check_time: "2024-04-28 02:06:53",
          overall_result: "OK",
          overall_result_detail: "制御成功",
          interlock_check_data:
            "データ時刻: 2024-04-28 02:06:42\n" +
            "生ごみ供給ポンプ/制御: 0\n" +
            "生ごみ供給ポンプ/運転: 0\n" +
            "生ごみ供給ポンプ/故障(過負荷): 0\n" +
            "調整槽レベル計: 200",
          input_check_data:
            "データ時刻: 2024-04-28 02:06:53\n" +
            "生ごみ供給ポンプ/制御: 1\n" +
            "生ごみ供給ポンプ/運転: 1\n" +
            "調整槽レベル計: 200",
        });
      }
      return dummyDatasets;
    },
  },
});
