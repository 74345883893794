

















































































































































import Vue, { PropType } from "vue";
import { Scores } from "@/types";
export default Vue.extend({
  name: "Score",
  data() {
    return {
      scoreIcon: [
        {
          icon: "mdi-emoticon-dead",
          color: "error",
        },
        {
          icon: "mdi-emoticon-sad",
          color: "#D27118",
        },
        {
          icon: "mdi-emoticon-neutral",
          color: "#f5d832",
        },
        {
          icon: "mdi-emoticon-happy",
          color: "#58AC31",
        },
        {
          icon: "mdi-emoticon-excited",
          color: "primary",
        },
      ],
      scoreList: [] as Scores[],
      plantScore: {} as PlantScore,
    };
  },
  props: {
    scores: { type: Array as PropType<Scores[]>, default: () => [] },
  },
  methods: {
    updateScore() {
      const scores = this.scores;
      this.plantScore = scores[0];
      // debugLog("プラント全体スコア", this.plantScore.overallPlantScore);
      this.scoreList = scores.slice(1);
      // debugLog("プラントスコア", this.scoreList);
    },
  },
  mounted() {
    this.updateScore();
  },
  watch: {
    scores() {
      this.updateScore();
    },
  },
});
interface PlantScore {
  overallPlantScore?: number;
}
