import { render, staticRenderFns } from "./ShortArrowRight.vue?vue&type=template&id=71f05382&scoped=true&"
import script from "./ShortArrowRight.vue?vue&type=script&lang=ts&"
export * from "./ShortArrowRight.vue?vue&type=script&lang=ts&"
import style0 from "./ShortArrowRight.vue?vue&type=style&index=0&id=71f05382&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f05382",
  null
  
)

export default component.exports