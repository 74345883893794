
























































import Vue from "vue";
import Login from "@/components/Login.vue";
import LoginHeader from "@/components/LoginHeader.vue";
import SendResetCode from "@/components/SendResetCode.vue";
import ResetPassword from "@/components/ResetPassword.vue";
import CreateUser from "@/components/CreateUser.vue";
import { bus } from "../main";

export default Vue.extend({
  name: "SignIn",
  components: { LoginHeader, Login, SendResetCode, ResetPassword, CreateUser },
  data() {
    return {
      mailAddress: "",
      showLogin: true,
      showSendCode: false,
      showResetPassword: false,
      showCreateUser: false,
      showErrorDialog: false,
      errorMessages: "",
      isLoadingDisplay: false,
    };
  },
  created() {
    bus.$emit("background", "image1");
  },
  methods: {
    switchToSendCode(): void {
      this.mailAddress = "";
      this.showSendCode = true;
      this.showLogin = false;
      this.showResetPassword = false;
      this.showCreateUser = false;
    },
    switchToLogin(): void {
      this.mailAddress = "";
      this.showSendCode = false;
      this.showLogin = true;
      this.showResetPassword = false;
      this.showCreateUser = false;
    },
    switchToResetPassword(mailAddress: string): void {
      this.mailAddress = mailAddress;
      this.showSendCode = false;
      this.showLogin = false;
      this.showResetPassword = true;
      this.showCreateUser = false;
    },
    switchToCreateUser(): void {
      this.mailAddress = "";
      this.showSendCode = false;
      this.showLogin = false;
      this.showResetPassword = false;
      this.showCreateUser = true;
    },
    // ローディング表示のオンオフ切り換え
    loadingDisplay(show: boolean): void {
      this.isLoadingDisplay = show;
    },
    // cognitoから返ってきたエラーコードでエラーポップアップを表示する
    checkCognitoError(code: string): void {
      this.errorMessages = "";
      switch (code) {
        case "InvalidPasswordException":
          this.errorMessages = "パスワードポリシーを満たしていません";
          break;
        case "NotAuthorizedException":
          this.errorMessages =
            "会社コードまたはメールアドレスまたはパスワードが違います";
          break;
        case "UserNotConfirmedException":
          this.errorMessages = "ユーザーの有効化をしてください";
          break;
        case "ExpiredCodeException":
        case "CodeMismatchException":
          this.errorMessages = "メールアドレスまたは認証コードが違います";
          break;
        case "InvalidParameterException":
          if (this.showLogin === true) {
            this.errorMessages =
              "会社コードまたはメールアドレスまたはパスワードが違います";
          } else {
            this.errorMessages = "メールアドレスまたは認証コードが違います";
          }
          break;
        case "LimitExceededException":
          this.errorMessages =
            "試行制限を超過しました。しばらくしてからもう一度お試しください";
          break;
        default:
          this.errorMessages = "認証処理が失敗しました";
          break;
      }
      this.showErrorDialog = true;
    },
    // cognitoのエラーポップアップを閉じる
    resetCognitoError(): void {
      this.errorMessages = "";
      this.showErrorDialog = false;
    },
  },
});
