





























































































































































































































































































































import { debugLog } from "@/lib/debug_util";
import Vue from "vue";
import { bus } from "../main";
import dayjs from "dayjs";
import store from "@/store";
import { PlantControl, DisplayDataTime } from "@/types";
import WebApiModel from "@/apis/web_api";
import {
  getDailyReportLogData,
  getOperationLogData,
  getAlertLogData,
} from "@/lib/web_api_util";
export default Vue.extend({
  name: "MobileDataView",
  data() {
    return {
      // データ種別
      dataType: "dailyReportData",
      // 開始日付カレンダー表示制御
      startDateCalender: false,
      // 開始日時格納
      startDatetime: {
        date: "",
        hour: "",
        minute: "",
      },
      // 終了日付カレンダー表示制御
      endDateCalender: false,
      // 終了日時格納
      endDatetime: {
        date: "",
        hour: "",
        minute: "",
      },
      // 時項目コンボボックス値
      hours: ["Dummy"],
      // 分項目コンボボックス値
      minutes: ["Dummy"],
      // WebAPI用変数
      webApiModel: {} as WebApiModel,
      // 条件設定エラーダイアログ表示制御
      dataViewErrorDialog: false,
      // 条件設定エラーメッセージ
      dataViewErrorMessages: "",
      // ローディング表示制御
      isLoading: false,
      // データ表示ダイアログ表示制御
      dataListDialog: false,
      // データ表示の表示項目選択表示制御
      isTypeColumnSelect: false,
      // columnItems: ["Dummy"],
      // データ表示の表示項目選択肢
      columnItems: [] as Array<string>,
      // データ表示の表示項目選択用
      selectedColumns: [] as Array<string>,
      // 日報データの表示項目(日報データ、積算で使用)
      reportColumns: [] as Array<string>,
      // 積算データの表示項目(日報データ、積算で使用)
      additionColumns: [] as Array<string>,
      // データ表示の対象絞り込み表示制御
      isTypeFilter: false,
      // データ表示の対象絞り込み選択肢
      targetItems: [] as Array<string>,
      // データ表示の対象絞り込み選択用
      filterTarget: null,
      // データ表示の一覧ヘッダー
      headers: [{}],
      // データ表示の一覧データ
      datasets: [{}],
      // データ表示の一覧ソートアイテム
      sortItem: "record_time",
      // データ表示の一覧ソート順
      sortDesc: false,
      // エラーダイアログ表示制御
      showErrorDialog: false,
      // エラーダイアログメッセージ
      errorDialogMessage: "",
    };
  },
  created() {
    // 背景イメージなし
    bus.$emit("background", "noImage");
    // 時間の配列作成
    this.hours = [];
    for (let i = 0; i <= 23; i++) {
      this.hours.push(String(i).padStart(2, "0"));
    }
    // 分の配列作成
    this.minutes = [];
    for (let i = 0; i <= 59; i++) {
      this.minutes.push(String(i).padStart(2, "0"));
    }
    // APIアクセス用ライブラリを作成
    this.webApiModel = new WebApiModel();
  },
  computed: {
    selectAllColumns(): boolean {
      return this.selectedColumns.length === this.columnItems.length;
    },
    selectSomeColumns(): boolean {
      return this.selectedColumns.length > 0 && !this.selectAllColumns;
    },
    selectReportAllColumns(): boolean {
      // 日報データの表示項目のうち、選択されていない項目を抽出
      const notSelectedReportColumns = this.reportColumns.filter(
        (i) => this.selectedColumns.indexOf(i) == -1
      );
      return notSelectedReportColumns.length === 0;
    },
    selectReportSomeColumns(): boolean {
      // 日報データの表示項目のうち、選択されている項目を抽出
      const selectedReportColumns = this.selectedColumns.filter(
        (i) => this.reportColumns.indexOf(i) >= 0
      );
      return selectedReportColumns.length > 0 && !this.selectReportAllColumns;
    },
    selectAdditionAllColumns(): boolean {
      // 日報データの表示項目のうち、選択されていない項目を抽出
      const notSelectedAdditionColumns = this.additionColumns.filter(
        (i) => this.selectedColumns.indexOf(i) == -1
      );
      return notSelectedAdditionColumns.length === 0;
    },
    selectAdditionSomeColumns(): boolean {
      // 日報データの表示項目のうち、選択されている項目を抽出
      const selectedAdditionColumns = this.selectedColumns.filter(
        (i) => this.additionColumns.indexOf(i) >= 0
      );
      return (
        selectedAdditionColumns.length > 0 && !this.selectAdditionAllColumns
      );
    },
    getSelectIcon(): string {
      if (this.selectAllColumns) return "mdi-close-box";
      if (this.selectSomeColumns) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    getSelectReportIcon(): string {
      if (this.selectReportAllColumns) return "mdi-close-box";
      if (this.selectReportSomeColumns) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    getSelectAdditionIcon(): string {
      if (this.selectAdditionAllColumns) return "mdi-close-box";
      if (this.selectAdditionSomeColumns) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    dataTypeTargetName(): string {
      let dataTypeTargetName = "";
      if (this.dataType === "dailyReportData") {
        dataTypeTargetName = "日報データ";
      } else if (this.dataType === "operationHistory") {
        dataTypeTargetName = "運転履歴";
      } else if (this.dataType === "alertHistory") {
        dataTypeTargetName = "アラート履歴";
      } else if (this.dataType === "additionData") {
        dataTypeTargetName = "積算";
      }
      return dataTypeTargetName;
    },
    rangeDataTime(): string {
      let strRange = "";
      if (this.startDatetime.date !== "") {
        strRange =
          strRange +
          this.startDatetime.date +
          " " +
          this.startDatetime.hour +
          ":" +
          this.startDatetime.minute;
      }
      strRange = strRange + " ~ ";
      if (this.endDatetime.date !== "") {
        strRange =
          strRange +
          this.endDatetime.date +
          " " +
          this.endDatetime.hour +
          ":" +
          this.endDatetime.minute;
      }
      return strRange;
    },
  },
  watch: {
    selectedColumns: {
      handler: function () {
        this.changeTableHeaders();
      },
    },
  },
  methods: {
    // DATA VIEWボタンを押下
    openDataView(): void {
      this.dataViewErrorMessages = "";
      // 入力値チェック
      if (
        !this.validateInput(this.dataType, this.startDatetime, this.endDatetime)
      ) {
        this.dataViewErrorDialog = true;
        return;
      }
      this.createTableHeders(this.dataType);
      this.createTableDatasets(this.dataType);
      if (this.showErrorDialog === true) {
        // WebAPIアクセスでエラー発生時等
        return;
      }
      if (
        this.dataType === "dailyReportData" ||
        this.dataType === "additionData"
      ) {
        this.isTypeColumnSelect = true;
        this.isTypeFilter = !this.isTypeColumnSelect;
      } else {
        this.isTypeColumnSelect = false;
        this.isTypeFilter = !this.isTypeColumnSelect;
      }
      this.dataListDialog = true;
    },
    // 入力チェック（全種別共通）
    validateInput(
      inDownloadType: string,
      inStartTime: DisplayDataTime,
      inEndTime: DisplayDataTime
    ): boolean {
      // Validate結果
      let validateResult = true;
      // データ種別：必須入力
      if (inDownloadType === undefined || inDownloadType === "") {
        this.dataViewErrorMessages += "データ種別が選択されていません。<br />";
        validateResult = false;
      }
      // 開始日時：いずれかに入力がある場合、日時分のすべて入力されていること
      if (
        inStartTime.date !== "" ||
        inStartTime.hour !== "" ||
        inStartTime.minute !== ""
      ) {
        if (
          inStartTime.date === "" ||
          inStartTime.hour === "" ||
          inStartTime.minute === ""
        ) {
          this.dataViewErrorMessages +=
            "開始日時を指定する場合は年月日時刻をすべて入力してください。<br />";
          validateResult = false;
        }
      }
      // 終了日時：いずれかに入力がある場合、日時分のすべて入力されていること
      if (
        inEndTime.date !== "" ||
        inEndTime.hour !== "" ||
        inEndTime.minute !== ""
      ) {
        if (
          inEndTime.date === "" ||
          inEndTime.hour === "" ||
          inEndTime.minute === ""
        ) {
          this.dataViewErrorMessages +=
            "終了日時を指定する場合は年月日時刻をすべて入力してください。<br />";
          validateResult = false;
        }
      }
      // 日付相関チェック
      if (validateResult) {
        // 日付形式変換
        const startTime = this.formatStartDateTime(this.startDatetime);
        const endTime = this.formatEndDateTime(this.endDatetime);
        // 開始日時、終了日時がともに未入力の場合はエラー
        if (startTime === null && endTime === null) {
          this.dataViewErrorMessages +=
            "開始日時・終了日時のいずれかは必ず指定してください。<br />";
          validateResult = false;
        }
        // 開始日時が終了日時よりも後の場合はエラー（同じ日時もエラー）
        if (startTime !== null && endTime !== null) {
          if (startTime >= endTime) {
            this.dataViewErrorMessages +=
              "開始日時に終了日時より前の日時は入力できません。<br />";
            validateResult = false;
          }
        }
      }
      return validateResult;
    },
    // 日時をプラントの時刻オフセットの時間に変換
    changeDateTimeWithPlantTimeOffset(inTime: null | string) {
      // プラントの時刻オフセットから「+09:00」のような文字列を作成し、
      // それを日時のオフセットとして返す。
      const timeOffset: number = store.getters.companies_plant.time_offset;
      const timeOffsetString =
        (timeOffset >= 0 ? "+" : "-") +
        String(Math.abs(timeOffset) / 60).padStart(2, "0") +
        ":" +
        String(Math.abs(timeOffset) % 60).padStart(2, "0");
      let changedTime: null | string = null;
      if (inTime) {
        changedTime =
          dayjs(inTime).format("YYYY-MM-DDTHH:mm:ss") + timeOffsetString;
      }
      return changedTime;
    },
    formatStartDateTime(startDatetime: { [key: string]: string }) {
      if (!startDatetime.date) {
        return null;
      }
      const formattedStartDateTime = dayjs(
        `${startDatetime.date}T${startDatetime.hour}:${startDatetime.minute}:00`
      ).format();
      const changedDateTime = this.changeDateTimeWithPlantTimeOffset(
        formattedStartDateTime
      );
      return changedDateTime;
    },
    formatEndDateTime(endDatetime: { [key: string]: string }) {
      if (!endDatetime.date) {
        return null;
      }
      const formattedEndDateTime = dayjs(
        `${endDatetime.date}T${endDatetime.hour}:${endDatetime.minute}:59`
      ).format();
      const changedDateTime =
        this.changeDateTimeWithPlantTimeOffset(formattedEndDateTime);
      return changedDateTime;
    },
    clearCondition(): void {
      this.dataType = "dailyReportData";
      this.startDatetime = {
        date: "",
        hour: "",
        minute: "",
      };
      this.endDatetime = {
        date: "",
        hour: "",
        minute: "",
      };
    },
    toggleColumnsAll(): void {
      this.$nextTick(() => {
        if (this.selectAllColumns) {
          this.selectedColumns = [];
        } else {
          this.selectedColumns = this.columnItems.slice();
        }
      });
    },
    toggleColumnsReportAll(): void {
      this.$nextTick(() => {
        if (this.selectReportAllColumns) {
          // 選択中項目から日報データの表示項目のみを削除
          this.selectedColumns = this.selectedColumns.filter(
            (i) => this.reportColumns.indexOf(i) == -1
          );
        } else {
          // 不足している日報データの表示項目を洗い出す。
          const addColumns = this.reportColumns.filter(
            (i) => this.selectedColumns.indexOf(i) == -1
          );
          // 選択中項目に不足していた日報データの表示項目を後ろに追加
          this.selectedColumns = this.selectedColumns.concat(addColumns);
        }
      });
    },
    toggleColumnsAdditionAll(): void {
      this.$nextTick(() => {
        if (this.selectAdditionAllColumns) {
          // 選択中項目から積算データの表示項目のみを削除
          this.selectedColumns = this.selectedColumns.filter(
            (i) => this.additionColumns.indexOf(i) == -1
          );
        } else {
          // 不足している日報データの表示項目を洗い出す。
          const addColumns = this.additionColumns.filter(
            (i) => this.selectedColumns.indexOf(i) == -1
          );
          // 選択中項目に不足していた日報データの表示項目を後ろに追加
          this.selectedColumns = this.selectedColumns.concat(addColumns);
        }
      });
    },
    updateStartCalendar(): void {
      this.startDateCalender = false;
      if (this.startDatetime.hour === "") {
        this.startDatetime.hour = "09";
      }
      if (this.startDatetime.minute === "") {
        console.log(this.dataType);
        if (
          this.dataType === "dailyReportData" ||
          this.dataType === "additionData"
        ) {
          // if: 日報データと積算データのみデフォルトの検索条件を9時00分~翌8時59分とする
          this.startDatetime.minute = "00";
        } else {
          this.startDatetime.minute = "01";
        }
      }
    },
    updateEndCalendar(): void {
      this.endDateCalender = false;
      if (this.endDatetime.hour === "") {
        if (
          this.dataType === "dailyReportData" ||
          this.dataType === "additionData"
        ) {
          // if: 日報データと積算データのみデフォルトの検索条件を9時00分~翌8時59分とする
          this.endDatetime.hour = "08";
        } else {
          this.endDatetime.hour = "09";
        }
      }
      if (this.endDatetime.minute === "") {
        if (
          this.dataType === "dailyReportData" ||
          this.dataType === "additionData"
        ) {
          // if: 日報データと積算データのみデフォルトの検索条件を9時00分~翌8時59分とする
          this.endDatetime.minute = "59";
        } else {
          this.endDatetime.minute = "00";
        }
      }
    },
    formatDatetime(strDateTime: string): string {
      // ローカル時刻表示をさせないため、ISO 8601フォーマットの日時文字列から
      // 「+09:00」のような時刻オフセット部分を削除してから、指定の日時文字列に変換して返す。
      let strChangeDateTime = "";
      if (strDateTime != null) {
        strChangeDateTime = strDateTime.substring(0, 19);
      }
      let resultStrDateTime = "";
      resultStrDateTime = dayjs(strChangeDateTime).format("MM-DD HH:mm");
      return resultStrDateTime;
    },
    // 日報データ用制御データ取得
    getReportControlList(): PlantControl[] {
      const reportTypePlantControlList: PlantControl[] = [];
      // Storeから制御データ取得
      const plant_control_list = store.getters.plant_control_list;
      // 初期化
      let max_report_priority = 0;
      this.reportColumns.splice(0);
      this.additionColumns.splice(0);
      // 日報対象データを抽出する
      plant_control_list.forEach((plantControl: PlantControl) => {
        if (plantControl.type_for_report != "unnecessary") {
          reportTypePlantControlList.push(plantControl);
          if (plantControl.report_priority > max_report_priority) {
            max_report_priority = plantControl.report_priority;
          }
        }
      });
      // 日報対象データに積算用("(日次)"を項目名の先頭に付与)を追加する
      // ・項目名英字は先頭に"daily_"を付与
      // ・日報データ用項目名は先頭に"(日次)"を付与
      // ・日報データ並び順は日報対象データの最大値の値に1足したものを加算
      plant_control_list.forEach((plantControl: PlantControl) => {
        if (plantControl.type_for_report != "unnecessary") {
          const daily = Object.assign({}, plantControl);
          daily.display_name_en = "daily_" + daily.display_name_en;
          daily.report_name = "(日次)" + daily.report_name;
          daily.report_priority += max_report_priority + 1;
          reportTypePlantControlList.push(daily);
        }
      });
      // 表示順に並び替える
      reportTypePlantControlList.sort(
        (a, b) => a.report_priority - b.report_priority
      );
      // 並び替えた項目を日報データと積算データの表示項目に分類して追加する
      reportTypePlantControlList.forEach((plantControl: PlantControl) => {
        if (plantControl.display_name_en.slice(0, 6) === "daily_") {
          this.additionColumns.push(plantControl.report_name);
        } else {
          this.reportColumns.push(plantControl.report_name);
        }
      });
      // 生成したデータを返す
      return reportTypePlantControlList;
    },
    // 運転履歴用制御データ取得
    getOperationControlList(): PlantControl[] {
      const operationTypePlantControlList: PlantControl[] = [];
      // Storeから制御データ取得
      const plant_control_list = store.getters.plant_control_list;
      // 日報対象データを抽出する
      plant_control_list.forEach((plantControl: PlantControl) => {
        if (plantControl.data_type == "operation") {
          // 要画面表示がtrueのみ対象とする
          if (plantControl.display_required == true) {
            operationTypePlantControlList.push(plantControl);
          }
        }
      });
      // 表示順に並び替える
      operationTypePlantControlList.sort(
        (a, b) => a.operation_priority - b.operation_priority
      );
      // 生成したデータを返す
      return operationTypePlantControlList;
    },
    // アラート履歴用制御データ取得
    getAlertControlList(): PlantControl[] {
      const alertTypePlantControlList: PlantControl[] = [];
      // Storeから制御データ取得
      const plant_control_list = store.getters.plant_control_list;
      // 日報対象データを抽出する
      plant_control_list.forEach((plantControl: PlantControl) => {
        if (plantControl.data_type == "alert") {
          // 要画面表示がtrueのみ対象とする
          if (plantControl.display_required == true) {
            alertTypePlantControlList.push(plantControl);
          }
        }
      });
      // 表示順に並び替える
      alertTypePlantControlList.sort(
        (a, b) => a.alert_priority - b.alert_priority
      );
      // 生成したデータを返す
      return alertTypePlantControlList;
    },
    // データ表示一覧のヘッダー部を変更（日報データのみ）
    changeTableHeaders(): void {
      this.headers = [
        {
          text: "日報日時",
          value: "record_time",
          width: "120px",
          divider: true,
        },
      ];
      const reportControlList = this.getReportControlList();
      this.selectedColumns.forEach((item) => {
        reportControlList.forEach((plantControl: PlantControl) => {
          if (plantControl.report_name == item) {
            let strText = plantControl.report_name;
            if (plantControl.display_name_en.slice(0, 6) === "daily_") {
              // "(日次)"と項目名英字の間に改行を入れる
              strText = strText.slice(0, 4) + "\r\n" + strText.slice(4);
            }
            if (plantControl.type_for_report != null) {
              strText = strText + "\r\n" + plantControl.type_for_report;
            }
            const hederObject = {
              text: strText,
              value: plantControl.display_name_en,
              width: "95px",
              align: "end",
              divider: true,
            };
            this.headers.push(hederObject);
          }
        });
      });
    },
    // データ表示一覧のヘッダー部を作成
    createTableHeders(intDownloadType: string): void {
      if (
        intDownloadType === "dailyReportData" ||
        intDownloadType === "additionData"
      ) {
        this.columnItems = [];
        const dailyReportHeaders = [
          { text: "日報日時", value: "record_time", divider: true },
        ];
        const reportControlList = this.getReportControlList();
        reportControlList.forEach((plantControl: PlantControl) => {
          let strText = plantControl.report_name;
          this.columnItems.push(strText);
          if (plantControl.display_name_en.slice(0, 6) === "daily_") {
            // "(日次)"と項目名英字の間に改行を入れる
            strText = strText.slice(0, 4) + "\r\n" + strText.slice(4);
          }
          if (plantControl.type_for_report != null) {
            strText = strText + "\r\n" + plantControl.type_for_report;
          }
          const hederObject = {
            text: strText,
            value: plantControl.display_name_en,
            align: "end",
            divider: true,
          };
          dailyReportHeaders.push(hederObject);
        });
        // 表示項目選択のデフォルト選択
        if (intDownloadType === "dailyReportData") {
          this.selectedColumns = this.reportColumns.concat();
        } else {
          this.selectedColumns = this.additionColumns.concat();
        }
        this.headers = dailyReportHeaders;
        // 日報日時の昇順でソート表示
        this.sortItem = "record_time";
        this.sortDesc = false;
      } else if (intDownloadType === "operationHistory") {
        this.targetItems = [];
        this.headers = [
          {
            text: "データ日時",
            value: "record_time_origin",
            width: "120px",
            divider: true,
          },
          { text: "対象", value: "display_name", divider: true },
          { text: "状態", value: "display_name_for_value" },
        ];
        const operationControlList = this.getOperationControlList();
        operationControlList.forEach((plantControl: PlantControl) => {
          const strText = plantControl.display_name_full;
          this.targetItems.push(strText);
        });
        // データ日時の降順でソート表示
        this.sortItem = "record_time_origin";
        this.sortDesc = true;
      } else {
        this.targetItems = [];
        this.headers = [
          {
            text: "データ日時",
            value: "record_time_origin",
            width: "120px",
            divider: true,
          },
          { text: "対象", value: "display_name", divider: true },
          { text: "状態", value: "display_name_for_value" },
        ];
        const alertControlList = this.getAlertControlList();
        alertControlList.forEach((plantControl: PlantControl) => {
          const strText = plantControl.display_name_full;
          this.targetItems.push(strText);
        });
        // データ日時の降順でソート表示
        this.sortItem = "record_time_origin";
        this.sortDesc = true;
      }
    },
    // データ表示一覧のデータ部を作成
    createTableDatasets(intDownloadType: string): void {
      // テーブルのデータが変わるときはテーブルのフィルターをクリア
      this.filterTarget = null;
      // 日付形式変換
      const startTime = this.formatStartDateTime(this.startDatetime);
      const endTime = this.formatEndDateTime(this.endDatetime);
      // ダウンロードタイプごと一覧データを生成
      switch (intDownloadType) {
        case "dailyReportData":
          debugLog("日報データのデータ表示");
          this.dataViewDailyReportHistory(startTime, endTime);
          break;
        case "operationHistory":
          debugLog("運転履歴のデータ表示");
          this.dataViewOperationHistory(startTime, endTime);
          break;
        case "alertHistory":
          debugLog("アラート履歴のデータ表示");
          this.dataViewAlertHistory(startTime, endTime);
          break;
        case "additionData":
          debugLog("積算データのデータ表示");
          this.dataViewDailyReportHistory(startTime, endTime);
          break;
        default:
          debugLog("想定外エラー");
      }
    },
    // 日報データのデータ表示
    async dataViewDailyReportHistory(
      inStartDatetime: string | null,
      inEndDatetime: string | null
    ): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 一覧のデータをクリア
      this.datasets = [];
      // パラメータ表示
      debugLog("開始日時", inStartDatetime, "終了日時", inEndDatetime);
      //日報データ取得APIを呼び出す
      const responseDailyReportLogData = await getDailyReportLogData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        inStartDatetime,
        inEndDatetime
      );
      // エラーの場合は中断する
      if (responseDailyReportLogData === undefined) {
        // DATA VIEWダイアログを閉じる
        this.dataListDialog = false;
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "データ取得に失敗しました。";
        this.showErrorDialog = true;
        return;
      }
      // 取得結果をチェックして表示する
      if (responseDailyReportLogData.records !== undefined) {
        // 取得上限に達していた場合はエラーを表示（一覧表示の中断はしない）
        const too_many_results = responseDailyReportLogData.too_many_results;
        if (too_many_results != null && too_many_results === true) {
          this.errorDialogMessage =
            "データ件数・サイズの制限により全件取得出来ませんでした。<br />" +
            "必要であれば条件を見直してください。";
          this.showErrorDialog = true;
        }
        // 一覧にデータをセット
        this.datasets = responseDailyReportLogData.records;
      } else {
        // レスポンスがエラーでなくてもデータが0件の場合は中断する
        // ※0件の時はrecordsが空になるのではなくrecordsが欠落する点に注意

        // DATA VIEWダイアログを閉じる
        this.dataListDialog = false;
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "指定した範囲の対象データはありません。";
        this.showErrorDialog = true;
        return;
      }
      // ローディング表示OFF
      this.isLoading = false;
    },
    // 運転履歴のデータ表示
    async dataViewOperationHistory(
      inStartDatetime: string | null,
      inEndDatetime: string | null
    ): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 一覧のデータをクリア
      this.datasets = [];
      // パラメータ表示
      debugLog("開始日時", inStartDatetime, "終了日時", inEndDatetime);
      // プラントアラート履歴取得APIを呼び出す
      const responseOperationLogData = await getOperationLogData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        inStartDatetime,
        inEndDatetime
      );
      // エラーの場合は中断する
      if (responseOperationLogData === undefined) {
        // DATA VIEWダイアログを閉じる
        this.dataListDialog = false;
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "データ取得に失敗しました。";
        this.showErrorDialog = true;
        return;
      }
      // 取得結果をチェックして表示する
      if (responseOperationLogData.records !== undefined) {
        // 取得上限に達していた場合はエラーを表示（一覧表示の中断はしない）
        const too_many_results = responseOperationLogData.too_many_results;
        if (too_many_results != null && too_many_results === true) {
          this.errorDialogMessage =
            "データ件数・サイズの制限により全件取得出来ませんでした。<br />" +
            "必要であれば条件を見直してください。";
          this.showErrorDialog = true;
        }
        // 一覧にデータをセット
        this.datasets = responseOperationLogData.records;
      } else {
        // レスポンスがエラーでなくてもデータが0件の場合は中断する
        // ※0件の時はrecordsが空になるのではなくrecordsが欠落する点に注意

        // DATA VIEWダイアログを閉じる
        this.dataListDialog = false;
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "指定した範囲の対象データはありません。";
        this.showErrorDialog = true;
        return;
      }
      // ローディング表示OFF
      this.isLoading = false;
    },
    // アラート履歴のデータ表示
    async dataViewAlertHistory(
      inStartDatetime: string | null,
      inEndDatetime: string | null
    ): Promise<void> {
      // ローディング表示ON
      this.isLoading = true;
      // 一覧のデータをクリア
      this.datasets = [];
      // パラメータ表示
      debugLog("開始日時", inStartDatetime, "終了日時", inEndDatetime);
      // プラントアラート履歴取得APIを呼び出す
      const responseAlertLogData = await getAlertLogData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        inStartDatetime,
        inEndDatetime
      );
      // エラーの場合は中断する
      if (responseAlertLogData === undefined) {
        // DATA VIEWダイアログを閉じる
        this.dataListDialog = false;
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "データ取得に失敗しました。";
        this.showErrorDialog = true;
        return;
      }
      // 取得結果をチェックして表示する
      if (responseAlertLogData.records !== undefined) {
        // 取得上限に達していた場合はエラーを表示（一覧表示の中断はしない）
        const too_many_results = responseAlertLogData.too_many_results;
        if (too_many_results != null && too_many_results === true) {
          this.errorDialogMessage =
            "データ件数・サイズの制限により全件取得出来ませんでした。<br />" +
            "必要であれば条件を見直してください。";
          this.showErrorDialog = true;
        }
        // 一覧にデータをセット
        this.datasets = responseAlertLogData.records;
      } else {
        // レスポンスがエラーでなくてもデータが0件の場合は中断する
        // ※0件の時はrecordsが空になるのではなくrecordsが欠落する点に注意

        // DATA VIEWダイアログを閉じる
        this.dataListDialog = false;
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "指定した範囲の対象データはありません。";
        this.showErrorDialog = true;
        return;
      }
      // ローディング表示OFF
      this.isLoading = false;
    },
    // エラーポップアップを閉じる
    resetErrorDialog(): void {
      this.errorDialogMessage = "";
      this.showErrorDialog = false;
    },
  },
});
