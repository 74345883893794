import Vue from "vue";
import Vuex from "vuex";
import {
  CompaniesPlant,
  PlantControl,
  PlantFacility,
  DisplayDataTime,
  LatestPlcAddressValue,
} from "@/types";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    company_id: "" as string,
    companies_plant: {} as CompaniesPlant,
    plant_control_list: [] as PlantControl[],
    plant_facility_list: [] as PlantFacility[],
    latest_values_list: [] as LatestPlcAddressValue[],
    isAutomation: false as boolean,
    isEmergencyStop: false as boolean,
    isAutoUpdate: true as boolean,
    isOnceUpdate: false as boolean,
    displayDataTime: {
      date: "" as string,
      hour: "" as string,
      minute: "" as string,
    } as DisplayDataTime,
    onceUpdateDataTime: {
      date: "" as string,
      hour: "" as string,
      minute: "" as string,
    } as DisplayDataTime,
    nextLoadingDisplay: true as boolean,
    refresh_seconds: 60 as number,
    showDatasetItems: [] as Array<string>,
  },
  getters: {
    company_id: (state) => {
      return state.company_id;
    },
    companies_plant: (state) => {
      return state.companies_plant;
    },
    plant_control_list: (state) => {
      return state.plant_control_list;
    },
    plant_facility_list: (state) => {
      return state.plant_facility_list;
    },
    latest_values_list: (state) => {
      return state.latest_values_list;
    },
    isAutoUpdate: (state) => {
      return state.isAutoUpdate;
    },
    isOnceUpdate: (state) => {
      return state.isOnceUpdate;
    },
    isAutomation: (state) => {
      return state.isAutomation;
    },
    isEmergencyStop: (state) => {
      return state.isEmergencyStop;
    },
    displayDataTime: (state) => {
      return state.displayDataTime;
    },
    onceUpdateDataTime: (state) => {
      return state.onceUpdateDataTime;
    },
    nextLoadingDisplay: (state) => {
      return state.nextLoadingDisplay;
    },
    refresh_seconds: (state) => {
      return state.refresh_seconds;
    },
    showDatasetItems: (state) => {
      return state.showDatasetItems;
    },
  },
  mutations: {
    setCompanyId: (state, company_id: string) => {
      state.company_id = company_id;
    },
    setCompaniesPlant: (state, companies_plant: CompaniesPlant) => {
      state.companies_plant = Object.assign({}, companies_plant);
    },
    setPlantControlList: (state, plant_control_list: PlantControl[]) => {
      const copy_list: PlantControl[] = [];
      if (plant_control_list != null) {
        for (let i = 0; i < plant_control_list.length; i++) {
          const plant_control = Object.assign({}, plant_control_list[i]);
          copy_list.push(plant_control);
        }
      }
      state.plant_control_list = copy_list;
    },
    setPlantFacility: (state, plant_facility_list: PlantFacility[]) => {
      const copy_list: PlantFacility[] = [];
      if (plant_facility_list != null) {
        for (let i = 0; i < plant_facility_list.length; i++) {
          const plant_facility = Object.assign({}, plant_facility_list[i]);
          copy_list.push(plant_facility);
        }
      }
      state.plant_facility_list = copy_list;
    },
    setLatestValue: (state, latest_values_list: LatestPlcAddressValue[]) => {
      const copy_list: LatestPlcAddressValue[] = [];
      if (latest_values_list != null) {
        for (let i = 0; i < latest_values_list.length; i++) {
          const latest_value = Object.assign({}, latest_values_list[i]);
          copy_list.push(latest_value);
        }
      }
      state.latest_values_list = copy_list;
    },
    setIsAutomation: (state, isAutomation: boolean) => {
      state.isAutomation = isAutomation;
    },
    setIsEmergencyStop: (state, isEmergencyStop: boolean) => {
      state.isEmergencyStop = isEmergencyStop;
    },
    setIsAutoUpdate: (state, isAutoUpdate: boolean) => {
      state.isAutoUpdate = isAutoUpdate;
    },
    setIsOnceUpdate: (state, isOnceUpdate: boolean) => {
      state.isOnceUpdate = isOnceUpdate;
    },
    setDisplayDataTime: (state, displayDataTime: DisplayDataTime) => {
      state.displayDataTime = displayDataTime;
    },
    setOnceUpdateDataTime: (state, onceUpdateDataTime: DisplayDataTime) => {
      state.onceUpdateDataTime = onceUpdateDataTime;
    },
    setNextLoadingDisplay: (state, nextLoadingDisplay: boolean) => {
      state.nextLoadingDisplay = nextLoadingDisplay;
    },
    setRefreshSeconds: (state, refresh_seconds: number) => {
      state.refresh_seconds = refresh_seconds;
    },
    setShowDatasetItems: (state, showDatasetItems: Array<string>) => {
      state.showDatasetItems = showDatasetItems;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
