






























































import Vue from "vue";
import { Auth } from "aws-amplify";
export default Vue.extend({
  name: "SendResetCode",
  data() {
    return {
      valid: false,
      mailAddress: "",
      mailAddressRules: [
        (value: unknown) => !!value || "メールアドレスは必須入力です",
      ],
    };
  },
  methods: {
    // 認証コード送信処理
    async forgotPassword(): Promise<void> {
      // 入力チェックを実施
      if (
        (
          this.$refs.sendResetCodeForm as Vue & { validate: () => boolean }
        ).validate()
      ) {
        // 入力チェックでエラーがない場合
        try {
          // ローディング表示オン
          this.$emit("loadingDisplay", true);
          // 認証コード送信
          await Auth.forgotPassword(this.mailAddress);
          // ローディング表示オフ
          this.$emit("loadingDisplay", false);
          // パスワード再設定画面に切り換え
          this.$emit("switchToResetPassword", this.mailAddress);
          // フォームをリセットして初期状態にする（次回表示用）
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.$refs.sendResetCodeForm as any).reset();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          console.error(err);
          // ローディング表示オフ
          this.$emit("loadingDisplay", false);
          // エラーポップアップを表示
          this.$emit("checkCognitoError", err.code);
        }
      }
    },
    // ログイン画面へ切り換え
    switchToLogin() {
      // ログイン画面へ切り換え
      this.$emit("switchToLogin");
      // フォームをリセットして初期状態にする（次回表示用）
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.sendResetCodeForm as any).reset();
    },
  },
});
