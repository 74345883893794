








import Vue from "vue";
export default Vue.extend({
  name: "arrow-down",
  props: {
    arrowColor: { type: String, default: "#e76d57" },
  },
  mounted() {
    const targetElement = this.$refs["arrow-down"] as HTMLElement;
    if (targetElement) {
      targetElement.style.setProperty("--arrowColor", this.arrowColor);
    }
  },
});
