


















import Vue from "vue";
import CustomList from "./CustomList.vue";
import { getIconInfo } from "../../lib/get_icon_info";

export default Vue.extend({
  name: "single-tank",
  components: { CustomList },
  props: {
    title: { type: String, default: "", required: true },
    icon: { type: String, default: "", required: true },
    dataList: { type: Array, default: () => [] },
  },
  data() {
    return {
      iconObject: getIconInfo(),
    };
  },
});
