<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :css-classes="cssClasses"
    :styles="styles"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import store from "@/store";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  ScatterController,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  ScatterController
);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    datasets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            type: "bar",
            label: "",
          },
        ],
      },
      chartOptions: {
        animation: false,
        plugins: {
          tooltip: {
            callbacks: {
              title: function (tooltipItems) {
                const label = tooltipItems[0].label;
                if (label.length <= 10) {
                  return label;
                } else {
                  return label.substring(0, 10) + " " + label.substring(11, 16);
                }
              },
            },
          },
          legend: {
            // 凡例クリック時の処理
            // ・凡例の表示非表示状態を覚えておくコードを追加したいため、クリックイベントをオーバライトする。
            //   それに伴い独自でクリック時の表示非表示の理実装が必要となっている。
            onClick: (e, legendItem, legend) => {
              const index = legendItem.datasetIndex;
              const ci = legend.chart;
              if (ci.isDatasetVisible(index)) {
                ci.hide(index);
                legendItem.hidden = true;
                let itemIndex = this.selectedLegendItem.indexOf(
                  legendItem.text
                );
                if (itemIndex !== -1) {
                  this.selectedLegendItem.splice(itemIndex, 1);
                }
              } else {
                ci.show(index);
                legendItem.hidden = false;
                if (this.selectedLegendItem.indexOf(legendItem.text) === -1) {
                  this.selectedLegendItem.push(legendItem.text);
                }
              }
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {},
      },
      selectedLegendItem: [],
    };
  },

  mounted() {
    window.addEventListener("beforeunload", this.handleBeforeunload);
    this.selectedLegendItem = store.getters.showDatasetItems;
  },

  beforeDestroy() {
    store.commit("setShowDatasetItems", this.selectedLegendItem);
    window.removeEventListener("beforeunload", this.handleBeforeunload);
  },

  watch: {
    // ラベルデータの変更を監視
    labels() {
      // チャートを更新
      const chart = ChartJS.getChart("bar-chart");
      chart.data.labels = this.labels;
      chart.update();
    },

    // データセットの変更を監視
    datasets() {
      // Ｘ軸の設定
      this.chartOptions.scales = {
        x: {
          display: true,
          ticks: {
            callback: function (tick) {
              const label = this.getLabelForValue(tick);
              if (label.length <= 10) {
                return label;
              } else {
                return label.substring(0, 10) + " " + label.substring(11, 16);
              }
            },
          },
        },
      };

      // Ｙ軸の設定（datasetの数分作成）
      let key;
      for (key in this.datasets) {
        let position = "left";
        if (this.datasets[key]["type"] === "line") {
          position = "right";
        }
        this.chartOptions.scales[this.datasets[key]["yAxisID"]] = {
          type: "linear",
          position: position,
          ticks: {
            color: this.datasets[key]["borderColor"],
          },
          display: "auto",
        };
      }

      // チャートを更新
      const chart = ChartJS.getChart("bar-chart");
      chart.data.datasets = this.datasets;
      // 凡例の表示非表示を記憶していたもので上書き
      for (key in chart.data.datasets) {
        const label = chart.data.datasets[key]["label"];
        if (this.selectedLegendItem.indexOf(label) !== -1) {
          chart.data.datasets[key]["hidden"] = false;
        }
      }
      chart.update();
    },
  },

  methods: {
    // beforeunload時に呼ばれる関数
    handleBeforeunload() {
      store.commit("setShowDatasetItems", this.selectedLegendItem);
    },

    getLabels() {
      return this.labels;
    },

    getDatasets() {
      return this.datasets;
    },
  },
};
</script>
