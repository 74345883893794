import WebApiModel from "@/apis/web_api";
import { debugLog } from "@/lib/debug_util";
import store from "@/store";
import {
  PlantControl,
  PlantFacility,
  DetailFacility,
  DetailBlock,
  DetailItem,
  DetailDisplayData,
  SummaryDisplayData,
  SummaryFacilities,
  SummaryFixedFacilities,
  SummaryFixedItem,
  SummaryFlow,
  SummaryFixedFlow,
  AlertsInfo,
  InterlockConditionItem,
  ManualControlData,
  LatestPlcAddressValue,
} from "@/types";
import {
  getSummaryFixedFacilities,
  getSummaryFixedFlows,
} from "../lib/get_summary_fixed_data";
import dayjs from "dayjs";
// utcは呼び元のVueのcreatedで参照している。
// このソースファイルではutcOffsetメソッドで不当にエラー指摘されないようimportしている。
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import utc from "dayjs/plugin/utc";
// import { getDummyAlertInfoData1 } from "../lib/get_dummy_data";

//////////////////////////////////////////////////////////////////////
// 会社プラント情報取得処理
//  ・会社プラント情報取得APIを呼び出す
//  引数：
//    (i) webApiModel           ：Vueで作成したApiModel
//    (i) company_id            ：取得対象の会社コード
//  戻り値：
//    会社プラント情報取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getCompaniesPlants(
  webApiModel: WebApiModel,
  company_id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path = "/companies/" + company_id + "/plants";
  debugLog("会社プラント情報取得API", path);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path, {})
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog("Succeeded in [GET]/companies/{company_id}/plants:", response);
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error("Failed on [GET]/companies/{company_id}/plants:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// プラントデータ表示制御情報取得処理
//  ・プラントデータ表示制御情報取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//  戻り値：
//    プラントデータ表示制御情報取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getPlantsControls(
  webApiModel: WebApiModel,
  imsi: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path = "/plants/" + imsi + "/controls";
  debugLog("プラントデータ表示制御情報取得API", path);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path, {})
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog("Succeeded in [GET]/plants/{imsi}/controls:", response);
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error("Failed on [GET]/plants/{imsi}/controls:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
        debugLog("Error status:", err.status);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// プラント設備表示制御情報取得処理
//  ・プラント設備表示制御情報取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//  戻り値：
//    プラント設備表示制御情報取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getPlantsFacilities(
  webApiModel: WebApiModel,
  imsi: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path = "/plants/" + imsi + "/facilities";
  debugLog("プラント設備表示制御情報取得API", path);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path, {})
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog("Succeeded in [GET]/plants/{imsi}/facilities:", response);
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error("Failed on [GET]/plants/{imsi}/facilities:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// プラント画面表示用データ取得処理
//  ・プラント画面表示用データ取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) start         ：取得対象とするデータの開始日時
//    (i) end           ：取得対象とするデータの終了日時
//    ※start、endは任意で、不要な場合はnullを指定する
//      現状は両方nullか両方指定のみ対応している
//  戻り値：
//    プラント画面表示用データ取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getPlantsData(
  webApiModel: WebApiModel,
  imsi: string,
  start: string | null,
  end: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  let queryParams = {};
  if (start != null && end != null) {
    queryParams = {
      queryStringParameters: {
        start: start,
        end: end,
      },
    };
  }

  const path = "/plants/" + imsi + "/data";
  debugLog("プラント画面表示用データ取得API", path);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path, queryParams)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog("Succeeded in [GET]/plants/{imsi}/data:", response);
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error("Failed on [GET]/plants/{imsi}/data:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// プラントスコアデータ取得処理
//  ・プラントスコアデータ取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) date         ：取得対象とするデータの指定日時
//    ※dateは任意で、不要な場合はnullを指定する
//  戻り値：
//    プラント画面表示用データ取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getPlantScore(
  webApiModel: WebApiModel,
  imsi: string,
  date: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  let queryParams = {};
  if (date != null) {
    queryParams = {
      queryStringParameters: {
        date: date,
      },
    };
  }

  const path = "/plants/" + imsi + "/score";
  debugLog("プラントスコア表示用データ取得API", path);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path, queryParams)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog("Succeeded in [GET]/plants/{imsi}/score:", response);
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error("Failed on [GET]/plants/{imsi}/score:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 異常アラート取得処理
//  ・異常アラート取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) date         ：取得対象とするデータの指定日時
//    ※dateは任意で、不要な場合はnullを指定する
//  戻り値：
//    プラント画面表示用データ取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getAbnormalAlert(
  webApiModel: WebApiModel,
  imsi: string,
  date: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  let queryParams = {};
  if (date != null) {
    queryParams = {
      queryStringParameters: {
        date: date,
      },
    };
  }

  const path = "/plants/" + imsi + "/abnormal-alerts";
  debugLog("プラントスコア表示用データ取得API", path);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path, queryParams)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog("Succeeded in [GET]/plants/{imsi}/abnormal-alerts:", response);
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error("Failed on [GET]/plants/{imsi}/abnormal-alerts:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 時刻オフセット付き開始終了日時作成処理
//  ・自動更新でなく日時指定での画面データ表示のためのAPIアクセスの際に、
//    プラントの時刻オフセットの付いたIS8601フォーマットの
//    開始日時と終了日時の文字列を作成して返す。
//  ・詳細版画面、モバイル版画面、サマリー版画面での使用を想定
//  ・日時指定(年月日時分)に対して開始日時と終了日時(年月日時分秒)は以下の仕様
//      終了日時 ＝ 日時指定 ＋ 59秒
//      開始日時 ＝ 日時指定 － 5分 + 00秒
//  引数：
//    ※引数なし
//  参照store内変数
//    (i)   companies_plant.time_offset ：時刻オフセット
//    (i)   onceUpdateDataTime ：日時指定(年月日時分)
//  戻り値：
//    start ：開始日時(ISO8601形式の年月日時分秒)
//    end ：終了日時(ISO8601形式の年月日時分秒)
//////////////////////////////////////////////////////////////////////
function makeStartAndEndWithTimeOffset(): [string, string] {
  let start: string | null = null;
  let end: string | null = null;

  const timeOffset: number = store.getters.companies_plant.time_offset;
  const dateTimes = store.getters.onceUpdateDataTime;
  // 分単位の時刻オフセットから「+09:00」のような文字列を作成する。
  const timeOffsetString =
    (timeOffset > 0 ? "+" : "-") +
    String(Math.abs(timeOffset) / 60).padStart(2, "0") +
    ":" +
    String(Math.abs(timeOffset) % 60).padStart(2, "0");
  // endは指定日時の末尾に秒として「:59」を追加する
  // 単に日時の文字列を指定してdayjsオブジェクトを作るとローカル時間で解釈されるため、
  // 必ず末尾に「+09:00」のような文字列を付加すること
  const endString: string =
    dateTimes.date +
    " " +
    dateTimes.hour +
    ":" +
    dateTimes.minute +
    ":59" +
    timeOffsetString;
  // 時刻オフセットを考慮したdayjsオブジェクトを作成する
  // この時も「.utcOffset(数値)」を付けないとローカル時間扱いのオブジェクトが
  // 出来てしまうので注意のこと
  const endDayJs = dayjs(endString).utcOffset(timeOffset);
  // startは指定日時の5分前とし、末尾に秒として「:00」を追加した形とするため、
  // 合計するとstartはendの5分59秒前とすればよい
  // ※年や月をまたいだりうるう日をまたいだりするケースを考えると、
  // ※dayjsオブジェクトで引き算をするのが無難
  const startDayJs = endDayJs.subtract(5, "minute").subtract(59, "second");
  end = endDayJs.format("YYYY-MM-DDTHH:mm:ssZ");
  start = startDayJs.format("YYYY-MM-DDTHH:mm:ssZ");
  debugLog("start:", start, "end:", end, "(offset):", timeOffsetString);
  return [start, end];
}

//////////////////////////////////////////////////////////////////////
// 時刻オフセット付き日時作成処理
//  ・自動更新でなく日時指定での画面データ表示のためのAPIアクセスの際に、
//    プラントの時刻オフセットの付いたIS8601フォーマットの
//    開始日時と終了日時の文字列を作成して返す。
//  ・詳細版画面、モバイル版画面、サマリー版画面での使用を想定
//  引数：
//    ※引数なし
//  参照store内変数
//    (i)   companies_plant.time_offset ：時刻オフセット
//    (i)   onceUpdateDataTime ：日時指定(年月日時分)
//  戻り値：
//    date ：開始日時(ISO8601形式の年月日時分秒)
//////////////////////////////////////////////////////////////////////
function makeDateWithTimeOffset(): string {
  let date: string | null = null;
  const timeOffset: number = store.getters.companies_plant.time_offset;
  // 分単位の時刻オフセットから「+09:00」のような文字列を作成する。
  const timeOffsetString =
    (timeOffset > 0 ? "+" : "-") +
    String(Math.abs(timeOffset) / 60).padStart(2, "0") +
    ":" +
    String(Math.abs(timeOffset) % 60).padStart(2, "0");
  // endは指定日時の末尾に秒として「:59」を追加する
  // 単に日時の文字列を指定してdayjsオブジェクトを作るとローカル時間で解釈されるため、
  // 必ず末尾に「+09:00」のような文字列を付加すること
  if (store.getters.isAutoUpdate === false) {
    const dateTimes = store.getters.onceUpdateDataTime;
    const dateString: string =
      dateTimes.date +
      " " +
      dateTimes.hour +
      ":" +
      dateTimes.minute +
      ":00" +
      timeOffsetString;
    // 時刻オフセットを考慮したdayjsオブジェクトを作成する
    // この時も「.utcOffset(数値)」を付けないとローカル時間扱いのオブジェクトが
    // 出来てしまうので注意のこと
    const dateDayJs = dayjs(dateString).utcOffset(timeOffset);
    date = dateDayJs.format("YYYY-MM-DDTHH:mm:ssZ");
    debugLog("date:", date, "(offset):", timeOffsetString);
  } else {
    // 現在時刻を取得する
    const dateDayJs = dayjs().utcOffset(timeOffset);
    date = dateDayJs.format("YYYY-MM-DDTHH:mm:ssZ");
  }
  return date;
}

//////////////////////////////////////////////////////////////////////
// 過去データのアラートをアラート詳細形式に変換する
//   ・アラートレコメンド追加前の過去データでアラートがある場合は、
//     alertsにアラートありで、alerts_infoにアラートなしとなる。
//     アラート一覧の表示を共通にするため、alertsをalerts_infoの形式で格納する。
//     その場合、plc_addressは空欄、alert_display_colorはnullで格納する。
//   ・アラートレコメンド追加後のalerts_infoにアラートがあるときは何もしない。
//  引数：
//    (i)   alerts      ：過去データ形式のアラート
//    (i/o) alerts_info ：新データ形式のアラート
//  戻り値：
//    なし
//////////////////////////////////////////////////////////////////////
export function convertPreviousDataFromAlertsToAlertsInfo(
  alerts: Array<string>,
  alerts_info: Array<AlertsInfo>
): Array<AlertsInfo> {
  let result_alerts_info = alerts_info;
  if (result_alerts_info == null) {
    result_alerts_info = [];
  }
  if (
    alerts != null &&
    alerts.length > 0 &&
    (alerts_info == null || alerts_info.length === 0)
  ) {
    alerts.forEach((alert: string) => {
      const alertInfo = {
        plc_address: "",
        alert_message: alert,
        alert_display_color: null,
      };
      result_alerts_info.push(alertInfo);
    });
  }
  return result_alerts_info;
}

//////////////////////////////////////////////////////////////////////
// 詳細画面表示用データ取得処理
//  ・詳細画面表示用のデータを作成する処理で、
//    内部でプラント画面表示用データ取得APIを呼び出している
//  ・PC版・モバイル版共通で使用される
//  ・関数内部でstoreに格納されている情報を参照していることに注意
//  引数：
//    (i)   webApiModel       ：Vueで作成したApiModel
//    (i/o) detailDisplayData ：ここに取得したデータが格納される
//  戻り値：
//    プラント画面表示用データ取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getDetailData(
  webApiModel: WebApiModel,
  detailDisplayData: DetailDisplayData
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  debugLog("getDisplayData");
  let start: string | null = null;
  let end: string | null = null;
  if (store.getters.isAutoUpdate === false) {
    // 日時指定のときは、startとendのパラメータを作成する
    [start, end] = makeStartAndEndWithTimeOffset();
  }
  const imsi = store.getters.companies_plant.imsi;
  debugLog("imsi:", imsi);
  debugLog("start/end:", start, end);

  /////////////////////////////////////////////////////////////
  // プラント画面表示用データ取得処理
  /////////////////////////////////////////////////////////////
  // プラント画面表示用データの取得を行う
  const responsePlantsData = await getPlantsData(webApiModel, imsi, start, end);
  // エラーの場合は中断する
  if (responsePlantsData === undefined) {
    return undefined;
  }
  // 成功してもデータ件数が0件の場合は中断する
  if (responsePlantsData.records == null) {
    return undefined;
  }
  const plantsData = responsePlantsData.records[0];
  debugLog(plantsData);

  /////////////////////////////////////////////////////////////
  // 画面表示用データを作成する
  /////////////////////////////////////////////////////////////
  // 異常アラートを取得
  const responseAbnormalAlert = await getAbnormalAlert(
    webApiModel,
    imsi,
    makeDateWithTimeOffset()
  );

  // アラート情報を作成
  detailDisplayData.alerts = plantsData["alert_array"];
  detailDisplayData.alerts_info = plantsData["alert_info_array"];

  if (responseAbnormalAlert.records) {
    for (const record of responseAbnormalAlert.records) {
      detailDisplayData.alerts_info.unshift(record);
    }
  }
  // detailDisplayData.alerts_info = getDummyAlertInfoData1();
  // 過去データのアラートをアラート詳細形式に変換する
  detailDisplayData.alerts_info = convertPreviousDataFromAlertsToAlertsInfo(
    detailDisplayData.alerts,
    detailDisplayData.alerts_info
  );

  // 設備データの作成
  detailDisplayData.facilities = [] as Array<DetailFacility>;
  // プラント設備表示制御情報分処理を繰り返す
  const plant_facility_list = store.getters.plant_facility_list;
  debugLog("plant_facility_list:", plant_facility_list);
  plant_facility_list.forEach((plantFacility: PlantFacility) => {
    // メインブロックのデータを作成
    const mainBlock: DetailBlock = {} as DetailBlock;
    mainBlock.title = plantFacility.facility_name_main;
    mainBlock.icon = plantFacility.facility_icon;
    mainBlock.order = plantFacility.order_of_facility;
    // サブブロックのデータを作成
    const subBlock: DetailBlock = {} as DetailBlock;
    subBlock.title = plantFacility.facility_name_sub;
    // 設備データ作成用変数を作成
    const facility: DetailFacility = {} as DetailFacility;
    const mainItems = [] as Array<DetailItem>;
    const subItems = [] as Array<DetailItem>;
    // プラントデータ表示制御情報分処理を行う
    const plant_control_list = store.getters.plant_control_list;
    plant_control_list.forEach((plantControl: PlantControl) => {
      // 画面表示対象で設備IDが一致するものを対象に処理する
      if (
        plantControl.display_required === true &&
        plantFacility.facility_id === plantControl.facility_id
      ) {
        // メイン・サブ共通でアイテムの設定を行う
        const item: DetailItem = {} as DetailItem;
        item.plcAddress = plantControl.plc_address;
        item.text = plantControl.display_name;
        item.icon = plantControl.icon;
        item.unit = plantControl.unit_of_data;
        item.isControllable = plantControl.is_controllable;
        // データ種別がセンサー値と稼働状況で設定するアイテムが異なる
        if (plantControl.data_type === "sensor") {
          item.onoff = false;
        } else if (plantControl.data_type === "operation") {
          item.onoff = true;
          item.iconOn = plantControl.icon_for_true;
          item.iconOff = plantControl.icon_for_false;
          item.textOn = plantControl.display_name_for_true;
          item.textOff = plantControl.display_name_for_false;
          item.colorOn = plantControl.color_for_true;
          item.colorOff = plantControl.color_for_false;
        }
        // プラント画面表示用データから対象アイテムの値を取り出す
        item.value = plantsData[plantControl.display_name_en];
        item.order = plantControl.order_in_facility;
        // メインまたはサブにアイテムを追加する
        if (plantControl.facility_type === 1) {
          mainItems.push(item);
        } else if (plantControl.facility_type === 2) {
          subItems.push(item);
        }
      }
    });
    // メインとサブのアイテムを表示順にソートする
    mainItems.sort((a, b) => a.order - b.order);
    subItems.sort((a, b) => a.order - b.order);
    // メインとサブのブロックとアイテムを設備データに追加する
    mainBlock.items = mainItems;
    facility.main = mainBlock;
    subBlock.items = subItems;
    facility.sub = subBlock;
    detailDisplayData.facilities.push(facility);
  });
  // 設備データを表示順にソートする
  detailDisplayData.facilities.sort((a, b) => a.main.order - b.main.order);

  // 手動制御画面の最新状況表示用データ作成しセッションストレージに格納する
  setManualControlLatestData(detailDisplayData.alerts_info, plantsData);

  // 記録日時を設定する
  detailDisplayData.record_time = plantsData.record_time;
  debugLog("record_time:", detailDisplayData.record_time);

  return responsePlantsData;
}

//////////////////////////////////////////////////////////////////////
// サマリー版画面表示用各ブロックアイテム作成処理
// ・サマリー版画面の表示用データの作成において、そのデータ階層の中の
//   SummarySingleTank内のDetailItemの配列の要素1個を作成する
//   ・以下3種類の元ネタを組み合わせて、DetailItemの配列の要素1個としてまとめる
//     ・引数のsummaryItemFixed(固定部分)
//     ・マスタデータ内の情報
//         (画面表示用データテーブルからIMSIと項目名英字に合致する1レコード)
//     ・APIからのレスポンス内のデータ
//  引数：
//    (i)   plantsData ：APIからのレスポンス内のrecords配列の先頭要素
//    (i)   summaryItemFixed ：各ブロックのアイテムの固定部分のみの設定情報
//  参照store内変数
//    (i)   plant_control_list ：画面表示用データテーブルからIMSIに合致する複数レコード
//  戻り値：
//    item ：SummarySingleTank内のDetailItemの配列の要素1個
//////////////////////////////////////////////////////////////////////
function makeSummaryItem(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plantsData: any,
  summaryItemFixed: SummaryFixedItem
): DetailItem {
  const plant_control_list = store.getters.plant_control_list;
  const display_name_en = summaryItemFixed.display_name_en;

  const plantControl = plant_control_list.find(
    (el: PlantControl) => el.display_name_en === display_name_en
  );
  // debugLog("plantControl:", plantControl);

  // 画面表示用データテーブルに対象のプラントデータ表示制御情報がなければ、
  // 空欄表示させるために枠のみの表示にする。
  if (plantControl == null) {
    const item: DetailItem = {
      plcAddress: "",
      text: summaryItemFixed.text,
      onoff: summaryItemFixed.default_data_type !== "sensor",
      icon: summaryItemFixed.icon,
      value: summaryItemFixed.default_data_type !== "sensor" ? false : "",
      unit: "",
      iconOn: "",
      iconOff: "mdi-blank",
      textOn: "",
      textOff: "",
      colorOn: "",
      colorOff: "#8F8985",
      isControllable: false,
      order: 0, // unused for Summary
    };
    return item;
  }

  // 画面表示用データテーブルに対象のプラントデータ表示制御情報があれば、
  // そのデータを表示する。
  const item: DetailItem = {
    plcAddress: plantControl.plc_address,
    text: summaryItemFixed.text,
    onoff: plantControl.data_type !== "sensor",
    icon: summaryItemFixed.icon,
    value: plantsData[display_name_en],
    unit: plantControl.unit_of_data,
    iconOn: plantControl.icon_for_true,
    iconOff: plantControl.icon_for_false,
    textOn: plantControl.display_name_for_true,
    textOff: plantControl.display_name_for_false,
    colorOn: plantControl.color_for_true,
    colorOff: plantControl.color_for_false,
    isControllable: plantControl.is_controllable,
    order: 0, // unused for Summary
  };

  return item;
}

//////////////////////////////////////////////////////////////////////
// サマリー版画面表示用設備データ(複数のSingleTank用の設定を含む)作成処理
// ・サマリー版画面の表示用データの作成において、そのデータ階層の中の
//   SummaryDisplayData内のSummaryFacilitiesを作成する
//   ・以下2種類の元ネタを組み合わせて、
//     SummaryDisplayData内のSummaryFacilitiesとしてまとめる
//     ・getSummaryFixedFacilities()を呼んで得た
//       設備データの固定部分のみ(複数のSingleTank用の設定を含む)
//     ・makeSummaryItem()を繰り返し呼んで得た
//       SummarySingleTank内のDetailItemの配列の要素群
//   ※summaryFixedFacilitiesとSummaryFixedItemを扱う際に、
//     構造体の要素を文字列のキーを用いて参照している
//     (index signatureを用いている)点に注意
//  引数：
//    (i)   plantsData ：APIからのレスポンス内のrecords配列の先頭要素
//  参照store内変数
//    ※参照なし
//  戻り値：
//    facilities ：SummaryDisplayData内のSummaryFacilities
//////////////////////////////////////////////////////////////////////
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function makeSummaryFacilities(plantsData: any): SummaryFacilities {
  const summaryFixedFacilities: SummaryFixedFacilities =
    getSummaryFixedFacilities();
  const facility_list: Array<string> = Object.keys(summaryFixedFacilities);
  // debugLog("facility_list:", facility_list);

  const facilities: SummaryFacilities = {} as SummaryFacilities;
  facility_list.forEach((facility: string) => {
    const fixedItems: Array<SummaryFixedItem> =
      summaryFixedFacilities[facility].items;
    const items: Array<DetailItem> = [] as Array<DetailItem>;
    fixedItems.forEach((fixedItem: SummaryFixedItem) => {
      const detailItem = makeSummaryItem(plantsData, fixedItem);
      items.push(detailItem);
    });

    facilities[facility] = {
      title: summaryFixedFacilities[facility].title,
      icon: summaryFixedFacilities[facility].icon,
      items: items,
    };
  });
  return facilities;
}

//////////////////////////////////////////////////////////////////////
// サマリー版画面表示用Flowデータ作成処理
// ・サマリー版画面の表示用データの作成において、そのデータ階層の中の
//   SummaryDisplayData内のSummaryFlowの配列の要素群を作成する
//   ・以下3種類の元ネタを組み合わせて、SummaryFlowの配列の要素群としてまとめる
//     ・getSummaryFixedFlows()を呼んで得たFlowデータの固定部分のみの配列
//     ・マスタデータ内の情報
//         (画面表示用データテーブルからIMSIと項目名英字に合致する1レコード)
//     ・APIからのレスポンス内のデータ
//  引数：
//    (i)   plantsData ：APIからのレスポンス内のrecords配列の先頭要素
//  参照store内変数
//    (i)   plant_control_list ：画面表示用データテーブルからIMSIに合致する複数レコード
//  戻り値：
//    flows ：SummaryFlowの配列
//////////////////////////////////////////////////////////////////////
function makeSummaryFlows(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plantsData: any
): Array<SummaryFlow> {
  const summaryFixedFlows: Array<SummaryFixedFlow> = getSummaryFixedFlows();
  const plant_control_list = store.getters.plant_control_list;

  const flows: Array<SummaryFlow> = [] as Array<SummaryFlow>;
  summaryFixedFlows.forEach((summaryFixedFlow: SummaryFixedFlow) => {
    const display_name_en = summaryFixedFlow.display_name_en;

    const plantControl = plant_control_list.find(
      (el: PlantControl) => el.display_name_en === display_name_en
    );
    // debugLog("plantControl:", plantControl);

    if (plantControl == null) {
      // 画面表示用データテーブルに対象のプラントデータ表示制御情報がなければ、
      // 空欄表示させるために枠のみの表示にする。
      const flow: SummaryFlow = {
        borderColor: summaryFixedFlow.borderColor,
        arrowColor: summaryFixedFlow.arrowColor,
        backgroundColor: summaryFixedFlow.backgroundColor,
        arrowDirection: summaryFixedFlow.arrowDirection,
        icon: summaryFixedFlow.icon,
        iconColor: summaryFixedFlow.iconColor,
        message: summaryFixedFlow.message,
        displayData: "",
        unit: "",
      };
      flows.push(flow);
    } else {
      // 画面表示用データテーブルに対象のプラントデータ表示制御情報があれば、
      // そのデータを表示する。
      const flow: SummaryFlow = {
        borderColor: summaryFixedFlow.borderColor,
        arrowColor: summaryFixedFlow.arrowColor,
        backgroundColor: summaryFixedFlow.backgroundColor,
        arrowDirection: summaryFixedFlow.arrowDirection,
        icon: summaryFixedFlow.icon,
        iconColor: summaryFixedFlow.iconColor,
        message: summaryFixedFlow.message,
        displayData: plantsData[display_name_en],
        unit: plantControl.unit_of_data,
      };
      // debugLog("SummaryFlow:", flow);
      flows.push(flow);
    }
  });
  return flows;
}

//////////////////////////////////////////////////////////////////////
// サマリー版画面表示用データ取得処理
//  ・サマリー版画面表示用のデータを作成する処理で、
//    内部でプラント画面表示用データ取得APIを呼び出している
//  ・サマリー版画面専用で使用される
//  ・関数内部でstoreに格納されている情報を参照していることに注意
//  引数：
//    (i)   webApiModel       ：Vueで作成したApiModel
//    (i/o) summaryDisplayData ：ここに取得したデータが格納される
//  戻り値：
//    プラント画面表示用データ取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getSummaryData(
  webApiModel: WebApiModel,
  summaryDisplayData: SummaryDisplayData
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  debugLog("getSummaryData");
  let start: string | null = null;
  let end: string | null = null;
  if (store.getters.isAutoUpdate === false) {
    // 日時指定のときは、startとendのパラメータを作成する
    [start, end] = makeStartAndEndWithTimeOffset();
  }
  const imsi = store.getters.companies_plant.imsi;
  debugLog("imsi:", imsi);
  debugLog("start/end:", start, end);

  /////////////////////////////////////////////////////////////
  // プラント画面表示用データ取得処理
  /////////////////////////////////////////////////////////////
  // プラント画面表示用データの取得を行う
  const responsePlantsData = await getPlantsData(webApiModel, imsi, start, end);
  // エラーの場合は中断する
  if (responsePlantsData === undefined) {
    return undefined;
  }
  // 成功してもデータ件数が0件の場合は中断する
  if (responsePlantsData.records == null) {
    return undefined;
  }
  const plantsData = responsePlantsData.records[0];
  debugLog(plantsData);

  /////////////////////////////////////////////////////////////
  // 画面表示用データを作成する
  /////////////////////////////////////////////////////////////
  // アラート情報を作成
  summaryDisplayData.alerts = plantsData["alert_array"];
  summaryDisplayData.alerts_info = plantsData["alert_info_array"];
  // 異常アラートを取得
  const responseAbnormalAlert = await getAbnormalAlert(
    webApiModel,
    imsi,
    makeDateWithTimeOffset()
  );

  if (responseAbnormalAlert.records) {
    for (const record of responseAbnormalAlert.records) {
      summaryDisplayData.alerts_info.unshift(record);
    }
  }
  // summaryDisplayData.alerts_info = getDummyAlertInfoData1();
  // 過去データのアラートをアラート詳細形式に変換する
  summaryDisplayData.alerts_info = convertPreviousDataFromAlertsToAlertsInfo(
    summaryDisplayData.alerts,
    summaryDisplayData.alerts_info
  );

  // 設備データの作成
  summaryDisplayData.facilities = {} as SummaryFacilities;
  summaryDisplayData.facilities = makeSummaryFacilities(plantsData);

  // Flowデータの作成
  summaryDisplayData.flows = [] as Array<SummaryFlow>;
  summaryDisplayData.flows = makeSummaryFlows(plantsData);

  // 手動制御画面の最新状況表示用データ作成しセッションストレージに格納する
  setManualControlLatestData(summaryDisplayData.alerts_info, plantsData);

  // 記録日時を設定する ※初回描画のフラグ代わりに、構造体作成処理の最後に値を設定する
  summaryDisplayData.record_time = plantsData.record_time;
  debugLog("record_time:", summaryDisplayData.record_time);

  return responsePlantsData;
}

//////////////////////////////////////////////////////////////////////
// 画面表示用スコアデータ取得処理
//  ・画面表示用のスコアデータを作成する処理で、
//    内部でプラント画面表示用データ取得APIを呼び出している
//  ・PC版・モバイル版共通で使用される
//  ・関数内部でstoreに格納されている情報を参照していることに注意
//  引数：
//    (i)   webApiModel       ：Vueで作成したApiModel
//    (i/o) displayData ：ここに取得したデータが格納される
//  戻り値：
//    プラント画面表示用データ取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////

export async function getDisplayScore(
  webApiModel: WebApiModel,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayData: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  debugLog("getDisplayScore");
  let date: string | null = null;
  // 日時指定のときは、dateのパラメータを作成する
  date = makeDateWithTimeOffset();

  const imsi = store.getters.companies_plant.imsi;
  debugLog("imsi:", imsi);
  debugLog("date:", date);

  /////////////////////////////////////////////////////////////
  // プラントスコア表示用データ取得処理
  /////////////////////////////////////////////////////////////
  // プラントスコア表示用データの取得を行う
  const responseScoreData = await getPlantScore(webApiModel, imsi, date);
  // エラーの場合は中断する
  if (responseScoreData === undefined) {
    return undefined;
  }
  // 成功してもデータ件数が0件の場合は中断する
  if (!responseScoreData.records) {
    displayData.scores = [];
    return undefined;
  }
  const scoreData = responseScoreData.records;
  debugLog(scoreData);
  // スコア表示用データを作成
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scoreList: any[] = [];

  // Storeから制御データ取得
  const plantControlList: PlantControl[] = store.getters.plant_control_list;
  // プラント制御リストと一致するデータを抽出して格納
  plantControlList.forEach((plantControl: PlantControl) => {
    if (plantControl.plc_address in scoreData) {
      const score = {
        plcAddress: plantControl.plc_address,
        displayName_full: plantControl.display_name_full,
        icon: plantControl.icon,
        score: scoreData[plantControl.plc_address as keyof typeof scoreData],
        orderInFacility: plantControl.order_in_facility,
      };
      scoreList.push(score);
    }
  });
  // Storeから設備データ取得
  const plantFacilityList: PlantFacility[] = store.getters.plant_facility_list;
  // scoresをfasのfacility_name_mainで昇順にソート
  scoreList.sort((a, b) => {
    const facilityNameA = a.score.facility_name;
    const facilityNameB = b.score.facility_name;

    // facility_name_mainに対応するorder_of_facilityを取得
    const orderA =
      plantFacilityList.find(
        (item) => item.facility_name_main === facilityNameA
      )?.order_of_facility || 0;
    const orderB =
      plantFacilityList.find(
        (item) => item.facility_name_main === facilityNameB
      )?.order_of_facility || 0;

    // order_of_facilityで比較し、同じ場合はplant_control_listのorder_in_facilityで比較
    if (orderA === orderB) {
      return a.orderInFacility - b.orderInFacility;
    } else {
      return orderA - orderB;
    }
  });

  // 先頭にプラント全体スコアを格納
  scoreList.unshift({ overallPlantScore: scoreData["overall_plant_score"] });
  debugLog("スコア制御リスト", scoreList);

  displayData.scores = scoreList;
  return responseScoreData;
}

//////////////////////////////////////////////////////////////////////
// 画面表示用設定データ取得処理
//////////////////////////////////////////////////////////////////////

export async function getSettingData(
  webApiModel: WebApiModel,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayData: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  debugLog("getSettingData");
  const imsi = store.getters.companies_plant.imsi;

  const responseAutomaticSequenceControlParameterSetting =
    await getAutomaticSequenceControlParameterSetting(webApiModel, imsi);

  const responseRawMaterialInputScheduleSetting =
    await getRawMaterialInputScheduleSetting(webApiModel, imsi);

  // エラーの場合は中断する
  if (
    !responseAutomaticSequenceControlParameterSetting ||
    !responseRawMaterialInputScheduleSetting
  ) {
    debugLog("設定データ取得APIのレスポンスデータがundefined");
    return undefined;
  }
  // 成功してもデータ件数が0件の場合はデバッグログへ出力
  if (
    !responseAutomaticSequenceControlParameterSetting.records &&
    !responseRawMaterialInputScheduleSetting.records
  ) {
    debugLog("設定データ件数が0件");
  }
  const convertStartTimeToLocalTime = (records: any[]): any[] => {
    // 開始時刻をローカル時刻に変換
    records.forEach((record: any) => {
      const utcHour = parseInt(record.start_time.slice(0, 2), 10);
      const utcMinute = record.start_time.slice(2);
      const timeOffsetMin = store.getters.companies_plant.time_offset;
      const timeOffsetHour = Math.floor(timeOffsetMin / 60);
      let jstHour = utcHour + timeOffsetHour;
      if (jstHour >= 24) {
        jstHour -= 24;
      }
      record.start_time = `${jstHour.toString().padStart(2, "0")}${utcMinute}`;
    });
    return records;
  };

  const settingData = {
    automaticSequenceControlParameterSetting:
      responseAutomaticSequenceControlParameterSetting.records || [],
    rawMaterialInputScheduleSetting:
      responseRawMaterialInputScheduleSetting.records
        ? convertStartTimeToLocalTime(
            responseRawMaterialInputScheduleSetting.records
          )
        : [],
  };
  displayData.setting = settingData;
  return responseAutomaticSequenceControlParameterSetting;
}

//////////////////////////////////////////////////////////////////////
// 生データ履歴取得用データ取得処理
//  ・生データ履歴取得用データ取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) start         ：取得対象とするデータの開始日時
//    (i) end           ：取得対象とするデータの終了日時
//    ※start、endは任意で、不要な場合はnullを指定する
//      どちらかの入力は必須
//  戻り値：
//    生データ履歴取得用データ取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getRawLogData(
  webApiModel: WebApiModel,
  imsi: string,
  start: string | null,
  end: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  let queryParams = { queryStringParameters: {} };
  if (start != null && end != null) {
    queryParams = {
      queryStringParameters: {
        start: start,
        end: end,
      },
    };
  }
  if (end === null) {
    queryParams = {
      queryStringParameters: {
        start: start,
      },
    };
  }
  if (start === null) {
    queryParams = {
      queryStringParameters: {
        end: end,
      },
    };
  }

  const path = "/plants/" + imsi + "/batch-data";
  debugLog("生データ履歴取得用データ取得API", path);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path, queryParams)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog("Succeeded in [GET]/plants/{imsi}/batch-data:", response);
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error("Failed on [GET]/plants/{imsi}/batch-data:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 日報データ取得処理
//  ・プラント日報取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) start         ：取得対象とするデータの開始日時
//    (i) end           ：取得対象とするデータの終了日時
//    ※start、endは任意で、不要な場合はnullを指定する
//      現状は全てnullか全て指定のみ対応している
//  戻り値：
//    プラント日報取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getDailyReportLogData(
  webApiModel: WebApiModel,
  imsi: string,
  start: string | null,
  end: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  let queryParams = { queryStringParameters: {} };
  if (start != null && end != null) {
    queryParams = {
      queryStringParameters: {
        start: start,
        end: end,
      },
    };
  }
  if (end === null) {
    queryParams = {
      queryStringParameters: {
        start: start,
      },
    };
  }
  if (start === null) {
    queryParams = {
      queryStringParameters: {
        end: end,
      },
    };
  }

  const path = "/plants/" + imsi + "/reports";
  debugLog("プラント日報取得API", path);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path, queryParams)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog("Succeeded in [GET]/plants/{imsi}/reports:", response);
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error("Failed on [GET]/plants/{imsi}/reports:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 運転履歴データ取得処理
//  ・プラント運転履歴取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) start         ：取得対象とするデータの開始日時
//    (i) end           ：取得対象とするデータの終了日時
//    ※start、endは任意で、不要な場合はnullを指定する
//      現状は全てnullか全て指定のみ対応している
//  戻り値：
//    プラント運転履歴取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getOperationLogData(
  webApiModel: WebApiModel,
  imsi: string,
  start: string | null,
  end: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  let queryParams = { queryStringParameters: {} };
  if (start != null && end != null) {
    queryParams = {
      queryStringParameters: {
        start: start,
        end: end,
      },
    };
  }
  if (end === null) {
    queryParams = {
      queryStringParameters: {
        start: start,
      },
    };
  }
  if (start === null) {
    queryParams = {
      queryStringParameters: {
        end: end,
      },
    };
  }

  const path = "/plants/" + imsi + "/operations";
  debugLog("プラント運転履歴取得API", path, queryParams);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path, queryParams)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog("Succeeded in [GET]/plants/{imsi}/operations:", response);
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error("Failed on [GET]/plants/{imsi}/operations:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// アラート履歴データ取得処理
//  ・プラントアラート履歴取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) start         ：取得対象とするデータの開始日時
//    (i) end           ：取得対象とするデータの終了日時
//    ※start、endは任意で、不要な場合はnullを指定する
//      現状は全てnullか全て指定のみ対応している
//  戻り値：
//    プラントアラート履歴取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getAlertLogData(
  webApiModel: WebApiModel,
  imsi: string,
  start: string | null,
  end: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  let queryParams = { queryStringParameters: {} };
  if (start != null && end != null) {
    queryParams = {
      queryStringParameters: {
        start: start,
        end: end,
      },
    };
  }
  if (end === null) {
    queryParams = {
      queryStringParameters: {
        start: start,
      },
    };
  }
  if (start === null) {
    queryParams = {
      queryStringParameters: {
        end: end,
      },
    };
  }
  const path = "/plants/" + imsi + "/alerts";
  debugLog("プラントアラート履歴取得API", path, queryParams);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path, queryParams)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog("Succeeded in [GET]/plants/{imsi}/alerts:", response);
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error("Failed on [GET]/plants/{imsi}/alerts:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// アラートレコメンド取得処理
//  ・プラントアラートレコメンド取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) plc_address   ：取得対象のplc_address
//    ※plc_addressは任意で、不要な場合はnullを指定する
//  戻り値：
//    プラントアラートレコメンド取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getPlantAlertRecommend(
  webApiModel: WebApiModel,
  imsi: string,
  plc_address: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  let queryParams = { queryStringParameters: {} };
  if (plc_address != null) {
    queryParams = {
      queryStringParameters: {
        plc_address: plc_address,
      },
    };
  }

  const path = "/plants/" + imsi + "/alert-recommend";
  debugLog("プラントアラートレコメンド取得API", path, queryParams);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path, queryParams)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog("Succeeded in [GET]/plants/{imsi}/alert-recommend:", response);
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error("Failed on [GET]/plants/{imsi}/alert-recommend:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

export async function getGraphData(
  webApiModel: WebApiModel
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  debugLog("getGraphData");
  let specifiedTime: string | null = null;
  if (store.getters.isAutoUpdate === false) {
    let start: string | null = null;
    let end: string | null = null;
    // 日時指定のときは、startとendのパラメータを作成する
    [start, end] = makeStartAndEndWithTimeOffset();
    debugLog("start/end:", start, end);
    specifiedTime = end;
  }
  const imsi = store.getters.companies_plant.imsi;
  debugLog("imsi:", imsi);

  /////////////////////////////////////////////////////////////
  // プラントグラフデータ取得処理
  /////////////////////////////////////////////////////////////
  // プラントグラフデータの取得を行う
  const response = await getPlantGraphData(webApiModel, imsi, specifiedTime);
  // エラーの場合は中断する
  if (response === undefined) {
    return undefined;
  }
  // 成功してもデータ件数が0件の場合は中断する
  if (response.records == null) {
    return undefined;
  }
  return response.records;
}

//////////////////////////////////////////////////////////////////////
// プラントグラフデータ取得処理
//  ・プラントグラフデータ取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) specifiedTime ：指定時刻
//    ※plc_addressは任意で、不要な場合はnullを指定する
//  戻り値：
//    プラントグラフデータ取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getPlantGraphData(
  webApiModel: WebApiModel,
  imsi: string,
  specifiedTime: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  let queryParams = { queryStringParameters: {} };
  if (specifiedTime != null) {
    queryParams = {
      queryStringParameters: {
        specified_time: specifiedTime,
      },
    };
  }

  const path = "/plants/" + imsi + "/graph-data";
  debugLog("プラントグラフデータ取得API", path, queryParams);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path, queryParams)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog("Succeeded in [GET]/plants/{imsi}/graph-data:", response);
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error("Failed on [GET]/plants/{imsi}/graph-data:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 制御管理情報取得処理
//  ・MORATGW制御管理情報取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) equipmentId   ：MORAT GW キー
//  戻り値：
//    制御管理情報取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getRemoteControlSetting(
  webApiModel: WebApiModel,
  imsi: string,
  equipmentId: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path =
    "/plants/" + imsi + "/equipments/" + equipmentId + "/control-setting";
  debugLog("制御管理情報取得API", path);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog(
        "Succeeded in [GET]/plants/{imsi}/equipments/{equipment_id}/control-setting:",
        response
      );
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error(
        "Failed on [GET]/plants/{imsi}/equipments/{equipment_id}/control-setting:",
        err
      );
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 運転または停止時のインターロック条件の画面用アイテムを作成
//////////////////////////////////////////////////////////////////////
function makeInterlockConditionItems(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resConditions: any,
  conditionItems: Array<InterlockConditionItem>
): void {
  // Storeから制御データ取得
  const plantControlList = store.getters.plant_control_list;
  for (const plcAddress in resConditions) {
    let text = "";
    let conditionText = "";
    // 手動制御画面の最新状況と成立/不成立の表示用に以下を追加
    let dataType: string | undefined = undefined;
    let value: number | boolean | string | undefined = undefined;
    let judgmentType: string | undefined = undefined;
    let displayNameForTrue: string | undefined = undefined;
    let displayNameForFalse: string | undefined = undefined;
    let unitOfData: string | undefined = undefined;
    let order = Number.MAX_SAFE_INTEGER;
    plantControlList.forEach((plantControl: PlantControl) => {
      if (plcAddress === plantControl.plc_address) {
        text = plantControl.display_name_full;
        order = plantControl.interlock_conditions_priority;
        const resCondition = resConditions[plcAddress];
        if (plantControl.data_type === "operation") {
          dataType = "operation";
          if (resCondition["EQUAL"] != null) {
            judgmentType = "EQUAL";
            displayNameForTrue = plantControl.display_name_for_true;
            displayNameForFalse = plantControl.display_name_for_false;
            value = resCondition["EQUAL"];
            if (value) {
              // 成立/不成立判定ために値はbool値に変換
              value = true;
              conditionText = plantControl.display_name_for_true;
            } else {
              // 成立/不成立判定ために値はbool値に変換
              value = false;
              conditionText = plantControl.display_name_for_false;
            }
          }
        } else if (plantControl.data_type === "alert") {
          dataType = "alert";
          if (resCondition["EQUAL"] != null) {
            judgmentType = "EQUAL";
            value = resCondition["EQUAL"];
            if (value) {
              // 成立/不成立判定ために値はbool値に変換
              value = true;
              conditionText = "警報あり";
            } else {
              // 成立/不成立判定ために値はbool値に変換
              value = false;
              conditionText = "警報なし";
            }
          }
        } else if (plantControl.data_type === "sensor") {
          dataType = "sensor";
          unitOfData = plantControl.unit_of_data;
          if (resCondition["EQUAL"] != null) {
            judgmentType = "EQUAL";
            value = resCondition["EQUAL"];
            conditionText = value + plantControl.unit_of_data;
          } else if (resCondition["IN"] != null) {
            judgmentType = "IN";
            value = resCondition["IN"];
            conditionText = value + " のいずれか";
          } else if (resCondition["SHORT"] != null) {
            judgmentType = "SHORT";
            value = resCondition["SHORT"];
            conditionText = value + " " + plantControl.unit_of_data + " 以下";
          } else if (resCondition["OVER"] != null) {
            judgmentType = "OVER";
            value = resCondition["OVER"];
            conditionText = value + " " + plantControl.unit_of_data + " 以上";
          } else if (resCondition["TIMER_SHORT"] != null) {
            judgmentType = "TIMER_SHORT";
            value = resCondition["TIMER_SHORT"];
            conditionText =
              value + " " + plantControl.unit_of_data + " 以下(一定時間)";
          } else if (resCondition["TIMER_OVER"] != null) {
            judgmentType = "TIMER_OVER";
            value = resCondition["TIMER_OVER"];
            conditionText =
              value + " " + plantControl.unit_of_data + " 以上(一定時間)";
          } else if (resCondition["VALVE"] != null) {
            judgmentType = "VALVE";
            value = resCondition["VALVE"];
            if (value) {
              conditionText = "開";
            } else {
              conditionText = "閉";
            }
          } else if (resCondition["SPECIAL"] != null) {
            judgmentType = "SPECIAL";
            value = resCondition["SPECIAL"];
            conditionText = "特殊 " + value;
          }
        }
      }
    });
    const item: InterlockConditionItem = {
      plcAddress: plcAddress,
      text: text,
      order: order,
      conditionText: conditionText,
      dataType: dataType,
      judgmentType: judgmentType,
      value: value,
      displayNameForTrue: displayNameForTrue,
      displayNameForFalse: displayNameForFalse,
      unitOfData: unitOfData,
    };
    conditionItems.push(item);
  }
  conditionItems.sort((a, b) => a.order - b.order);
}

//////////////////////////////////////////////////////////////////////
// 手動制御データ取得処理
//  ・手動制御画面用のデータを取得する処理で、
//    内部で制御管理情報取得APIを呼び出している。
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) equipmentId   ：MORAT GW キー
//    (i/o) manualControlData ：ここに取得したデータが格納される
//  戻り値：
//    制御管理情報取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getManualControlData(
  webApiModel: WebApiModel,
  imsi: string,
  equipmentId: string | null,
  manualControlData: ManualControlData
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  /////////////////////////////////////////////////////////////
  // 制御管理情報取得
  /////////////////////////////////////////////////////////////
  const responseData = await getRemoteControlSetting(
    webApiModel,
    imsi,
    equipmentId
  );
  // エラーの場合は中断する
  if (responseData === undefined) {
    return undefined;
  }
  /////////////////////////////////////////////////////////////
  // 手動制御用データを作成する
  /////////////////////////////////////////////////////////////
  // MORATGW制御管理情報を基にデータを作成する
  const remoteControlSetting = responseData.records;
  manualControlData.imsi = remoteControlSetting["imsi"];
  manualControlData.equipmentId = remoteControlSetting["equipment_id"];
  manualControlData.run_entry_value = remoteControlSetting["run_entry_value"];
  manualControlData.stop_entry_value = remoteControlSetting["stop_entry_value"];
  const runItem = [] as Array<InterlockConditionItem>;
  const stopItem = [] as Array<InterlockConditionItem>;
  const conditions = remoteControlSetting["interlock_conditions"];
  if (conditions) {
    const run = conditions["run"];
    if (run) {
      makeInterlockConditionItems(run, runItem);
    }
    const stop = conditions["stop"];
    if (stop) {
      makeInterlockConditionItems(stop, stopItem);
    }
    const conditionItem = {
      run: runItem,
      stop: stopItem,
    };
    manualControlData.interlockConditions = conditionItem;
  }
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 手動制御画面用の最新データを設定
//  ・手動制御画面用の最新状況のデータをセッションストレージに格納する。
//  引数：
//    (i) alertsInfo    ：アラートの発生情報
//    (i) plantsData    ：プラント画面表示用データ
//  戻り値：
//    なし
//////////////////////////////////////////////////////////////////////
function setManualControlLatestData(
  alertsInfo: Array<AlertsInfo>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  plantsData: any
) {
  // 手動制御画面の最新状況表示用データ(アラート)を作成
  const latestValues = [] as Array<LatestPlcAddressValue>;
  alertsInfo.forEach((alertInfo: AlertsInfo) => {
    if (alertInfo.plc_address.length > 0) {
      const latestValue: LatestPlcAddressValue = {} as LatestPlcAddressValue;
      latestValue.plcAddress = alertInfo.plc_address;
      latestValue.value = true;
      latestValues.push(latestValue);
    }
  });

  // 手動制御画面の最新状況表示用データ(センサー、オペレーション)を作成
  const plant_control_list = store.getters.plant_control_list;
  for (let i = 0; i < plant_control_list.length; i++) {
    const plantControl = plant_control_list[i];
    if (plantControl.data_type === "alert") {
      continue;
    }
    const latestValue: LatestPlcAddressValue = {} as LatestPlcAddressValue;
    latestValue.plcAddress = plantControl.plc_address;
    latestValue.value = plantsData[plantControl.display_name_en];
    latestValues.push(latestValue);
  }

  // 手動制御画面の最新状況表示用データをセッションストレージに格納する
  store.commit("setLatestValue", latestValues);

  // 自動運転中かどうかをセッションストレージに格納する
  let isAutomation = false;
  for (let i = 0; i < latestValues.length; i++) {
    if (
      latestValues[i].plcAddress === "M_AUTOMATION_OUTPUT" &&
      latestValues[i].value === true
    ) {
      isAutomation = true;
      break;
    }
  }
  store.commit("setIsAutomation", isAutomation);

  // 非常停止中かどうかをセッションストレージに格納する
  let isEmergencyStop = false;
  for (let i = 0; i < latestValues.length; i++) {
    if (
      latestValues[i].plcAddress === "M_EMERGENCY_STOP_OUTPUT" ||
      latestValues[i].plcAddress === "M_EMERGENCY_STOP_INPUT"
    ) {
      if (latestValues[i].value === true) {
        isEmergencyStop = true;
        break;
      }
    }
  }
  store.commit("setIsEmergencyStop", isEmergencyStop);
}

//////////////////////////////////////////////////////////////////////
// 遠隔制御個別機器運転開始処理
//  ・遠隔制御個別機器運転停止APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) equipmentId   ：MORAT GW キー
//  戻り値：
//    遠隔制御個別機器運転開始APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function remoteControlIndividualStart(
  webApiModel: WebApiModel,
  imsi: string,
  equipmentId: string | null,
  forceExecution: boolean,
  getTriggeredTime: string,
  comment: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path = "/plants/" + imsi + "/equipments/" + equipmentId + "/start";
  debugLog("遠隔制御個別機器運転開始API", path);

  const payload = {
    body: {
      force_execution: forceExecution,
      triggered_time: getTriggeredTime,
      comment: comment,
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .put(path, payload)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog(
        "Succeeded in [PUT]/plants/{imsi}/equipments/{equipment_id}/start:",
        response
      );
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error(
        "Failed on [PUT]/plants/{imsi}/equipments/{equipment_id}/start:",
        err
      );
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 遠隔制御個別機器運転停止処理
//  ・遠隔制御個別機器運転停止APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) equipmentId   ：MORAT GW キー
//  戻り値：
//    遠隔制御個別機器運転停止APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function remoteControlIndividualStop(
  webApiModel: WebApiModel,
  imsi: string,
  equipmentId: string | null,
  forceExecution: boolean,
  getTriggeredTime: string,
  comment: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path = "/plants/" + imsi + "/equipments/" + equipmentId + "/stop";
  debugLog("遠隔制御個別機器運転停止API", path);

  const payload = {
    body: {
      force_execution: forceExecution,
      triggered_time: getTriggeredTime,
      comment: comment,
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .put(path, payload)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog(
        "Succeeded in [PUT]/plants/{imsi}/equipments/{equipment_id}/stop:",
        response
      );
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error(
        "Failed on [PUT]/plants/{imsi}/equipments/{equipment_id}/stop:",
        err
      );
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 自動運転開始処理
//  ・自動運転開始APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//  戻り値：
//    自動運転開始APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function remoteControlCollectiveStartMachine(
  webApiModel: WebApiModel,
  imsi: string,
  getTriggeredTime: string,
  comment: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path = "/plants/" + imsi + "/equipments/auto/start";
  debugLog("自動運転開始API", path);

  const payload = {
    body: {
      triggered_time: getTriggeredTime,
      comment: comment,
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .put(path, payload)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog(
        "Succeeded in [PUT]/plants/{imsi}/equipments/auto/start:",
        response
      );
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error(
        "Failed on [PUT]/plants/{imsi}/equipments/auto/start:",
        err
      );
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 自動運転停止処理
//  ・自動運転停止APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) equipmentId   ：MORAT GW キー
//  戻り値：
//    自動運転停止APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function remoteControlCollectiveStopMachine(
  webApiModel: WebApiModel,
  imsi: string,
  getTriggeredTime: string,
  comment: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path = "/plants/" + imsi + "/equipments/auto/stop";
  debugLog("自動運転停止API", path);

  const payload = {
    body: {
      triggered_time: getTriggeredTime,
      comment: comment,
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .put(path, payload)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog(
        "Succeeded in [PUT]/plants/{imsi}/equipments/auto/stop:",
        response
      );
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error("Failed on [PUT]/plants/{imsi}/equipments/auto/stop:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 改行タグ除去処理
//  ・改行タグを取り除く処理
//  引数：
//    (i) text          ：文字列
//  戻り値：
//    改行タグを取り除いた文字列
//////////////////////////////////////////////////////////////////////
function removeNewlineTag(text: string): string {
  if (typeof text !== "string") {
    return text;
  }
  return text.replace(/<br\s*\/?>/g, "");
}

//////////////////////////////////////////////////////////////////////
// 稼働ログ取得処理
//  ・稼働ログ取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//    (i) start         ：取得対象とするデータの開始日時
//    (i) end           ：取得対象とするデータの終了日時
//    ※start、endは任意で、不要な場合はnullを指定する
//      現状は全てnullか全て指定のみ対応している
//  戻り値：
//    稼働ログ取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getPlantsRemoteControlsData(
  webApiModel: WebApiModel,
  imsi: string,
  start: string | null,
  end: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  let queryParams = { queryStringParameters: {} };
  if (start != null && end != null) {
    queryParams = {
      queryStringParameters: {
        start: start,
        end: end,
      },
    };
  }
  if (end === null) {
    queryParams = {
      queryStringParameters: {
        start: start,
      },
    };
  }
  if (start === null) {
    queryParams = {
      queryStringParameters: {
        end: end,
      },
    };
  }

  const path = "/plants/" + imsi + "/equipments/remote-controls";
  debugLog("稼働ログ取得API", path, queryParams);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path, queryParams)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog(
        "Succeeded in [GET]/plants/{imsi}/equipments/remote-controls:",
        response
      );
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error(
        "Failed on [GET]/plants/{imsi}/equipments/remote-controls:",
        err
      );
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.message != null) {
        debugLog("Error message:", err.message);
      }
      return undefined;
    });

  // Storeから制御データ取得
  const displayNameOrder: { [k: string]: number } = {};
  const plant_control_list = store.getters.plant_control_list;
  for (let i = 0; i < plant_control_list.length; i++) {
    displayNameOrder[plant_control_list[i].display_name] =
      plant_control_list[i].interlock_conditions_priority;
  }

  // 制御状況チェック対象データと運転状況チェック対象データの辞書型データを
  // 表示用テキスト(改行あり)として格納する。
  if (responseData !== undefined && responseData.records !== undefined) {
    for (const record of responseData.records) {
      record["display_name"] = removeNewlineTag(record["display_name"]);
      record["interlock_check_data_text"] = convertDataStatusToLogText(
        displayNameOrder,
        record["interlock_check_data"]
      );
      record["input_check_data_text"] = convertDataStatusToLogText(
        displayNameOrder,
        record["input_check_data"]
      );
    }
  }

  return responseData;
}

// データ状況文字列をログ出力用の文字列(改行付き)に変換する
function convertDataStatusToLogText(
  displayNameOrder: { [k: string]: number },
  dataStatus: { [k: string]: string | number }
) {
  // 各データ状況と並び順を設定する
  const tempList: { text: string; order: number }[] = [];
  for (const item in dataStatus) {
    let value = dataStatus[item];
    // 各項目の並び順を設定（未定義は最後に並ぶように最大値を設定）
    let order =
      displayNameOrder[item] === undefined
        ? Number.MAX_SAFE_INTEGER
        : displayNameOrder[item];
    if (item === "データ日時") {
      value = dayjs(value).format("YYYY-MM-DD HH:mm:ss");
      // データ日時は先頭に並ぶように最小値を設定
      order = Number.MIN_SAFE_INTEGER;
    }
    tempList.push({
      text: removeNewlineTag(item) + ": " + value,
      order: order,
    });
  }
  // 並び順でソートする
  tempList.sort((a, b) => a.order - b.order);
  // 改行コード付きの文字列にする
  let logText = "";
  let index = 0;
  for (const row of tempList) {
    if (index !== 0) {
      logText += "\n";
    }
    logText += row.text;
    index++;
  }
  return logText;
}

//////////////////////////////////////////////////////////////////////
// 原材料投入スケジュール設定取得処理
//  ・原材料投入スケジュール設定取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//  戻り値：
//    原材料投入スケジュール設定取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getRawMaterialInputScheduleSetting(
  webApiModel: WebApiModel,
  imsi: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path = "/plants/" + imsi + "/equipments/auto/schedule";
  debugLog("原材料投入スケジュール設定取得API", path);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog(
        "Succeeded in [GET]/plants/{imsi}/equipments/auto/schedule:",
        response
      );
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error(
        "Failed on [GET]/plants/{imsi}/equipments/auto/schedule:",
        err
      );
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.response.data.message != null) {
        debugLog("Error message:", err.response.data.message);
        return err.response.data.message;
      }
      return undefined;
    });
  return responseData;
}
//////////////////////////////////////////////////////////////////////
// 原材料投入スケジュール設定登録処理
//  ・原材料投入スケジュール設定登録APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：登録対象のIMSI
//    (i) schedule_type : スケジュール種別
//    (i) start_time    : 開始時刻
//    (i) input_period  : 投入期間
//    (i) is_enabled    : 有効設定
//  戻り値：
//    原材料投入スケジュール設定登録APIのレスポンスデータ（登録結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function registerRawMaterialInputScheduleSetting(
  webApiModel: WebApiModel,
  imsi: string,
  schedule_type: string,
  start_time: string,
  input_period: number,
  is_enabled: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path = "/plants/" + imsi + "/equipments/auto/schedule";
  debugLog("原材料投入スケジュール設定登録API", path);
  // スケジュールIDを作成
  const schedule_id = schedule_type + "_" + start_time;

  const payload = {
    body: {
      schedule_id: schedule_id,
      schedule_type: schedule_type,
      start_time: start_time,
      input_period: input_period,
      is_enabled: is_enabled,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .post(path, payload)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog(
        "Succeeded in [POST]/plants/{imsi}/equipments/auto/schedule:",
        response
      );
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error(
        "Failed on [POST]/plants/{imsi}/equipments/auto/schedule:",
        err
      );
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.response.data.message != null) {
        debugLog("Error message:", err.response.data.message);
        return err.response.data.message;
      }
      debugLog("Error stack:", err.stack);
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 原材料投入スケジュール設定更新処理
//  ・原材料投入スケジュール設定更新APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：更新対象のIMSI
//    (i) schedule_type : スケジュール種別
//    (i) start_time    : 開始時刻
//    (i) input_period  : 投入期間
//    (i) is_enabled    : 有効設定
//  戻り値：
//    原材料投入スケジュール設定更新APIのレスポンスデータ（更新結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function updateRawMaterialInputScheduleSetting(
  webApiModel: WebApiModel,
  imsi: string,
  schedule_type: string,
  start_time: string,
  input_period: number,
  is_enabled: boolean,
  beforeItem: { [key: string]: any }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path = "/plants/" + imsi + "/equipments/auto/schedule";
  debugLog("原材料投入スケジュール設定更新API", path);
  // スケジュールIDを作成
  const schedule_id = schedule_type + "_" + start_time;

  const payload = {
    body: {
      beforeUpdate: beforeItem,
      afterUpdate: {
        schedule_id: schedule_id,
        schedule_type: schedule_type,
        start_time: start_time,
        input_period: input_period,
        is_enabled: is_enabled,
      },
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .put(path, payload)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog(
        "Succeeded in [PUT]/plants/{imsi}/equipments/auto/schedule:",
        response
      );
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error(
        "Failed on [PUT]/plants/{imsi}/equipments/auto/schedule:",
        err
      );
      debugLog("Error response:", err);
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.response.data.message != null) {
        debugLog("Error message:", err.response.data.message);
        return err.response.data.message;
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 原材料投入スケジュール設定削除処理
//  ・原材料投入スケジュール設定削除APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：削除対象のIMSI
//    (i) schedule_type : スケジュール種別
//    (i) start_time    : 開始時刻
//  戻り値：
//    原材料投入スケジュール設定削除APIのレスポンスデータ（削除結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function deleteRawMaterialInputScheduleSetting(
  webApiModel: WebApiModel,
  imsi: string,
  schedule_type: string,
  start_time: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path = "/plants/" + imsi + "/equipments/auto/schedule";
  debugLog("原材料投入スケジュール設定削除API", path);
  // スケジュールIDを作成
  const schedule_id = schedule_type + "_" + start_time;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .del(path, { body: { schedule_id: schedule_id } })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog(
        "Succeeded in [DELETE]/plants/{imsi}/equipments/auto/schedule:",
        response
      );
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error(
        "Failed on [DELETE]/plants/{imsi}/equipments/auto/schedule:",
        err
      );
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 自動運転パラメータ設定取得処理
//  ・自動運転パラメータ設定取得APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：取得対象のIMSI
//  戻り値：
//    自動運転パラメータ設定取得APIのレスポンスデータ（取得結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function getAutomaticSequenceControlParameterSetting(
  webApiModel: WebApiModel,
  imsi: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path = "/plants/" + imsi + "/equipments/auto/param";
  debugLog("自動運転パラメータ設定取得API", path);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .get(path)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog(
        "Succeeded in [GET]/plants/{imsi}/equipments/auto/parameters:",
        response
      );
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error(
        "Failed on [GET]/plants/{imsi}/equipments/auto/parameters:",
        err
      );
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.response.data.message != null) {
        debugLog("Error message:", err.response.data.message);
        return err.response.data.message;
      }
      return undefined;
    });
  return responseData;
}

//////////////////////////////////////////////////////////////////////
// 自動運転パラメータ設定更新処理
//  ・自動運転パラメータ設定更新APIを呼び出す
//  引数：
//    (i) webApiModel   ：Vueで作成したApiModel
//    (i) imsi          ：更新対象のIMSI
//    (i) param_id      ：パラメータID
//    (i) status        ：ステータス
//  戻り値：
//    自動運転パラメータ設定更新APIのレスポンスデータ（更新結果）を返す
//    API呼び出しでエラー発生時はundefinedを返す
//////////////////////////////////////////////////////////////////////
export async function updateAutomaticSequenceControlParameterSetting(
  webApiModel: WebApiModel,
  imsi: string,
  param_id: string,
  status: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const path = "/plants/" + imsi + "/equipments/auto/param";
  debugLog("自動運転パラメータ設定更新API", path);

  const payload = {
    body: {
      param_id: param_id,
      status: status,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const responseData: any = await webApiModel
    .put(path, payload)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any): any => {
      debugLog(
        "Succeeded in [PUT]/plants/{imsi}/equipments/auto/parameters:",
        response
      );
      return response;
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((err: any): any => {
      console.error(
        "Failed on [PUT]/plants/{imsi}/equipments/auto/parameters:",
        err
      );
      if (err.response != null) {
        debugLog("Error response:", err.response);
      }
      if (err.response.data.message != null) {
        debugLog("Error message:", err.response.data.message);
        return err.response.data.message;
      }
      return undefined;
    });
  return responseData;
}
